import type { FC } from 'react';
import PageInfoContainer from '../../../components/PageInfoContainer/PageInfoContainer.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import CatImage from '../../../assets/svg/error-cat.svg?react';
import type { ErrorContentProps } from './ErrorContent.type.ts';
import { buttonCSS, descriptionCSS, imageCSS } from './ErrorContent.style.ts';

const ErrorContent: FC<ErrorContentProps> = ({ footer, button }) => {
  return (
    <PageInfoContainer
      heading="Something went wrong"
      footer={footer}
    >
      <CatImage css={imageCSS} />
      <p css={descriptionCSS}>
        This page is missing or our servers are having temporary issues. We’re working on it.
      </p>
      {button && (
        <Button customCSS={buttonCSS} size="large" onClick={button.onClick}>
          {button.label}
        </Button>
      )}
    </PageInfoContainer>
  );
};

export default ErrorContent;
