import queryString from 'query-string';

export interface UTMParams {
  utm_content?: string | (string | null)[] | null;
  utm_campaign?: string | (string | null)[] | null;
  utm_medium?: string | (string | null)[] | null;
  utm_source?: string | (string | null)[] | null;
  utm_term?: string | (string | null)[] | null;
  internal_ref?: string | (string | null)[] | null;
}

function compactParams(params: any) {
  Object.keys(params).forEach((key) => {
    if (params[key] === undefined) {
      delete params[key];
    }
  });
}

export function getUtmParams(search: string): UTMParams {
  const { utm_content, utm_campaign, utm_medium, utm_source, utm_term, internal_ref } = queryString.parse(search);
  const utmParams = {
    utm_content,
    utm_campaign,
    utm_medium,
    utm_source,
    utm_term,
    internal_ref,
  };
  compactParams(utmParams);

  return utmParams;
}

export function addUtmParams(originalUrl: string, search: string): string {
  const { url, query } = queryString.parseUrl(originalUrl);

  const queryUrl = Object.assign(getUtmParams(search), query);
  return `${url}?${queryString.stringify(queryUrl)}`;
}
