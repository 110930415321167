import type { FC } from 'react';

import EXTERNAL_URLS from '../../../../constants/external-urls.ts';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import noop from '../../../utils/noop';
import Anchor from '../../Anchor/Anchor';
import IconButton from '../../IconButton/IconButton';
import type { FooterLinksProps } from '../Footer.type';
import { linksCSS, paragraphCSS, socialLinksCSS } from './FooterLinks.style';

const FooterLinks: FC<FooterLinksProps> = ({ inverted = false, phone = '', onSocialLinkClick = noop, customCSS }) => {
  return (
    <>
      <div css={[customCSS, linksCSS]}>
        <p css={paragraphCSS}>
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.maticWebsite}>
            We
          </Anchor>
          {' '}
          Have Solutions for
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websiteConsumers}>
            Consumers
          </Anchor>
          ,
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websiteOriginators}>
            Originators
          </Anchor>
          ,
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websiteServicers}>
            Servicers
          </Anchor>
          .
        </p>
        <p css={paragraphCSS}>
          View Our
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websitePrivacyPolicy}>
            Privacy Policy
          </Anchor>
          ,
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websiteLicenseNumbers}>
            License Numbers
          </Anchor>
          ,
          {' '}
          <Anchor anchorType="single-color" target="_blank" href={EXTERNAL_URLS.websiteTerms}>
            Terms of Use
          </Anchor>
          .
        </p>
      </div>
      <div css={socialLinksCSS}>
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.socialTwitter}
          src={SIRV_IMAGES.twitter}
          iconText="Twitter (opens a new window)"
          iconType={inverted ? 'single-color-inverted' : 'single-color'}
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.socialLinkedin}
          src={SIRV_IMAGES.linkedin}
          iconText="Linkedin (opens a new window)"
          iconType={inverted ? 'single-color-inverted' : 'single-color'}
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.socialFacebook}
          src={SIRV_IMAGES.facebook}
          iconText="Facebook (opens a new window)"
          iconType={inverted ? 'single-color-inverted' : 'single-color'}
        />
        <IconButton
          wrapperElement="a"
          target="_blank"
          href={EXTERNAL_URLS.socialInstagram}
          src={SIRV_IMAGES.instagram}
          iconText="Instagram (opens a new window)"
          iconType={inverted ? 'single-color-inverted' : 'single-color'}
        />
        <IconButton
          wrapperElement="a"
          href={`tel:${phone}`}
          src={SIRV_IMAGES.phone}
          iconText="Phone number"
          iconType={inverted ? 'single-color-inverted' : 'single-color'}
          onClick={() => onSocialLinkClick('phone')}
        />
      </div>
    </>
  );
};

export default FooterLinks;
