import { z } from 'zod';

export const AddressSchema = z.object({
  line1: z.string(),
  line2: z.string().nullable(),
  state: z.string(),
  city: z.string(),
  zip: z.string(),
  full: z.string().optional(),
}).optional();

export const AccountSchema = z.object({
  gid: z.string(),
  person_gid: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  date_of_birth: z.string(),
  phone: z.string(),
  verified: z.boolean(),
  verified_at: z.string().datetime({ offset: true }).nullable(), // unix time in seconds
  address: AddressSchema,
  engagement_gid: z.string(),
  profile_completed_at: z.string().datetime({ offset: true }).nullable(), // unix time in seconds
  available_authorization_attempts_count: z.number(),
  test_mode: z.boolean(),
});

export const AccountResponseSchema = z.object({
  account: AccountSchema,
  person_has_core_bundle_interest: z.union([z.literal('yes'), z.literal('no'), z.null()]),
});
