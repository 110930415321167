import { type SerializedStyles, css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';
import { LoaderSizeEnum } from './Loader.type.ts';

const smallDotSize = 8;
const mediumDotSize = 12;
const animationSpeed = 0.6;

const fullPageLoaderCSS = css`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const loaderWrapperCSS = (fullPage: boolean): SerializedStyles => (fullPage ? fullPageLoaderCSS : css``);

export const loaderCSS = css`
  display: flex;
  justify-content: center;
`;

export function dotCSS(isInverted: boolean, withColor: boolean, size: LoaderSizeEnum): SerializedStyles {
  return css`
  animation: fade ${animationSpeed}s infinite alternate;
  background-color: ${withColor ? THEME.color.azure62 : isInverted ? THEME.color.white : THEME.color.black};
  border-radius: 50%;
  height: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;
  margin-right: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;
  width: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;

  &:nth-of-type(2) {
    animation-delay: ${animationSpeed / 3}s;
  }

  &:nth-of-type(3) {
    animation-delay: ${animationSpeed / 1.5}s;
    margin-right: 0;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
}
