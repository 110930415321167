import type { QuoteCoverage } from '../../../types/quote.type.ts';
import { CoverageKey } from '../../../types/quote.type.ts';

const BASE_COVERAGES = [
  CoverageKey.Dwelling,
  CoverageKey.OtherStructures,
  CoverageKey.PersonalProperty,
  CoverageKey.LossOfUse,
  CoverageKey.PersonalLiability,
  CoverageKey.MedicalPayments,
];

export function filterExtraCoverages(coverage: QuoteCoverage): boolean {
  return BASE_COVERAGES.includes(coverage.key);
}
