import type { HistoryResponse, QuotesOverTimeHistoryItem } from '../../../types/history.type';
import type { InfoPanelContent } from '../../replacement-cost/ReplacementCostHistory/ReplacementCostChart.type';

export const infoPanelContent: {
  [key: string]: InfoPanelContent;
} = {
  default: {
    message: 'None of our online-enabled carriers returned a quote for your home. Additional options may be '
    + 'available to you. To learn more, {{schedule_a_call_lower}} to talk to one of our licensed advisors.',
    type: 'negative',
  },
  availableOptions: {
    message: 'Additional options may be available to you. To learn more, contact one of our licensed advisors.',
    type: 'positive',
  },
};

export function getInfoPanelContent(data?: HistoryResponse<QuotesOverTimeHistoryItem>) {
  if (data?.history) {
    const currentYear = new Date().getFullYear();

    const currentYearData = data.history[currentYear];

    return currentYearData && +currentYearData > 0 ? infoPanelContent.availableOptions : infoPanelContent.default;
  }
}
