export const CALENDLY_ID = 'calendly-widget';
export const CALENDLY_PREVIEW_DETAILS_ID = 'calendly-preview-details';
export const CALL_SCHEDULED_STEP = 3;

export function filterCalendlyEvents(eventHandler: (event: string) => void) {
  return (event: MessageEvent): void => {
    if (!(event.data.event || '').indexOf('calendly')) {
      eventHandler(event.data.event);
    }
  };
}
