import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

export const tooltipCSS = css`
  border: none;
  cursor: pointer;
`;

export const tooltipContentCSS = css`
  color: ${THEME.color.gray60};
  font-size: 12px;
  line-height: 20px;
`;

export const closeIconCSS = css`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
