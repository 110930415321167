import { type FC, useId, useState } from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import CloseIcon from '../../../assets/svg/close.svg?react';
import { TooltipTheme, muiTooltipCSS, tooltipArrowCSS } from '../../../styles/common.style';
import noop from '../../utils/noop';
import { buttonCSS } from '../Button/Button.style';
import THEME from '../../../styles/theme.ts';
import { closeIconCSS, tooltipCSS, tooltipContentCSS } from './Toggletip.style';
import type { ToggletipProps } from './Toggletip.type';

const Toggletip: FC<ToggletipProps> = ({
  id,
  children,
  content,
  customCSS,
  onClick = noop,
  showByDefault = false,
  displayButton = true,
}) => {
  const [showToggletip, setShowToggletip] = useState(showByDefault);
  const generatedId = useId();
  const key = id ?? generatedId;

  const onToggletipClickOutside = (): void => {
    if (showToggletip) {
      setShowToggletip(prevState => !prevState);
    }
  };

  const toggletipProps = {
    id: `tooltip-${key}`,
    arrow: true,
    componentsProps: {
      tooltip: { sx: { ...muiTooltipCSS(TooltipTheme.Light), ...customCSS?.toggletipCSS } },
      arrow: { sx: { ...tooltipArrowCSS(TooltipTheme.Light) } },
    },
    title: (
      <ClickAwayListener onClickAway={onToggletipClickOutside}>
        <div>
          <CloseIcon
            onClick={() => setShowToggletip(prevState => !prevState)}
            color={THEME.color.gray60}
            css={closeIconCSS}
            width={16}
          />
          <div css={tooltipContentCSS}>{content}</div>
        </div>
      </ClickAwayListener>
    ),
    open: showToggletip,
    disableFocusListener: true,
    disableHoverListener: true,
  };

  const onChildrenClicked = (): void => {
    setShowToggletip(prevState => !prevState);
    onClick();
  };

  return (
    <MuiTooltip {...toggletipProps}>
      {displayButton
        ? (
            <button
              type="button"
              css={[buttonCSS('middle'), tooltipCSS, customCSS?.buttonCSS]}
              onClick={onChildrenClicked}
            >
              {children}
            </button>
          )
        : (
            <div css={[tooltipCSS, customCSS?.buttonCSS]}>{children}</div>
          )}
    </MuiTooltip>
  );
};

export default Toggletip;
