import { type SerializedStyles, css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

function getContainerWidth(fullscreen: boolean, small: boolean): string {
  if (fullscreen) {
    return '100%';
  }

  return (
    `${(small ? THEME.size.containerSmMaxWidth : THEME.size.containerMaxWidth)
    + THEME.size.paddingBodyHorizontalMobile * 2
    }px`
  );
}

export function containerCSS(fullscreen: boolean, small: boolean): SerializedStyles {
  return css`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${THEME.size.paddingBodyHorizontalMobile}px;
  padding-right: ${THEME.size.paddingBodyHorizontalMobile}px;
  max-width: ${getContainerWidth(fullscreen, small)};
  width: 100%;
`;
}
