function isGid(gid?: string | null): boolean {
  if (gid == null) {
    return false;
  }
  const v4 = /[a-f0-9]{8}-?[a-f0-9]{4}-?4[a-f0-9]{3}-?[89ab][a-f0-9]{3}-?[a-f0-9]{12}/i;
  const quoteGid = /[a-z0-9]{8}-[a-z0-9]{2}-[a-z0-9]{22}/i;
  const personGid = /[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}/i;

  return v4.test(gid) || quoteGid.test(gid) || personGid.test(gid);
}

export default isGid;
