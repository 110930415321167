import type { FC } from 'react';

import ChatIcon from '../../assets/svg/chat.svg?react';
import THEME from '../../styles/theme';
import { containerCSS, iconCSS } from './Comment.style';
import type { CommentProps } from './Comment.type';

const Comment: FC<CommentProps> = ({ content }) => {
  return (
    <div css={containerCSS}>
      <ChatIcon color={THEME.color.violet} css={iconCSS} />
      <p>{content}</p>
    </div>
  );
};

export default Comment;
