import * as React from 'react';

import './Container.scss';

import type { ReactNode } from 'react';

import classNames from 'classnames';
import { ContainerSize } from './Container.type';

interface ContainerProps {
  className?: string;
  size?: ContainerSize;
  children: ReactNode;
}

const Container: React.FunctionComponent<ContainerProps> = ({ children, className, size = ContainerSize.Medium }) => (
  <div className={classNames('c-container', className, `c-container--${size.toLowerCase()}`)}>{children}</div>
);

export default Container;
