import { css } from '@emotion/react';

export const formInputFocusOutlineCSS = css`
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
`;
