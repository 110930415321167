import type { FC } from 'react';

import { Card } from '../../../shared/components/Card/Card.tsx';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import ScoreCardContent from '../ScoreCardContent/ScoreCardContent.tsx';
import ScoreFactors from '../ScoreFactors/ScoreFactors.tsx';
import ReplacedText from '../../../shared/components/ReplacedText/ReplacedText.tsx';
import { useDynamicTextReplacements } from '../../../hooks/use-dynamic-text-replacements.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import {
  buttonCSS,
  cardInnerCSS,
  containerCSS,
  factorsCSS,
  iconWrapperCSS,
  titleCSS,
} from './ScoreCard.style';
import type { ScoreCardProps } from './ScoreCard.type';
import { ScoreGaugeType } from './ScoreCard.type';

const ScoreCard: FC<ScoreCardProps> = ({
  customCSS,
  title,
  description,
  icon,
  scoreGaugeType = ScoreGaugeType.Positive,
  score,
  scoreMax,
  factors,
  tooltip,
  scoreButtonLabel = 'View all factors',
  isLoading,
  onClick,
  onViewAllFactorsClick,
}) => {
  const dynamicTextReplacements = useDynamicTextReplacements();

  const hasScoreData = typeof score === 'number' && typeof scoreMax === 'number';

  if (isLoading) {
    return (
      <Card customCSS={[containerCSS, customCSS]}>
        <SkeletonLoader width={50} absoluteHeight={28} marginBottom={20} centered />
        <SkeletonLoader width={70} absoluteHeight={146} marginBottom={20} centered />
        <SkeletonLoader width={35} absoluteHeight={20} marginBottom={12} />

        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={12} />
        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={12} />
        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={20} />

        <SkeletonLoader width={55} absoluteHeight={20} />
      </Card>
    );
  }

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div>
        <div css={titleCSS}>{title}</div>
        {hasScoreData
          ? (
              <ScoreCardContent
                score={score}
                scoreMax={scoreMax}
                scoreGaugeType={scoreGaugeType}
                tooltip={tooltip?.map(hint => (
                  <ReplacedText replacements={dynamicTextReplacements} template={hint} key={hint} />
                ))}
              />
            )
          : (
              <div css={cardInnerCSS}>
                <div css={iconWrapperCSS}>{icon}</div>
                <div>{description}</div>
              </div>
            )}
      </div>

      <div>
        {!hasScoreData && (
          <Button css={buttonCSS} onClick={onClick}>
            Get your score
          </Button>
        )}
        <ScoreFactors
          customCSS={factorsCSS}
          factors={factors || []}
          scoreButtonLabel={scoreButtonLabel}
          onActionClick={onViewAllFactorsClick}
        />
      </div>
    </Card>
  );
};

export default ScoreCard;
