import type { FC } from 'react';
import { Global } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { globalCSS } from '../../styles/global.style.ts';
import { ANALYTICS_SESSION_GID, LOGGED_OUT_SESSION_GID } from '../../constants/browser-storage.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';

const AuthRoot: FC = () => {
  const loggedOutSessionGid = sessionStorage.getItem(LOGGED_OUT_SESSION_GID);
  const localStorageSessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);
  const { sessionGid } = useAnalyticsSessionGidCheck(localStorageSessionGid, loggedOutSessionGid);

  return (
    <>
      <Global styles={globalCSS} />
      {sessionGid ? <Outlet /> : <FullPageLoader />}
    </>
  );
};

export default AuthRoot;
