export default {
  init: (segmentKey: string): void => (window as any).analytics.invoked && (window as any).analytics.load(segmentKey),
  identify: (...args: unknown[]): void => (window as any).analytics && (window as any).analytics.identify(...args),
  trackV1: (eventName: string, gid: string, config: Record<string, any> = {}, ...args: any[]): void => {
    const configWithGeneric = {
      session_gid: gid,
      flow_type: 'rate_review',
      ...config,
    };

    return (window as any).analytics && (window as any).analytics.track(eventName, configWithGeneric, ...args);
  },
  track: (eventName: string, properties: Record<string, any> = {}): void => {
    return (window as any).analytics && (window as any).analytics.track(eventName, properties);
  },
  page: (pageName: string, ...args: unknown[]): void => {
    return (window as any).analytics && (window as any).analytics.page(pageName, ...args);
  },
  generateTrackEmail: (personGid: string): string => {
    return `${personGid}@matic.gid`;
  },
  generatePageName: (pageKey: string): string => {
    const name = pageKey
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return `${name} Page`;
  },
  // TODO: Remove after release v2
  setFullStoryUserVars: (...args: any[]): void => (window as any).FS && (window as any).FS.setUserVars(...args),
};
