import { type QueryObserverResult, useQuery } from '@tanstack/react-query';

import rateReviews, { type RateReview } from '../services/rateReviews';

const getRateReview = async (gid: string) => await rateReviews.get(gid);

function useRateReviews(gid: string): QueryObserverResult<RateReview> {
  return useQuery({
    queryKey: ['rateReviews', gid],
    queryFn: () => getRateReview(gid),
    retry: false,
  });
}

export default useRateReviews;
