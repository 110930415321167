import type { Vehicle } from '../../../types/asset.type';
import type { QuoteCoverage } from '../../../types/quote.type';
import { titleize } from '../../../utils/string.util';

export function buildVehicleTitle(vehicle?: Vehicle | null) {
  if (!vehicle) {
    return 'Unknown vehicle';
  }
  if (vehicle.year && vehicle.make && vehicle.model) {
    return titleize(`${vehicle.year} ${vehicle.make} ${vehicle.model}`);
  }
  return 'Unknown vehicle';
}

export function generateAutoDescription(assets?: (Vehicle | undefined | null)[] | null) {
  return assets?.map((auto, index) => `${index > 0 ? ', ' : ''}${buildVehicleTitle(auto)}`);
}

export function getLimitByCoverageKey(coverages: QuoteCoverage[] | null, coverageKey: string) {
  return coverages?.find(coverage => coverage.key === coverageKey)?.limit_description;
}

export function getAssetDeductibleByCoverageKey({
  coverages,
  coverageKey,
  assetGid,
}: {
  coverages: QuoteCoverage[] | null;
  coverageKey: string;
  assetGid?: string;
}) {
  return coverages?.find(coverage => coverage.key === coverageKey && coverage.asset_gid === assetGid)
    ?.deductible_description;
}

export function getAssetLimitByCoverageKey({
  coverages,
  coverageKey,
  assetGid,
}: {
  coverages: QuoteCoverage[] | null;
  coverageKey: string;
  assetGid?: string;
}) {
  return coverages?.find(coverage => coverage.key === coverageKey && coverage.asset_gid === assetGid)
    ?.limit_description;
}
