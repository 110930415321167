import type { FC } from 'react';
import { Link } from 'react-router-dom';
import he from 'he';
import { Card } from '../../../shared/components/Card/Card';
import { postCardCSS, postContainerCSS, textContainerCSS } from './PostItem.style';
import type { PostItemProps } from './PostItem.type';

const PostItem: FC<PostItemProps> = ({ link, img, title, onItemClicked }) => {
  const decodedTitle = he.decode(title);

  return (
    <Link to={link} target="_blank" css={postContainerCSS} onClick={onItemClicked}>
      <Card customCSS={postCardCSS}>
        <img {...img} />
        <div css={textContainerCSS}>
          <h3>{decodedTitle}</h3>
        </div>
      </Card>
    </Link>
  );
};

export default PostItem;
