import { Link, useParams } from 'react-router-dom';
import type { Replacements } from '../shared/components/ReplacedText/ReplacedText.type';
import ROUTES from '../constants/routes';
import type { DefaultRouteParams } from '../types/route-params.type';
import EXTERNAL_URLS from '../constants/external-urls';

export function useDynamicTextReplacements(): Replacements {
  const { gid } = useParams() as DefaultRouteParams;

  return {
    credit_score_details: <Link to={ROUTES.creditScoreDetails.replace(':gid', gid)}>Read more</Link>,
    fema_whats_covered: <Link to={EXTERNAL_URLS.floodSmart} target="_blank">Read more</Link>,
    // TODO think about parse text capitalize from pattern
    schedule_a_call: <Link to={ROUTES.scheduleCall.replace(':gid', gid)}>Schedule a call</Link>,
    schedule_a_call_lower: <Link to={ROUTES.scheduleCall.replace(':gid', gid)}>schedule a call</Link>,
    your_factors: <Link to={ROUTES.creditScoreDetails.replace(':gid', gid)}>(View your factors here)</Link>,
  };
}
