import type { FC } from 'react';
import { containerCSS, countCSS, descriptionCSS, imgWrapperCSS } from './StepItem.style';
import type { StepItemProps } from './StepItem.type';

const StepItem: FC<StepItemProps> = ({ customCSS, img, count, description }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={imgWrapperCSS}>
        <img {...img} />
      </div>

      <div css={descriptionCSS}>
        <div css={countCSS}>{count}</div>
        {description}
      </div>
    </div>
  );
};

export default StepItem;
