import type { FC } from 'react';

import './ScheduleCall.scss';

import Sirv from '../../constants/sirv';
import Button from '../../kit_deprecated/Button/Button';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage';
import { ButtonSize } from '../../kit_deprecated/Button/Button.type';
import type { onScheduleCall } from '../RateReview/RateReview.type';

interface Props {
  savings?: boolean;
  onScheduleCall: onScheduleCall;
}

const ScheduleCall: FC<Props> = ({ savings, onScheduleCall }) => (
  <div className={`c-schedule-call${savings ? '--savings' : ''}`}>
    <SirvImage
      className="c-schedule-call__illustration u-hidden--mobile"
      url={Sirv.ILLUSTRATIONS.CALENDAR}
      width={275}
    />

    <div className="c-schedule-call__right">
      <h3 className="c-schedule-call__header">
        Simplify your whole world of insurance. Schedule a free consultation today.
      </h3>

      <SirvImage
        className="c-schedule-call__illustration u-hidden--desktop"
        url={Sirv.ILLUSTRATIONS.CALENDAR}
        width={180}
      />

      <div className="c-schedule-call__content">
        Matic can help you with more than just your home insurance. Our agents can help you find the best rates for all
        of your insurance needs.
      </div>

      <Button onClick={onScheduleCall} secondary size={ButtonSize.Large}>
        Schedule a Call
      </Button>
    </div>
  </div>
);

export default ScheduleCall;
