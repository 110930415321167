import axios from 'axios';

import { type Api, type ApiCallParams, HTTPMethod } from '../types/api.type.ts';

function call(method: HTTPMethod, url: string, { headers = {}, body = {}, params = {} }: ApiCallParams) {
  return axios({
    method,
    url,
    headers: {
      ...headers,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
    params,
  })
    .then(res => res.data)
    .catch((error) => {
      if (!error.response) {
        window.location.reload();
      }
      else {
        throw error;
      }
    });
}

const api: Api = {
  get(url, params = {}) {
    return call(HTTPMethod.get, url, params);
  },
  post(url, params = {}) {
    return call(HTTPMethod.post, url, params);
  },
  put(url, params = {}) {
    return call(HTTPMethod.put, url, params);
  },
  patch(url, params = {}) {
    return call(HTTPMethod.patch, url, params);
  },
  head(url, params = {}) {
    return call(HTTPMethod.head, url, params);
  },
  delete(url, params = {}) {
    return call(HTTPMethod.delete, url, params);
  },
};

export default api;
