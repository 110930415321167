import * as React from 'react';

import './Icon.scss';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import type { SVGUrls } from '../../constants/urls';

export interface IconProps {
  iconName: SVGUrls | string;
  className?: string;
}

const Icon: React.FunctionComponent<IconProps> = ({ iconName, className }) => (
  <ReactSVG src={iconName} className={classNames('c-icon', className)} />
);

export default Icon;
