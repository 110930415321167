import { useEffect, useRef, useState } from 'react';
import { getSession } from '../api/session/use-session-query.ts';
import useMutateTrackSession from '../api/session/use-mutate-track-session.ts';
import type { UTMParams } from '../types/route-params.type.ts';
import { ANALYTICS_SESSION_GID } from '../constants/browser-storage.ts';

export function useAnalyticsSessionGidCheck(
  sessionGidCandidate?: string | null,
  loggedOutSessionGid?: string | null,
  utmParams?: UTMParams,
): { sessionGid: string | null } {
  const { mutateAsync: createTrackSession } = useMutateTrackSession();
  const [sessionData, setSessionData] = useState<{ gid: string | null; updateStorage: boolean | null }>({
    gid: null,
    updateStorage: null,
  });
  const sessionProcessedRef = useRef(false);

  useEffect(() => {
    const createAndSetNewSession = async () => {
      const newSession = await createTrackSession(utmParams);
      setSessionData({
        gid: newSession.session.gid,
        updateStorage: true,
      });
    };

    const checkExistingSession = async (gid: string) => {
      const existingSession = await getSession(gid);
      if (existingSession.data.session && !existingSession.data.session?.finished_at) {
        setSessionData({
          gid: existingSession.data.session.gid,
          updateStorage: true,
        });
      }
      else {
        createAndSetNewSession();
      }
    };

    if (!sessionProcessedRef.current) {
      sessionProcessedRef.current = true;
      if (loggedOutSessionGid) {
        /**
         * If this GID is presented, it means that user was logged out, and we do not need to create
         * new session or check existing. We should create a new session manually in child component
         * after next user action (ex. user type email on sign-in page and pres Send Magic Link).
         */
        setSessionData({
          gid: loggedOutSessionGid,
          updateStorage: false,
        });
      }
      else if (sessionGidCandidate) {
        checkExistingSession(sessionGidCandidate);
      }
      else {
        createAndSetNewSession();
      }
    }
  }, [sessionGidCandidate, loggedOutSessionGid, utmParams, createTrackSession]);

  useEffect(() => {
    if (sessionData.updateStorage && sessionData.gid) {
      localStorage.setItem(ANALYTICS_SESSION_GID, sessionData.gid);
    }
  }, [sessionData]);

  return {
    sessionGid: sessionData.gid,
  };
}
