import type { FC } from 'react';
import { Fragment } from 'react';
import type { ReplacedTextProps } from './ReplacedText.type';

const ReplacedText: FC<ReplacedTextProps> = ({ template, replacements, customCSS }) => {
  const parseTemplate = (template: string) => {
    const parts = template.split(/(\{\{.*?\}\})/g);

    return parts.map((part) => {
      const match = part.match(/\{\{(.*?)\}\}/);
      if (match) {
        const key = match[1];

        if (key in replacements) {
          return (
            <Fragment key={key}>
              {replacements[key]}
            </Fragment>
          );
        }
      }
      return part;
    });
  };

  return (
    <p css={customCSS}>
      {parseTemplate(template)}
    </p>
  );
};

export default ReplacedText;
