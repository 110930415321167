import * as React from 'react';

import classNames from 'classnames';

import './Coverages.scss';

import { moneyFormatter } from '../../utils/formatters';

type CoverageType = number | string;

interface CoveragesProps {
  className?: string;
  message?: string;
  coverages: {
    dwelling: CoverageType;
    other_structures?: CoverageType;
    personal_property: CoverageType;
    loss_of_use: CoverageType;
    personal_liability: CoverageType;
    medical_payments: CoverageType;
  };
}

const Coverages: React.FunctionComponent<CoveragesProps> = ({ coverages, message, className }) => (
  <div className={classNames('c-coverages', className)}>
    <div className="c-coverages__wrapper">
      {!!coverages.dwelling && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Dwelling</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">{moneyFormatter(coverages.dwelling)}</p>
          </div>
          <div className="c-coverages__coverage-description">
            A faulty Christmas light or your attempt at deep frying can cause smoke and fire damage.
          </div>
        </div>
      )}
      {!!coverages.other_structures && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Other structures</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">
              {moneyFormatter(coverages.other_structures)}
            </p>
          </div>
          <div className="c-coverages__coverage-description">
            A tree falls on your gazebo during a bad storm. We&apos;ll cover the cost to rebuild.
          </div>
        </div>
      )}
      {!!coverages.personal_property && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Personal Property</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">
              {moneyFormatter(coverages.personal_property)}
            </p>
          </div>
          <div className="c-coverages__coverage-description">
            Your stuff got stolen or damaged? This is the maximum amount you’ll be able to claim.
          </div>
        </div>
      )}
      {!!coverages.medical_payments && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Medical Payment</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">
              {moneyFormatter(coverages.medical_payments)}
            </p>
          </div>
          <div className="c-coverages__coverage-description">
            We’ll cover medical expenses if a guest or neighbor is injured on your property.
          </div>
        </div>
      )}
      {!!coverages.personal_liability && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Personal Liability</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">
              {moneyFormatter(coverages.personal_liability)}
            </p>
          </div>
          <div className="c-coverages__coverage-description">
            We&apos;ll cover the cost if someone else is injured or their property is damaged while at your home
          </div>
        </div>
      )}
      {!!coverages.loss_of_use && (
        <div className="c-coverages__coverage">
          <div className="c-coverages__coverage-header">
            <p className="c-coverages__coverage-name o-paragraph--bold">Loss of use</p>
            <p className="c-coverages__coverage-price o-paragraph--bold">{moneyFormatter(coverages.loss_of_use)}</p>
          </div>
          <div className="c-coverages__coverage-description">
            If your home is under construction from a recent claim, we&apos;ll put you up in a nice hotel or similar
            rental home.
          </div>
        </div>
      )}
    </div>

    {message && <div>{message}</div>}
  </div>
);

export default Coverages;
