import * as React from 'react';

import './BaseFooter.scss';

import classNames from 'classnames';

import { EXTERNAL_URLS, SVGUrls, URLS } from '../../constants/urls';
import Container from '../Container/Container';
import { AnchorWithIcon } from '../ElementWithIcon/ElementWithIcon';
import SirvImage from '../SirvImage/SirvImage';

interface BaseFooterProps {
  copy?: string | React.ReactElement;
  phone?: string;
  isRebranded?: boolean;
}

const BaseFooter: React.FunctionComponent<BaseFooterProps> = ({
  copy,
  phone = EXTERNAL_URLS.PHONE_NUMBER,
  isRebranded = false,
}) => (
  <footer
    className={classNames('c-footer', {
      'c-footer__rebranded': isRebranded,
    })}
  >
    <Container className="c-footer__inner">
      {copy && <div className="c-footer__copy o-paragraph--small u-mb-8">{copy}</div>}
      <div className="c-footer__logo-wrapper u-mb-2">
        <SirvImage width={138} url={URLS.MATIC_LOGO} alt="Matic logo" className="c-footer__logo" />
      </div>
      <h4 className="u-mb-4">
        We are a Digital Insurance Agency That Empowers You to Simplify Your Whole World of Insurance
      </h4>
      <div className="c-footer__row">
        <div className="c-footer__copyrights">
          <p className="u-mb-3">
            © 2014 –
            {new Date().getFullYear()}
            {' '}
            Matic Insurance Services, Inc.
          </p>
          <p>All rights reserved.</p>
        </div>
        <div className="c-footer__links">
          <p className="u-mb-3">
            <a target="_blank" href={EXTERNAL_URLS.MATIC_WEBSITE} className="o-link" rel="noreferrer">
              We
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            {' '}
            Have Solutions for
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_CONSUMERS} className="o-link" rel="noreferrer">
              Consumers
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            ,
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_ORIGINATORS} className="o-link" rel="noreferrer">
              Originators
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            ,
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_SERVICERS} className="o-link" rel="noreferrer">
              Servicers
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            .
          </p>
          <p className="u-mb-6">
            View Our
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_PRIVACY_POLICY} className="o-link" rel="noreferrer">
              Privacy Policy
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            ,
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_LICENSE_NUMBERS} className="o-link" rel="noreferrer">
              License Numbers
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            ,
            {' '}
            <a target="_blank" href={EXTERNAL_URLS.WEBSITE_TERMS} className="o-link" rel="noreferrer">
              Terms of Use
              <span className="u-visually-hidden">(opens a new window)</span>
            </a>
            .
          </p>
          <div className="c-footer__socials">
            <AnchorWithIcon
              className="c-footer__social-link"
              target="_blank"
              href={EXTERNAL_URLS.SOCIAL_TWITTER}
              iconName={SVGUrls.Twitter}
              iconText="Twitter (opens a new
                window)"
              social
            />
            <AnchorWithIcon
              className="c-footer__social-link"
              target="_blank"
              href={EXTERNAL_URLS.SOCIAL_LINKEDIN}
              iconName={SVGUrls.Linkedin}
              iconText="Linkedin (opens a new
                window)"
              social
            />
            <AnchorWithIcon
              className="c-footer__social-link"
              target="_blank"
              href={EXTERNAL_URLS.SOCIAL_FACEBOOK}
              iconName={SVGUrls.Facebook}
              iconText="Facebook (opens a new
                window)"
              social
            />
            <AnchorWithIcon
              className="c-footer__social-link"
              target="_blank"
              href={EXTERNAL_URLS.SOCIAL_INSTAGRAM}
              iconName={SVGUrls.Instagram}
              iconText="Instagram (opens a new
                window)"
              social
            />
            <AnchorWithIcon
              className="c-footer__social-link"
              href={`tel:${phone}`}
              iconName={SVGUrls.Phone}
              iconText="Phone number (opens a new
                window)"
              social
            />
          </div>
        </div>
      </div>
    </Container>
  </footer>
);

export default BaseFooter;
