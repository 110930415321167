import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const cardCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 18px 19px 18px 24px;
  gap: 16px;

  ${mq[0]} {
    flex-direction: row;
  }
`;

export const cardTitleCSS = css`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  gap: 16px;
`;

export const buttonCSS = css`
  width: 100%;

  ${mq[0]} {
    padding: 12px 20px;
    width: 152px;
  }
`;
