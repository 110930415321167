import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import type { ProposalPagePresenceResponse } from '../../types/proposal-page-presence.type.ts';

function useProposalUserPresenceQuery({ proposalGid }: { proposalGid: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.proposalUserPresence, proposalGid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<ProposalPagePresenceResponse>>(API_PATH.proposalUserPresence.replace(':gid', proposalGid))
        .then((res) => {
          return res.data.proposal_page_presence;
        }),
  });
}

export default useProposalUserPresenceQuery;
