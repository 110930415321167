import { useMutation } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';
import type { SendMagicLinkPayload } from '../../types/account.type.ts';

function useSendMagicLink(throwOnError = false) {
  return useMutation({
    throwOnError,
    mutationFn: async (data: SendMagicLinkPayload) =>
      await api
        .post<HTTPResponse<{ account: { email: string; session_gid?: string } }>>(API_PATH.magicLink, {
          body: { data },
        })
        .then(async res => res.data),
  });
}
export default useSendMagicLink;
