import './CardInfoSection.scss';

import type { FC, ReactElement } from 'react';

import classNames from 'classnames';

interface Props {
  value?: ReactElement | string;
  label?: string;
  className: string;
}

const CardInfoSection: FC<Props> = ({ value, label, className }) => (
  <div className={classNames('c-card-info-section', className)}>
    <div className="c-card-info-section__value u-mb-1">{value}</div>
    {label && <div className="c-card-info-section__label">{label}</div>}
  </div>
);

export default CardInfoSection;
