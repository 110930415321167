import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { Session, SessionTrackPayload } from '../../types/session.type.ts';
import api from '../api.ts';

function useMutateTrackSession() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data?: SessionTrackPayload) =>
      await api
        .post<HTTPResponse<{ session: Session }>>(API_PATH.sessionTrack, { body: { data } })
        .then(async (res) => {
          await queryClient.setQueryData([QUERY_CACHE_KEYS.session, res.data.session.gid], res.data);
          return res.data;
        }),
  });
}

export default useMutateTrackSession;
