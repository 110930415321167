import { css } from '@emotion/react';

export const illustrationCSS = css`
  margin: 32px 0;
`;

export const actionButtonCSS = css`
  font-weight: 700;
  margin-top: 8px;
  font-variant-numeric: lining-nums;
`;
