// FIXME some problem with such memo exports need to be fixed later
/* eslint-disable react-refresh/only-export-components */
import { type FC, memo } from 'react';

import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import Wrapper from '../../../shared/components/Wrapper/Wrapper.tsx';
import { labelCSS, tooltipButtonCSS } from './QuestionLabel.style';
import { type QuestionLabelProps, QuestionLabelWrapper } from './QuestionLabel.type';

const QuestionLabel: FC<QuestionLabelProps> = ({
  customCSS,
  children,
  wrapperElement = QuestionLabelWrapper.Label,
  keyName,
  tooltipText,
}) => {
  const wrapperComponentProps = wrapperElement === QuestionLabelWrapper.Label ? { htmlFor: keyName } : {};

  return (
    <Wrapper
      tag={wrapperElement === QuestionLabelWrapper.Label ? 'label' : 'div'}
      css={[labelCSS, customCSS]}
      {...wrapperComponentProps}
    >
      {children}
      {tooltipText && <TooltipQuestionButton customCSS={tooltipButtonCSS} id={keyName} content={tooltipText} />}
    </Wrapper>
  );
};

export default memo(QuestionLabel);
