import { css } from '@emotion/react';
import { buttonCSS } from '../../../shared/components/Button/Button.style.ts';

export const containerCSS = css`
  margin-bottom: 32px;
`;

export const imageCSS = css`
  max-width: 100%;
  max-height: 172px;
`;

export const anchorButtonCSS = css`
  ${buttonCSS('middle', 'secondary', false)}
  text-align: center;
  text-decoration: none;
`;
