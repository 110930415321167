import './QuoteCard.scss';

import type { FC } from 'react';
import { useState } from 'react';

import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Button from '../../kit_deprecated/Button/Button';
import type { Quote } from '../../services/rateReviews';
import { Card, CardContent } from '../Card/Card';
import QuoteDetailsModal from '../QuoteDetailsModal/QuoteDetailsModal';
import type { onQuoteReview, onQuoteSelect } from '../RateReview/RateReview.type';

interface Props {
  quote: Quote;
  onQuoteSelect: onQuoteSelect;
  onQuoteReview: onQuoteReview;
  recommended?: boolean;
  ordinal: number;
}

const QuoteCard: FC<Props> = ({ quote, onQuoteSelect, onQuoteReview, recommended, ordinal }) => {
  const { data: settings } = useSettingsQuery();
  const [areDetailsVisible, setDetailsVisible] = useState(false);
  const onChooseQuote = () => onQuoteSelect(quote, ordinal);

  return (
    <>
      <Card className="c-quote-card">
        {recommended && (
          <div className="c-quote-card__header">
            <h4>Recommended Quote</h4>
          </div>
        )}
        <CardContent>
          <div className="c-quote-card-content">
            <div className="c-quote-card-content__logo-container">
              <img className="c-quote-card-content__logo" src={quote.carrier.logo_url} alt="Carrier Logo" />
            </div>

            <div className="c-quote-card-content__price-container">
              {quote.deductible && (
                <div className="c-quote-card-content__price u-hidden--mobile">
                  <span className="c-quote-card-content__label">Deductible</span>
                  <span className="c-quote-card-content__value u-mb-1">{quote.deductible}</span>
                </div>
              )}
              {quote.coverages && quote.coverages.dwelling && (
                <div className="c-quote-card-content__price u-hidden--mobile">
                  <span className="c-quote-card-content__label">Dwelling</span>
                  <span className="c-quote-card-content__value u-mb-1">{quote.coverages.dwelling}</span>
                </div>
              )}
              {quote.coverages && quote.coverages.other_structures && (
                <div className="c-quote-card-content__price u-hidden--mobile">
                  <span className="c-quote-card-content__label">Other Structures</span>
                  <span className="c-quote-card-content__value u-mb-1">{quote.coverages.other_structures}</span>
                </div>
              )}

              <div className="c-quote-card-content__price">
                <span className="c-quote-card-content__label">Annual premium</span>
                {' '}
                <h4 className="c-quote-card-content__value c-quote-card-content__value--large u-mb-1">
                  {quote.premium}
                </h4>
              </div>
            </div>

            <div className="c-quote-card-content__actions">
              <Button className="c-quote-card-content__choose" onClick={onChooseQuote}>
                Choose Quote
              </Button>

              <div className="c-quote-card-content__expand-details">
                <a
                  className="o-link o-link--secondary c-quote-card-content__show-details"
                  onClick={() => {
                    onQuoteReview(quote, ordinal);
                    setDetailsVisible(true);
                  }}
                >
                  Show Details
                </a>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <QuoteDetailsModal
        visible={areDetailsVisible}
        quote={quote}
        phoneNumber={settings?.phone_number}
        onClose={() => {
          setDetailsVisible(false);
        }}
      >
        <Button data-testid="choose-quote-modal-button" onClick={onChooseQuote}>
          Choose Quote
        </Button>
      </QuoteDetailsModal>
    </>
  );
};

export default QuoteCard;
