import { useMutation } from '@tanstack/react-query';

import api from '../api';
import API_PATH from '../../constants/api.ts';

export function useCreateFullStorySession() {
  return useMutation({ mutationFn: async ({
    personGid,
    engagementGid,
    sessionUrl,
    proposalGid,
  }: {
    personGid: string;
    engagementGid: string;
    sessionUrl: string;
    proposalGid: string;
  }) => await api.post(API_PATH.fullStorySessions.replace(':proposal_gid', proposalGid), {
    body: {
      person_gid: personGid,
      engagement_gid: engagementGid,
      session_url: sessionUrl,
    },
  }) });
}

export default useCreateFullStorySession;
