import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FactorsList from '../../components/FactorsList/FactorsList.tsx';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import SuggestedPolicy from '../../components/SuggestedPolicy/SuggestedPolicy.tsx';
import { ScoreType } from '../../types/score.type.ts';
import {
  columnsWrapperCSS,
  customSectionHeadingCSS,
  leftColumnCSS,
  pageHeadingCSS,
  pageSubheadingCSS,
  rightColumnCSS,
} from '../../styles/layout.style.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import ROUTES from '../../constants/routes.ts';
import MaticContent from '../../components/MaticContent/MaticContent.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import PremiumHistory from './PremiumHistory/PremiumHistory.tsx';
import { rSymbolCSS } from './index.style.ts';
import FooterCopy from './FooterCopy/FooterCopy.tsx';

const RateReviewLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  useTrackPage(AnalyticPageKey.RateReview);

  const handleShowMoreQuotesClick = () => {
    navigate(ROUTES.carrierOptions.replace(':gid', gid), { state: { scrollToList: true } });
  };

  return (
    <PageContainer pageKey={AnalyticPageKey.RateReview} footerCopy={<FooterCopy />} showBackButton>
      <h1 css={[pageHeadingCSS, rSymbolCSS]}>
        Home RateReview<span>®</span>
      </h1>
      <p css={pageSubheadingCSS}>Compare your home insurance rate.</p>

      <div css={columnsWrapperCSS}>
        <div css={leftColumnCSS}>
          <PremiumHistory />

          {isMobile && (
            <>
              <SectionHeading title="Your personalized home quote" />
              <SuggestedPolicy page={AnalyticPageKey.RateReview} onShowMoreQuotesClick={handleShowMoreQuotesClick} />
            </>
          )}

          <SectionHeading
            title="Risk factors impacting carrier premiums"
            subtitle="Below are some of the factors that carriers may use when determining your premium."
          />
          <FactorsList
            page={AnalyticPageKey.RateReview}
            scoreType={ScoreType.Risk}
            noFactorsButtonLabel="Unlock Your Premium Factors"
          />

          {!isMobile && (
            <>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={AnalyticPageKey.RateReview} />
            </>
          )}
        </div>

        <div css={rightColumnCSS}>
          {!isMobile && (
            <>
              <SectionHeading title="Your personalized home quote" customCSS={customSectionHeadingCSS} />
              <SuggestedPolicy page={AnalyticPageKey.RateReview} onShowMoreQuotesClick={handleShowMoreQuotesClick} />
            </>
          )}

          {isMobile && (
            <div>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={AnalyticPageKey.RateReview} />
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default RateReviewLayout;
