import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';
import THEME from '../../../styles/theme';

export const agentInfoCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const agentContactCSS = css`
  display: flex;
  gap: 24px;
  flex-direction: column;
  ${mq[1]} {
    flex-direction: row;
  }
`;

export const agentContactItemCSS = css`
  font-weight: 700;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
`;

export const expiredWarningBlockCSS = css`
  display: flex;
  align-items: center;
  gap: 16px;
  background-color: ${THEME.color.warningYellow94};
  padding: 16px;
  border-radius: 4px;
`;

export const expiredWarningTextCSS = css`
  max-width: calc(100% - 48px);
`;
