import { type FC, useRef } from 'react';

import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story.ts';
import Input from '../../../../shared/components/Input/Input.tsx';
import noop from '../../../../shared/utils/noop.ts';
import { isValueSpecificType } from '../../../../utils/answers.util.ts';
import isSensitiveQuestion from '../../../../utils/is-sensitive-question.util';
import { getHiddenQuestionValidationRules, getValidationRules } from '../../../../utils/question-validations.util.ts';
import { PHONE_PATTERN_VALIDATION } from '../PhoneQuestions.util';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../../shared/components/Input/Input.style.ts';
import type { PhoneInputProps } from './PhoneInput.type';
import { normalizePhoneValue } from './PhoneInput.util';

const DEFAULT_MASK = '(###) ###-####';
const HIDDEN_MASK = '(\\*\\*\\*) \\*\\*\\*-####';

const PhoneInput: FC<PhoneInputProps> = ({
  name,
  isDisabled,
  hasError = false,
  inputId,
  placeholder = '(xxx) xxx-xxxx',
  validations,
  trackingForbidden = false,
  dense = false,
  onValidatePreviousInputs = noop,
}) => {
  const isAsteriskCleared = useRef(false);
  const notClearedSensitiveQuestion = isSensitiveQuestion(name) && !isAsteriskCleared.current;

  const getValue = (value: string): string => {
    if (notClearedSensitiveQuestion) {
      isAsteriskCleared.current = true;
      return value.includes('*') ? '' : value;
    }

    return normalizePhoneValue(value);
  };

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value, onBlur, name }, fieldState: { isDirty } }) => {
        const safeValue = isValueSpecificType<string>(value, ['string']) ? value : '';
        const isPrefilled = false;
        return (
          <div css={inputWrapperCSS}>
            <PatternFormat
              id={inputId}
              className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
              css={inputCSS(hasError, isDisabled, false, isPrefilled, dense)}
              type="tel"
              format={isDirty || !safeValue.includes('*') ? DEFAULT_MASK : HIDDEN_MASK}
              mask="_"
              name={name}
              customInput={Input}
              onChange={(e) => {
                onChange({ target: { value: getValue(e.target.value) } });
              }}
              value={safeValue}
              disabled={isDisabled}
              onBlur={() => {
                onBlur();
                if (isValueSpecificType<string>(value, ['string']) && value.trim().length > 0) {
                  onValidatePreviousInputs();
                }
              }}
              placeholder={placeholder}
              autoComplete="off"
            />
            <div css={focusHelperCSS} className="input-focus-helper" />
          </div>
        );
      }}
      rules={
        notClearedSensitiveQuestion
          ? getHiddenQuestionValidationRules(validations)
          : getValidationRules([PHONE_PATTERN_VALIDATION, ...(validations || [])])
      }
      defaultValue=""
    />
  );
};

export default PhoneInput;
