import './HorizontalPolicyCard.scss';

import classnames from 'classnames';

import type { FC } from 'react';
import { UNKNOWN } from '../../constants/quotes';
import type { Home, Policy } from '../../services/rateReviews';
import { Card, CardContent } from '../Card/Card';
import CardInfoSection from './CardInfoSection';

interface Props {
  policy?: Policy;
  home?: Home;
  className?: string;
}

const HorizontalPolicyCard: FC<Props> = ({ policy, home, className }) => (
  <div className={classnames('c-current-plan', className)}>
    <Card>
      <CardContent className="c-current-plan__content">
        <div className="c-current-plan__label">
          <h4 className="u-mb-1">Current Policy</h4>
          <div className="c-current-plan__expiration">{home?.address}</div>
        </div>
        <CardInfoSection
          className="c-current-plan__info-deductible"
          value={policy?.deductible ? policy.deductible : UNKNOWN}
          label="Deductible"
        />
        <CardInfoSection
          className="c-current-plan__info-dwelling"
          label="Dwelling"
          value={policy?.dwelling ? policy.dwelling : UNKNOWN}
        />
        <CardInfoSection
          className="c-current-plan__info-premium"
          value={policy?.premium ? policy.premium : UNKNOWN}
          label="Annual Premium"
        />
        <CardInfoSection
          className="c-current-plan__info-expiration"
          value={policy?.expiration_date ? policy.expiration_date : UNKNOWN}
          label="Expiration"
        />
      </CardContent>
    </Card>
  </div>
);

export default HorizontalPolicyCard;
