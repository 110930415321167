import type { Home, Vehicle } from './asset.type';

export enum PaymentMethod {
  Escrow = 'escrow',
  DirectBill = 'direct_bill',
}

export interface PersonLoan {
  gid: string;
  home: Home | null;
  vehicle: Vehicle | null;
  payment_method: PaymentMethod;
}
