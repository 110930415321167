import type { ReactNode } from 'react';

import type { SerializedStyles } from '@emotion/react';

export interface QuestionLabelProps {
  customCSS?: SerializedStyles | Array<SerializedStyles | undefined>;
  wrapperElement?: QuestionLabelWrapper;
  keyName: string;
  tooltipText?: string;
  children: ReactNode;
}

export enum QuestionLabelWrapper {
  Div = 'DIV',
  Label = 'LABEL',
}
