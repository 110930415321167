import type { FC } from 'react';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import { skeletonColumnGroupCSS } from './SuggestedPolicySkeleton.style.ts';

const SuggestedPolicySkeleton: FC = () => {
  return (
    <>
      <SkeletonLoader marginBottom={12} minWidth={40} maxWidth={50} absoluteHeight={50} />

      <SkeletonLoader marginBottom={8} minWidth={60} maxWidth={80} absoluteHeight={30} />
      <SkeletonLoader marginBottom={24} minWidth={50} maxWidth={70} absoluteHeight={20} />

      <SkeletonLoader marginBottom={16} width={100} absoluteHeight={24} />

      <div css={skeletonColumnGroupCSS}>
        <SkeletonLoader width={100} absoluteHeight={80} />
        <SkeletonLoader width={100} absoluteHeight={80} />
        <SkeletonLoader width={100} absoluteHeight={80} />
      </div>

      <SkeletonLoader marginTop={24} width={100} absoluteHeight={44} />
    </>
  );
};

export default SuggestedPolicySkeleton;
