import type { FC } from 'react';

import LogoInvertedIcon from '../../../assets/svg/matic-logo-inverted.svg?react';
import LogoIcon from '../../../assets/svg/matic-logo.svg?react';
import noop from '../../utils/noop';
import Container from '../Container/Container';
import {
  confettiCSS,
  copyrightCSS,
  disclosuresCSS,
  footerCSS,
  footerRowCSS,
  logoCSS,
  subtitleCSS,
} from './Footer.style';
import type { FooterProps } from './Footer.type';
import FooterLinks from './FooterLinks/FooterLinks';

const Footer: FC<FooterProps> = ({
  copy,
  phone,
  inverted = false,
  withConfetti = false,
  onSocialLinkClick = noop,
  customCSS,
}) => {
  return (
    <footer css={[footerCSS(inverted), customCSS]}>
      {withConfetti && <div data-testid="footer-confetti" css={confettiCSS} />}
      <Container>
        {copy && <div css={disclosuresCSS}>{copy}</div>}
        {inverted ? <LogoInvertedIcon css={logoCSS} width={212} /> : <LogoIcon css={logoCSS} width={212} />}
        <div css={subtitleCSS}>
          We are a Digital Insurance Agency That Empowers You to Simplify Your Whole World of Insurance
        </div>
        <div css={footerRowCSS}>
          <div css={copyrightCSS}>
            © 2014 – {new Date().getFullYear()} Matic Insurance Services, Inc.
          </div>
          <div>
            <FooterLinks inverted phone={phone} onSocialLinkClick={onSocialLinkClick} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
