import { useCallback, useEffect, useState } from 'react';

function useTimer() {
  const [time, setTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const startTimer = (initialTime: number) => {
    if (timer) {
      clearInterval(timer);
    }

    setTime(initialTime);

    setTimer(
      setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000),
    );
  };

  const stopTimer = useCallback(() => {
    setTime(0);
    if (timer) {
      clearInterval(timer);
    }
  }, [timer]);

  useEffect(() => {
    if (time === 0) {
      stopTimer();
    }
  }, [stopTimer, time]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [timer]);

  return {
    time,
    startTimer,
    stopTimer,
  };
}

export default useTimer;
