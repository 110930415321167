import type { FC } from 'react';
import TitleIcon from '../../assets/svg/light-bulb-path.svg?react';
import { containerCSS, contentCSS, messageCSS, messageContainerCSS, titleCSS } from './OfferCard.style';
import type { OfferCardProps } from './OfferCard.type';

const OfferCard: FC<OfferCardProps> = ({ customCSS, title, message, backgroundColor, messageColor, children }) => {
  return (
    <div css={[containerCSS(backgroundColor), customCSS]}>
      <div css={contentCSS}>{children}</div>
      <div css={messageContainerCSS(messageColor)}>
        <p css={titleCSS}>
          <TitleIcon />
          <span>{title}</span>
        </p>
        <p css={messageCSS}>{message}</p>
      </div>
    </div>
  );
};

export default OfferCard;
