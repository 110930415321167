/* istanbul ignore file */
import type { TouchEventHandler } from 'react';

const isIos = () => navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

interface IScrollUtils {
  disableBodyScroll: () => void;
  enableBodyScroll: () => void;
  onIosTouchMoveFocusedInputScrollFix: TouchEventHandler;
}

class ScrollUtils implements IScrollUtils {
  private scrollTop: number = window.pageYOffset;
  private isScrollingEnabled = true;

  public disableBodyScroll(): void {
    document.body.style.overflow = 'hidden';
    this.scrollTop = this.isScrollingEnabled ? window.pageYOffset : this.scrollTop;
    this.isScrollingEnabled = false;

    if (isIos()) {
      document.body.style.top = `-${this.scrollTop}px`;
      document.body.style.position = 'fixed';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
    }
  }

  public enableBodyScroll(): void {
    this.isScrollingEnabled = true;
    document.body.style.overflow = '';

    if (isIos()) {
      document.body.style.top = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
      window.scroll(0, this.scrollTop);
    }
  }

  public onIosTouchMoveFocusedInputScrollFix: TouchEventHandler = (event) => {
    if (isIos()) {
      const inputs = event.currentTarget.getElementsByTagName('input');

      for (let i = 0; i < (inputs || []).length; i++) {
        inputs[i].blur();
      }
    }
  };
}

export default ScrollUtils;
