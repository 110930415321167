import api from '../api/api.ts';
import { RATE_REVIEWS, RATE_REVIEWS_SUBSCRIPTIONS } from '../constants/api';
import type { UTMParams } from '../utils/queryString';

export interface Person {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  gid: string;
}

export interface Quote {
  premium: string;
  gid: string;
  deductible: string;
  coverages: Coverages;
  carrier: Carrier;
  online_bind_url?: string;
}

export interface ManualQuote {
  premium?: string;
  gid?: string;
  online_bind_url?: string;
  deductible?: string;
  coverages?: Coverages;
  carrier: Carrier;
}

interface Coverages {
  dwelling: string;
  other_structures: string;
  loss_of_use: string;
  personal_property: string;
  personal_liability: string;
  medical_payments: string;
}

interface Carrier {
  key: string;
  logo_url: string;
  marketing_name: string;
  marketing_description: string;
}

export interface Home {
  residency_type: string;
  address: string;
}

export interface Policy {
  expiration_date?: string | null;
  premium?: string | null;
  deductible?: string | null;
  dwelling?: string | null;
  carrier_name?: string;
}

export interface RateReview {
  savings: string | null;
  variation: string;
  person: Person;
  home: Home;
  quotes: Quote[];
  policy?: Policy;
}

export interface Success {
  success: boolean;
}

export interface UpdatePolicyParams {
  premium: string;
  deductible: string;
  end_date: string;
}

const rateReviews = {
  get(gid: string): Promise<RateReview> {
    return api.get(`${RATE_REVIEWS}/${gid}`);
  },
  selectQuote(gid: string, quote_gid: string, utmParams: UTMParams): Promise<Success> {
    return api.post(`${RATE_REVIEWS}/${gid}/select_quote`, {
      body: { quote_gid, ...utmParams },
    });
  },
  selectManualQuote(gid: string, carrier_key: string, utmParams: UTMParams): Promise<Success> {
    return api.post(`${RATE_REVIEWS}/${gid}/select_manual_quote`, {
      body: { carrier_key, ...utmParams },
    });
  },
  scheduleCall(gid: string, utmParams: UTMParams): Promise<Success> {
    return api.post(`${RATE_REVIEWS}/${gid}/schedule_call`, {
      body: utmParams,
    });
  },
  updatePolicy(gid: string, params: UpdatePolicyParams): Promise<Success> {
    return api.put(`${RATE_REVIEWS_SUBSCRIPTIONS}/${gid}/update_policy`, {
      body: { policy: params },
    });
  },
};

export default rateReviews;
