import type { FC } from 'react';

import './MaticTeam.scss';

import { useParams } from 'react-router-dom';

import { PAGE_KEYS, PHONE_CONVERSATION_INITIATED } from '../../constants/analytics';
import Sirv from '../../constants/sirv';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage.tsx';
import analytics from '../../utils/analytics.util.ts';
import { Card, CardContent } from '../Card/Card';

function trackPhoneConversationInitiated(gid: string) {
  const props = {
    page: PAGE_KEYS.CONFIRMATION,
    location: 'matic team block',
  };

  analytics.trackV1(PHONE_CONVERSATION_INITIATED, gid, props);
}

const MaticTeam: FC = () => {
  const { data: settings } = useSettingsQuery();
  const gid = (useParams as any)().gid;

  const onPhoneClick = () => trackPhoneConversationInitiated(gid);

  return (
    <Card className="c-matic-team">
      <CardContent className="c-matic-team__content">
        <div className="c-matic-team__team">
          <SirvImage className="c-matic-team__photo" url={Sirv.TEAM} width={96} height={96} alt="Matic Team" />
          <div className="c-matic-team__message">
            <h3 className="u-mb-1">The Matic Team</h3>
            <p>
              We look forward to connecting with you. In the meantime, if you need immediate assistance please contact
              us.
            </p>
          </div>
        </div>
        <div className="c-matic-team__contacts">
          <h4 className="c-matic-team__questions u-mb-1">Have questions?</h4>

          <a href={`tel:${settings?.phone_number}`} className="c-matic-team__phone" onClick={onPhoneClick}>
            {settings?.phone_number}
          </a>
          <a href={`mailto:${settings?.email}`} className="c-matic-team__mail">
            {settings?.email}
          </a>
        </div>
      </CardContent>
    </Card>
  );
};

export default MaticTeam;
