import { css } from '@emotion/react';
import THEME from '../../styles/theme';
import { mq } from '../../styles/media-queries';

export const containerCSS = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${THEME.color.gray10};
  background-color: ${THEME.color.white};
  z-index: 99;

  ${mq[1]} {
    padding: 20px 80px;
  }
`;

export const logoCSS = css`
  position: relative;
  height: 32px;
`;
