import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

// TODO remove this package later
// import '../node_modules/normalize.css/normalize.css';

import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App';
import THEME from './styles/theme.ts';
import { QUERY_CLIENT_CONFIG } from './constants/queryClientConfig.ts';

const muiTheme = createTheme({
  typography: {
    fontFamily: THEME.typography.fontFamily,
    fontSize: THEME.typography.fontSize,
  },
});
const queryCache = new QueryClient(QUERY_CLIENT_CONFIG);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ThemeProvider theme={muiTheme}>
      <QueryClientProvider client={queryCache}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>,
);
