import * as React from 'react';

import Container from '../Container/Container';
import Logo from '../Logo/Logo';

import './Header.scss';

interface HeaderProps {
  partnerLogo?: string;
  partnerName?: string;
  children?: React.ReactNode;
}

const Header: React.FunctionComponent<HeaderProps> = ({ partnerLogo, partnerName, children }) => (
  <header className="c-header">
    <Container className="c-header__inner">
      <Logo partnerLogo={partnerLogo} partnerName={partnerName} />
      {children}
    </Container>
  </header>
);

export default Header;
