import { css } from '@emotion/react';
import THEME from '../../../../styles/theme';
import { mq } from '../../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: relative;

  :before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: ${THEME.color.white};
    z-index: -1;
  }
`;

export const imgWrapperCSS = css`
  padding: 23px;
  border: 1px solid ${THEME.color.gray10};
  border-radius: 50%;
  display: none;

  ${mq[1]} {
    display: flex;
  }
`;

export const descriptionCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  color: ${THEME.color.gray60};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  ${mq[1]} {
    font-size: 27px;
    line-height: 38px;
    flex-direction: row;
  }
`;

export const countCSS = css`
  font-size: 20px;
  line-height: 28px;
  width: 32px;
  height: 32px;
  border: 2px solid ${THEME.color.gray30};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq[1]} {
    width: 24px;
    height: 24px;
  }
`;
