import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { ANALYTICS_SESSION_GID } from '../constants/browser-storage.ts';
import analytics from '../utils/analytics.util.ts';
import type { PageSegmentFunction, TrackSegmentFunction } from '../types/analytics.type.ts';

function useSegment() {
  const { gid } = useParams<{ gid: string }>();

  const track = useCallback<TrackSegmentFunction>(
    (eventName, properties) => {
      const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

      analytics.track(eventName, {
        account_gid: gid || null,
        session_gid: sessionGid,
        ...properties,
      });
    },
    [gid],
  );

  const page = useCallback<PageSegmentFunction>(
    (pageKey, properties) => {
      const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);
      const pageName = analytics.generatePageName(pageKey);

      analytics.page(pageName, {
        account_gid: gid || null,
        session_gid: sessionGid,
        ...properties,
      });
    },
    [gid],
  );

  return {
    track,
    page,
  };
}

export default useSegment;
