import type { Quote } from '../../../types/quote.type.ts';
import { QuoteBundleType } from './QuoteDetails.type.ts';

export const SAVINGS_TOOLTIP_CONTENT = 'The estimated savings is your current policy premium '
  + 'minus your suggested quote.';
export const DEDUCTIBLE_TOOLTIP_CONTENT = 'Your deductible is the amount you’ll have to pay out '
  + 'of pocket before your insurance policy kicks in for a covered claim.';
export const DWELLING_TOOLTIP_CONTENT = 'If your house is damaged by a covered event this coverage '
  + 'can help you pay to repair or rebuild your home.';
export const POLICY_START_DATE_TOOLTIP_CONTENT = 'This is the earliest possible effective date if you '
  + 'choose to buy this policy. Choose a start date that works for you.';

export function getBundleType(homeQuote: Quote, autoQuote?: Quote): null | QuoteBundleType {
  if (!autoQuote) {
    return null;
  }

  return homeQuote.carrier === autoQuote.carrier ? QuoteBundleType.BundleApplied : QuoteBundleType.MultiPolicyAvailable;
}
