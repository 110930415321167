export const CHART_DATA_SET = {
  status: 'ready',
  history: {
    2022: {
      policy_premium: '1500',
      quote_premium: '1230',
    },
    2023: {
      policy_premium: '1570',
      quote_premium: '1300',
    },
    2024: {
      policy_premium: '1500',
      quote_premium: '1230',
    },
    2025: {
      policy_premium: '1600',
      quote_premium: '1300',
    },
  },
};

export const EST_TOOLTIP = 'Your estimated premium is based on your current premium and the average percent '
  + 'increase of premiums in your state renewing this year and next year. Rates and discounts vary, are determined '
  + 'by many factors, and are subject to change. Our prediction model has a mean absolute percentage error of '
  + '9%, meaning that on average, our predictions may be off by about 9% compared to actual renewal premium.';
