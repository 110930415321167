import type { FC } from 'react';
import { MAX_QUOTE_COUNT } from '../../constants/quotes';
import type { Quote, RateReview } from '../../services/rateReviews';
import type { RateReviewCallbacks } from '../RateReview/RateReview.type';
import NoQuotes from './NoQuotes/NoQuotes';
import NoSavings from './NoSavings/NoSavings';
import Savings from './Savings/Savings';

const VariationMap: any = {
  no_quotes: NoQuotes,
  no_savings: NoSavings,
  savings: Savings,
};

const quotesCountMap: any = {
  no_quotes: MAX_QUOTE_COUNT,
  no_savings: MAX_QUOTE_COUNT,
  savings: MAX_QUOTE_COUNT + 1,
};

export interface RateReviewVariation {
  rateReviewData: RateReview;
  callbacks: RateReviewCallbacks;
  quotes: Quote[];
}

export interface VariationResult {
  variation: FC<RateReviewVariation>;
  maxQuotesCount: number;
}

function Variation(variation: string): VariationResult {
  return {
    variation: VariationMap[variation],
    maxQuotesCount: quotesCountMap[variation],
  };
}

export default Variation;
