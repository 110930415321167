import { create } from 'zustand';

interface State {
  lastUserInteractionTime: number;
}

interface Action {
  updateLastUserInteractionTime: (firstName: State['lastUserInteractionTime']) => void;
}

const useSessionTimeoutStore = create<State & Action>(set => ({
  lastUserInteractionTime: Date.now(),
  updateLastUserInteractionTime: timestamp => set(() => ({ lastUserInteractionTime: timestamp })),
}));

export default useSessionTimeoutStore;
