import { skipToken, useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import type { HTTPResponse } from '../../types/api.type';
import type { Person } from '../../types/related-person.type';
import api from '../api';

function usePersonQuery({ personGid }: { personGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.person, personGid],
    queryFn: personGid
      ? async () => await api.get<HTTPResponse<{ person: Person }>>(API_PATH.person.replace(':gid', personGid))
      : skipToken,
    select: ({ data }) => data.person,
  });
}

export default usePersonQuery;
