import type { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useAccountQuery from '../../../api/account/use-account-query.ts';
import useAvailableCarriersQuery from '../../../api/available-carriers/use-available-carriers-query.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import {
  addQuoteData,
  getFullCarriersList,
  getQuotesMap,
  sortByQuoteStatus,
  sortBySupported,
  sortQuoteOrder,
} from '../CarrierOptionsList/CarrierOptionsList.util.ts';
import { buttonCSS, listContainerCSS } from '../CarrierOptionsList/CarrierOptionsList.style.ts';
import QuoteItem from '../QuoteItem/QuoteItem.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import noop from '../../../shared/utils/noop.ts';
import type { QuotesListProps } from './QuotesList.type.ts';

const QuotesList: FC<QuotesListProps> = ({ optionsLimit, quoteGidInProcess, onQuoteSelect = noop }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: accountData } = useAccountQuery(gid);
  const { data: availableCarriersData } = useAvailableCarriersQuery(gid);
  const { data: quotesData } = useQuotesQuery(gid, !!accountData?.account.profile_completed_at);
  const quotesMap = getQuotesMap(quotesData?.quotes);

  const quotingStatus = quotesData?.quotes_request_status;

  const optionsToRender = getFullCarriersList(availableCarriersData, quotesData)
    .map(carrier => addQuoteData(carrier, quotesMap, !!accountData?.account.profile_completed_at, quotingStatus));

  const [showAllCarriers, setShowAllCarriers] = useState(false);
  const showExpandButton = !showAllCarriers
    && (optionsToRender.length > optionsLimit
    || optionsToRender.some(({ carrier }) => !carrier.supported));

  const handleShowMoreClick = () => {
    setShowAllCarriers(true);
  };

  return (
    <>
      <div css={listContainerCSS}>
        {optionsToRender
          .sort((a, b) => sortBySupported(!!a.carrier.supported, !!b.carrier.supported))
          .sort((a, b) => sortQuoteOrder(a.quote.order, b.quote.order))
          .sort((a, b) => sortByQuoteStatus(a.quote.status, b.quote.status))
          .filter(({ carrier }) => showAllCarriers || carrier.supported)
          .slice(0, showAllCarriers ? optionsToRender.length : optionsLimit)
          .map(({ carrier, quote }, index) => {
            return (
              <QuoteItem
                key={carrier.key}
                carrier={carrier}
                quote={quote}
                isUpdating={quote.gid === quoteGidInProcess}
                onQuoteChoose={quoteGid => onQuoteSelect(quoteGid, index)}
              />
            );
          })}
      </div>

      {showExpandButton && (
        <Button customCSS={buttonCSS} variant="secondary" fullWidth onClick={handleShowMoreClick}>
          View full carrier list
        </Button>
      )}

    </>
  );
};

export default QuotesList;
