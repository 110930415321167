import { skipToken, useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import type { Agent } from '../../types/agent.type';
import type { HTTPResponse } from '../../types/api.type';
import api from '../api';

function useAgentContactInfoQuery({ agentGid }: { agentGid?: string }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.agentContactInfo, agentGid],
    queryFn: agentGid
      ? async () => await api.get<HTTPResponse<Agent>>(API_PATH.agentContactInfo.replace(':gid', agentGid))
      : skipToken,
    select: ({ data }) => data,
  });
}

export default useAgentContactInfoQuery;
