import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import THEME from '../../styles/theme';

export const heroImgCSS = css`
  width: 100%;
  margin: 20px 0;
`;

export const heroSubheadingCSS = css`
  font-size: 16px;
  line-height: 20px;
`;

export const heroDescriptionCSS = css` 
  font-size: 20px;
  line-height: 28px;
`;

export const creditImpactsCSS = css`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 12px;
  text-align: center;
  font-weight: 700;

  img {
    max-width: 275px;
  }

  ${mq[1]} {
    flex-direction: row;
    gap: 35px;
    text-align: start;
    font-weight: 500;
  }
`;

export const improveSuggestionCSS = css`
  display: flex;

  svg {
    margin: 20px;
    flex-shrink: 0;
  }

  p:not(:last-child) {
    margin-bottom: 14px;
  }
`;

export const infoCSS = css`
  font-style: italic;
  color: ${THEME.color.gray60};
`;
