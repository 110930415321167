import { css } from '@emotion/react';

export const cardCSS = css`
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  padding: 24px;
  border-radius: 8px;
`;

export const descriptionCSS = css`
  text-align: center;
  font-weight: 500;
`;

export const titleCSS = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 8px;
  text-align: center;
`;

export const buttonCSS = css`
  margin-top: 36px
`;
