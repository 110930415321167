import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { formatDateTimeValue } from '../../../utils/date.util';
import Select from '../../../shared/components/Select/Select';
import { ActionCableContext } from '../../../utils/actioncable';
import type { Subscription } from '../../../services/ActionCable';
import usePersonProposalsQuery from '../../../api/proposal/use-person-proposals-query';
import Modal from '../../../shared/components/Modal/Modal';
import { ModalSize } from '../../../shared/components/Modal/Modal.type';
import type { ProposalsHistoryProps } from './ProposalsHistory.type';
import { proposalsSelectCSS } from './ProposalsHistory.style';

const ProposalsHistory: FC<ProposalsHistoryProps> = ({
  personGid,
  engagementGid,
  openedProposalGid,
  onOpenProposal,
}) => {
  const cable = useContext(ActionCableContext);
  const { data: proposals, refetch: refetchProposals } = usePersonProposalsQuery({ personGid, engagementGid });
  const [showProposalUpdatedModal, setShowProposalUpdatedModal] = useState(false);

  useEffect(() => {
    let engagementProposalsSubscription: Subscription;

    if (cable) {
      engagementProposalsSubscription = cable.subscriptions.create(
        { channel: 'EngagementProposalsChannel', engagement_gid: engagementGid },
        { received: async () => {
          const { data: updatedProposals } = await refetchProposals();

          if (updatedProposals && !updatedProposals[0].opened_at) {
            setShowProposalUpdatedModal(true);
          }
        } },
      );
    }

    return () => {
      if (cable) {
        engagementProposalsSubscription.unsubscribe();
      }
    };
  }, [cable, engagementGid, refetchProposals]);

  if (!proposals || proposals.length === 1) {
    return null;
  }

  const latestProposal = proposals?.[0];
  const sentProposals = proposals?.filter(({ sent_at }) => sent_at);

  return (
    <>
      {sentProposals && sentProposals.length > 1 && (
        <Select
          customCSS={proposalsSelectCSS}
          inputId="opened-proposal-gid"
          value={openedProposalGid}
          options={proposals.map(({ gid, created_at }) => {
            const formattedCreatedAt = formatDateTimeValue(created_at);

            return {
              value: gid,
              label: `Offer updated ${formattedCreatedAt}${latestProposal.gid === gid ? ' (Most recent)' : ''}`,
            };
          })}
          onValidEntry={proposalGid => onOpenProposal(proposalGid as string)}
        />
      )}
      {showProposalUpdatedModal && (
        <Modal
          title="Your proposal has been updated"
          size={ModalSize.Medium}
          isOpen
          actionButtons={[
            {
              text: 'Reload',
              onClick: () => {
                setShowProposalUpdatedModal(false);
                onOpenProposal(latestProposal.gid);
              },
            },
          ]}
        >
          <p>
            Your agent has made updates to your proposal. Please reload the page to see the latest information. You will
            also be able to switch the previous version for your reference.
          </p>
        </Modal>
      )}
    </>
  );
};

export default ProposalsHistory;
