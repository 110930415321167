import { css } from '@emotion/react';

import { mq } from '../../../../styles/media-queries';

export const linksCSS = css`
  margin-bottom: 32px;
`;

export const paragraphCSS = css`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const socialLinksCSS = css`
  display: flex;
  justify-content: center;
  a:not(:last-child) {
    margin-right: 20px;
  }
  ${mq[1]} {
    justify-content: unset;
  }
`;
