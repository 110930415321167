import type { Factor } from '../../types/score.type.ts';

export function addOrderNumber(arr?: Factor[], isMobile?: boolean): Array<Factor & { order: number }> {
  if (!arr) {
    return [];
  }
  if (isMobile) {
    return arr.map((factor, index) => ({ ...factor, order: index + 1 }));
  }

  const mid = Math.ceil(arr.length / 2);

  return arr.map((factor, index) => {
    const order = index < mid ? index * 2 + 1 : (index - mid) * 2 + 2;
    return { ...factor, order };
  });
}
