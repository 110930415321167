import type { ManualQuote, Quote } from '../services/rateReviews';

function quoteType(quote: Quote | ManualQuote): string {
  if (!quote.premium)
    return 'manual';
  if (quote.online_bind_url)
    return 'online_bind';
  return 'auto';
}

export default quoteType;
