import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { HTTPResponse } from '../../types/api.type.ts';
import type { AccountResponse } from '../../types/account.type.ts';
import api from '../api.ts';
import { ANALYTICS_SESSION_GID, LOGGED_OUT_SESSION_GID } from '../../constants/browser-storage.ts';
import API_PATH from '../../constants/api.ts';

function useSignOut() {
  const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await api.delete<HTTPResponse<AccountResponse>>(`${API_PATH.sessionCurrent}?session_gid=${sessionGid}`)
        .then(async (res) => {
          sessionStorage.setItem(LOGGED_OUT_SESSION_GID, sessionGid || '');
          localStorage.removeItem(ANALYTICS_SESSION_GID);
          await queryClient.invalidateQueries();
          return res.data;
        }),
  });
}
export default useSignOut;
