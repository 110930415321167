import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export function containerInnerCSS(showBackButton: boolean): SerializedStyles {
  return css`
    padding-top: ${showBackButton ? 27 : 48}px;
    padding-bottom: 130px;
    margin: 0;
  `;
}

export const appContainerCSS = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
