import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import useScoreQuery from '../../api/score/use-score-query.ts';
import ProtectionIconLarge from '../../assets/svg/factors/protection-factor-large.svg?react';
import ProtectionIconSmall from '../../assets/svg/factors/protection-factor-small.svg?react';
import ReplacementIconLarge from '../../assets/svg/factors/replacement-factor-large.svg?react';
import ReplacementIconSmall from '../../assets/svg/factors/replacement-factor-small.svg?react';
import RiskIconLarge from '../../assets/svg/factors/risk-factor-large.svg?react';
import RiskIconSmall from '../../assets/svg/factors/risk-factor-small.svg?react';
import FactorItem from '../../components/FactorItem/FactorItem.tsx';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import SkeletonLoader from '../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { ScoreType } from '../../types/score.type.ts';

import { pageHeadingCSS, pageSubheadingCSS } from '../../styles/layout.style.ts';
import useScrollToBlockOnInit from '../../hooks/use-scroll-to-block-on-init.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { HOW_WE_CALCULATE_PROTECTION_SCORE, HOW_WE_CALCULATE_RISK_SCORE } from '../insights/ScoreCards/ScoreCards.util.ts';
import ReplacedText from '../../shared/components/ReplacedText/ReplacedText.tsx';
import { useDynamicTextReplacements } from '../../hooks/use-dynamic-text-replacements.tsx';
import { activeTabCSS, factorCSS, factorsContainerCSS, tabWithTagCSS, tabsCSS, tagCSS } from './index.style.ts';
import FooterCopy from './FooterCopy/FooterCopy.tsx';
import { addOrderNumber } from './index.util.ts';

const SnapshotDetailsLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { isMobile } = useResponsive();
  const { data: riskScoreData } = useScoreQuery(gid, ScoreType.Risk);
  const { data: protectionScoreData } = useScoreQuery(gid, ScoreType.Protection);
  const { data: replacementScoreData } = useScoreQuery(gid, ScoreType.Replacement);
  const dynamicTextReplacements = useDynamicTextReplacements();

  const riskFactors = addOrderNumber(riskScoreData?.score.factors, isMobile);
  const protectionFactors = addOrderNumber(protectionScoreData?.score.factors, isMobile);
  const replacementFactors = addOrderNumber(replacementScoreData?.score.factors, isMobile);

  useTrackPage(AnalyticPageKey.SnapshotDetails);
  useScrollToBlockOnInit();

  const renderLoadingFactors = () => {
    const count = isMobile ? [1, 2, 3] : [1, 2, 3, 4, 5, 6];
    return count.map(key => <SkeletonLoader key={key} customCSS={factorCSS} absoluteHeight={40} width={100} />);
  };

  return (
    <PageContainer pageKey={AnalyticPageKey.SnapshotDetails} footerCopy={<FooterCopy />} showBackButton>
      <h1 css={pageHeadingCSS}>Protection Snapshot Details</h1>
      <p css={pageSubheadingCSS}>The following factors impacted your risk and protection scores.</p>
      <div css={tabsCSS}>
        <div css={activeTabCSS}>Home Protection Scores</div>
        <div css={tabWithTagCSS}>
          <span>Auto Protection Scores</span>
          <span css={tagCSS}>Coming Soon</span>
        </div>
      </div>

      <div id="scroll-to-risk" />
      <SectionHeading
        title="Risk Factors"
        subtitle="Your level of risk can impact your home insurance rates and carrier options.
        The factors used to calculate your risk score are listed below."
        icon={isMobile ? <RiskIconSmall /> : <RiskIconLarge />}
        size="large"
        tooltip={HOW_WE_CALCULATE_RISK_SCORE?.map(hint => (
          <ReplacedText
            replacements={dynamicTextReplacements}
            template={hint}
            key={hint}
          />
        ))}
      />
      <div css={factorsContainerCSS}>
        {riskFactors.length
          ? riskFactors
            .sort((a, b) => a.order - b.order)
            .map(f => (
              <FactorItem
                customCSS={factorCSS}
                key={f.key}
                title={f.title}
                titleHint={f.tooltip}
                value={f.display_value}
                valueHint={f.explanation}
                riskLevel={f.risk}
                impactLevel={f.impact_level}
              />
            ))
          : renderLoadingFactors()}
      </div>

      <div id="scroll-to-protection" />
      <SectionHeading
        title="Protection Factors"
        subtitle="Your protection score takes into account your current level of coverage and the
        steps you’ve taken to help protect your home from damage or theft."
        icon={isMobile ? <ProtectionIconSmall /> : <ProtectionIconLarge />}
        size="large"
        tooltip={HOW_WE_CALCULATE_PROTECTION_SCORE?.map(hint => (
          <ReplacedText
            replacements={dynamicTextReplacements}
            template={hint}
            key={hint}
          />
        ))}
      />
      <div css={factorsContainerCSS}>
        {protectionFactors.length
          ? protectionFactors
            .sort((a, b) => a.order - b.order)
            .map(f => (
              <FactorItem
                customCSS={factorCSS}
                key={f.key}
                title={f.title}
                titleHint={f.tooltip}
                value={f.display_value}
                valueHint={f.explanation}
                riskLevel={f.risk}
                impactLevel={f.impact_level}
              />
            ))
          : renderLoadingFactors()}
      </div>

      <div id="scroll-to-replacement" />
      <SectionHeading
        title="Replacement Factors"
        subtitle="When estimating the replacement cost, the following factors significantly
        impact the estimated cost to rebuild your home."
        icon={isMobile ? <ReplacementIconSmall /> : <ReplacementIconLarge />}
        size="large"
      />
      <div css={factorsContainerCSS}>
        {replacementFactors.length
          ? replacementFactors
            .sort((a, b) => a.order - b.order)
            .map(f => (
              <FactorItem
                customCSS={factorCSS}
                key={f.key}
                title={f.title}
                titleHint={f.tooltip}
                value={f.display_value}
                valueHint={f.explanation}
                riskLevel={f.risk}
                impactLevel={f.impact_level}
              />
            ))
          : renderLoadingFactors()}
      </div>
    </PageContainer>
  );
};

export default SnapshotDetailsLayout;
