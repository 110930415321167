export enum FactorLevel {
  Low = 'low',
  Moderate = 'moderate',
  High = 'high',
  Unknown = 'unknown',
}

export interface Factor {
  key: string;
  title: string;
  risk?: FactorLevel | null;
  score?: number | null;
  tooltip?: string[];
  explanation?: string;
  impact_level?: FactorLevel | null;
  display_value?: string;
}

export enum ScoreType {
  Risk = 'risk',
  Protection = 'protection',
  Replacement = 'replacement',
}

export interface Score {
  key: ScoreType;
  value: number | null;
  max_value: number | null;
  factors: Factor[];
}

export interface ScoreResponse {
  score: Score;
}
