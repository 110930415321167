import { css } from '@emotion/react';
import { ScoreGaugeType } from '../ScoreCard/ScoreCard.type.ts';
import { GAUGE_PALETTE, getGaugeColor } from '../ScoreCard/ScoreCard.util.ts';
import THEME from '../../../styles/theme.ts';

export const gaugeContainerCSS = css`
  position: relative;
`;

export function gaugeCSS(score: number, scoreMax: number, scoreType: ScoreGaugeType) {
  const palette = scoreType === ScoreGaugeType.Positive ? GAUGE_PALETTE : GAUGE_PALETTE.slice().reverse();
  return {
    '.MuiGauge-referenceArc': {
      fill: THEME.color.gray5,
    },
    '.MuiGauge-valueArc': {
      fill: getGaugeColor(score, scoreMax, palette),
    },
  };
}

export const gaugeTextCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 26px;
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;

export const infoMessageCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 12px;
  line-height: 16px;
`;
