import './ManualQuoteCard.scss';
import '../QuoteCard/QuoteCard.scss';

import type { FC } from 'react';
import { useState } from 'react';

import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Button from '../../kit_deprecated/Button/Button';
import type { ManualQuote } from '../../services/rateReviews';
import { Card, CardContent } from '../Card/Card';
import QuoteDetailsModal from '../QuoteDetailsModal/QuoteDetailsModal';
import type { onManualQuoteSelect, onQuoteReview } from '../RateReview/RateReview.type';

interface Props {
  quote: ManualQuote;
  onManualQuoteSelect: onManualQuoteSelect;
  onQuoteReview: onQuoteReview;
  ordinal: number;
}

const ManualQuoteCard: FC<Props> = ({ quote, onManualQuoteSelect, onQuoteReview, ordinal }) => {
  const { data: settings } = useSettingsQuery();
  const onChooseQuote = () => onManualQuoteSelect(quote, ordinal);
  const [areDetailsVisible, setDetailsVisible] = useState(false);
  return (
    <>
      <Card className="c-manual-quote-card">
        <CardContent>
          <div className="c-quote-card-content" data-testid={quote.carrier.key}>
            <div className="c-quote-card-content__logo-container">
              <img className="c-quote-card-content__logo" src={quote.carrier.logo_url} alt="Carrier Logo" />
            </div>

            <div className="c-quote-card-content__description">
              Some carriers won’t allow us to display quotes online. Call a Matic agent at
              {' '}
              <a className="o-link u-text--no-break" href={`tel:${settings?.phone_number}`}>
                {settings?.phone_number}
              </a>
            </div>

            <div className="c-quote-card-content__actions">
              <Button className="c-quote-card-content__choose" onClick={onChooseQuote}>
                Talk to Matic
              </Button>

              <div className="c-quote-card-content__expand-details">
                <a
                  className="o-link o-link--secondary c-quote-card-content__show-details"
                  onClick={() => {
                    onQuoteReview(quote, ordinal);
                    setDetailsVisible(true);
                  }}
                >
                  Show Details
                </a>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <QuoteDetailsModal
        visible={areDetailsVisible}
        quote={quote}
        phoneNumber={settings?.phone_number}
        onClose={() => {
          setDetailsVisible(false);
        }}
      >
        <Button data-testid="choose-quote-modal-button" onClick={onChooseQuote}>
          Talk to Matic
        </Button>
      </QuoteDetailsModal>
    </>
  );
};

export default ManualQuoteCard;
