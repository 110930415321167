import type { ModifiedPostData, WPItem, WPTerm } from '../../types/matic-content.type';

function getCategoryLabels(data: WPTerm[][], shadowCategoryId: number): string[] {
  return data.flat()
    .filter(term => term.taxonomy === 'category' && term.id !== shadowCategoryId)
    .map(term => term.name);
}

export function dataModifier(data: WPItem[], categoryId: number): ModifiedPostData[] {
  return data.map((item): ModifiedPostData => ({
    img: {
      src: item._embedded['wp:featuredmedia'][0].link,
      alt: item._embedded['wp:featuredmedia'][0].alt_text,
    },
    title: item.title.rendered,
    categories: getCategoryLabels(item._embedded['wp:term'], categoryId),
    link: item.link,
  }));
}
