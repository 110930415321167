export const PAGES = {
  QUOTES: 'Quotes Page',
  CONFIRMATION: 'Confirmation page',
  RATE_REVIEW_FOLLOW_UP: 'Rate Review Follow Up Page',
  ERROR: 'Error Page',
};

export const PAGE_KEYS = {
  QUOTES: 'quotes',
  CONFIRMATION: 'confirmation',
  ERROR: 'error',
};

export const FLOW_STARTED = 'Flow Started';
export const ONLINE_BIND_OFFERED = 'Online Bind Offered';
export const QUOTE_REVIEWED = 'Quote Reviewed';
export const QUOTE_SELECTED = 'Quote Selected';
export const SHOW_MORE_QUOTES_CLICKED = 'Show More Quotes Clicked';
export const PHONE_CONVERSATION_INITIATED = 'Phone Conversation Initiated';
export const CONVERSATION_SCHEDULING_INITIATED = 'Conversation Scheduling Initiated';
export const CONVERSATION_SCHEDULED = 'Conversation Scheduled';
export const FAQ_CLICKED = 'FAQ Clicked';

export const PROPOSAL_SEGMENTS = {
  events: {
    agentContactViewed: 'Agent Contacts Viewed',
  },
  locations: { bundleCard: 'bundle_card', adCard: 'ad_card' },
  pages: { proposal: 'proposal' },
};
