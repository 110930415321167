import './Card.scss';

import type { FC } from 'react';

import classnames from 'classnames';
import type { CardContentProps, CardHeadingProps, CardProps } from './Card.type';
import { CardPadding } from './Card.type';

export const Card: FC<CardProps> = ({ className = null, children, onClick }) => (
  <div className={classnames('c-card', className)} onClick={onClick}>
    {children}
  </div>
);

export const CardHeading: FC<CardHeadingProps> = ({ className = null, heading }) => (
  <h4 className={classnames('c-card-heading', className)}>{heading}</h4>
);

export const CardContent: FC<CardContentProps> = ({
  className = null,
  children,
  padding = CardPadding.Standard,
}) => <div className={classnames('c-card-content', `c-card-content--${padding}`, className)}>{children}</div>;
