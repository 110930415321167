import { css } from '@emotion/react';

export const listContainerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
`;

export const buttonCSS = css`
  margin-top: 28px;
`;
