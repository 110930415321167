export const URLS = {
  MATIC_LOGO: 'https://maticinsurance-cdn.sirv.com/matic-logo/matic-header-logo-for-consumers.png',
  MATIC_WHITE_LOGO: 'https://maticinsurance-cdn.sirv.com/matic-logo/white.png',
};

export enum SVGUrls {
  Twitter = 'https://maticinsurance-cdn.sirv.com/icons/svg/twitter.svg',
  Linkedin = 'https://maticinsurance-cdn.sirv.com/icons/svg/linkedin.svg',
  Facebook = 'https://maticinsurance-cdn.sirv.com/icons/svg/facebook.svg',
  Instagram = 'https://maticinsurance-cdn.sirv.com/icons/svg/instagram.svg',
  Phone = 'https://maticinsurance-cdn.sirv.com/icons/svg/phone.svg',
  PhoneSecondary = 'https://maticinsurance-cdn.sirv.com/icons/svg/phone-secondary.svg',
  Close = 'https://maticinsurance-cdn.sirv.com/icons/svg/close.svg',
  Hamburger = 'https://maticinsurance-cdn.sirv.com/icons/svg/hamburger.svg',
  ArrowLeft = 'https://maticinsurance-cdn.sirv.com/icons/svg/arrow-left.svg',
  ArrowRight = 'https://maticinsurance-cdn.sirv.com/icons/svg/arrow-right.svg',
  Save = 'https://maticinsurance-cdn.sirv.com/icons/svg/save.svg',
  Exclamation = 'https://maticinsurance-cdn.sirv.com/icons/svg/exclamation.svg',
  Quote = 'https://maticinsurance-cdn.sirv.com/icons/svg/quote.svg',
  Heart = 'https://maticinsurance-cdn.sirv.com/icons/svg/heart.svg',
  Dot = 'https://maticinsurance-cdn.sirv.com/icons/svg/dot.svg',
  Pause = 'https://maticinsurance-cdn.sirv.com/icons/svg/pause.svg',
  Play = 'https://maticinsurance-cdn.sirv.com/icons/svg/play.svg',
}

const MATIC_WEBSITE = 'https://matic.com';

export const EXTERNAL_URLS = {
  MATIC_WEBSITE,
  WEBSITE_CONSUMERS: `${MATIC_WEBSITE}/for-consumers`,
  WEBSITE_ORIGINATORS: `${MATIC_WEBSITE}/for-originators`,
  WEBSITE_SERVICERS: `${MATIC_WEBSITE}/for-servicers`,
  WEBSITE_PRIVACY_POLICY: `${MATIC_WEBSITE}/privacy-policy`,
  WEBSITE_LICENSE_NUMBERS: `${MATIC_WEBSITE}/license-numbers`,
  WEBSITE_TERMS: `${MATIC_WEBSITE}/terms-of-use`,
  SOCIAL_TWITTER: 'https://twitter.com/maticinsurance',
  SOCIAL_LINKEDIN: 'https://www.linkedin.com/company/matic-insurance',
  SOCIAL_FACEBOOK: 'https://www.facebook.com/maticinsurance',
  SOCIAL_INSTAGRAM: 'https://www.instagram.com/matic.insurance',
  PHONE_NUMBER: '8184655388',
};
