import type { FC } from 'react';
import { useEffect, useState } from 'react';
import useMaticContentQuery from '../../api/matic-content/use-matic-content-query';
import { Card } from '../../shared/components/Card/Card';
import SkeletonLoader from '../../shared/components/SkeletonLoader/SkeletonLoader';
import useResponsive from '../../hooks/use-responsive';
import type { ModifiedPostData } from '../../types/matic-content.type.ts';
import useSegment from '../../hooks/use-segment.ts';
import { containerCSS } from './MaticContent.style';
import type { MaticContentProps } from './MaticContent.type';
import PostItem from './PostItem/PostItem';
import { postCardCSS, textContainerCSS } from './PostItem/PostItem.style';

const MaticContent: FC<MaticContentProps> = ({ customCSS, page }) => {
  const { data, isLoading } = useMaticContentQuery();
  const { isDesktop } = useResponsive();
  const [shuffledPosts, setShuffledPosts] = useState<ModifiedPostData[]>([]);
  const { track } = useSegment();

  useEffect(() => {
    if (data) {
      setShuffledPosts(data?.sort(() => Math.random() - 0.5));
    }
  }, [data]);

  const handlePostClick = (title: string) => {
    track('Blog Post Opened', { title, page });
  };

  if (isLoading) {
    const iterableArray = Array.from({ length: 3 }, (_, i) => i);

    return (
      <div css={[containerCSS, customCSS]}>
        {iterableArray.map((_, i) => (
          <Card customCSS={postCardCSS} key={`post-skeleton-${i + 1}`}>
            <>
              <SkeletonLoader {...(isDesktop && { absoluteWidth: 200 })} width={100} absoluteHeight={160} />
              <div css={textContainerCSS}>
                <SkeletonLoader width={80} absoluteHeight={20} />
              </div>
            </>
          </Card>
        ))}
      </div>
    );
  }

  return (
    <div css={[containerCSS, customCSS]}>
      {shuffledPosts?.slice(0, 3)
        .map(p => <PostItem {...p} key={p.title} onItemClicked={() => handlePostClick(p.title)} />)}
    </div>
  );
};

export default MaticContent;
