import type { FC, ReactNode } from 'react';

import type { SerializedStyles } from '@emotion/react';

import { cardCSS } from './Card.style';

interface CardProps {
  customCSS?: SerializedStyles | (SerializedStyles | undefined)[];
  children: ReactNode;
}

export const Card: FC<CardProps> = ({ customCSS, children }) => <div css={[cardCSS, customCSS]}>{children}</div>;
