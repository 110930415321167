import './Faq.scss';

import type { FC } from 'react';
import Sirv from '../../constants/sirv';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage';
import type { onFAQClicked } from '../RateReview/RateReview.type';
import FAQAccordion from '../../kit_deprecated/FAQAccordion/FAQAccordion';
import { FAQItems } from './Faq.const';

const Faq: FC<{ onFAQClicked: onFAQClicked }> = ({ onFAQClicked }) => {
  return (
    <div className="c-faq">
      <h2 className="c-faq__heading">Frequently Asked Questions</h2>
      <div className="c-faq__content">
        <div className="c-faq__accordion">
          <FAQAccordion items={FAQItems} onClick={({ title }) => onFAQClicked(title)} />
        </div>
        <div className="c-faq__illustration-block">
          <SirvImage className="c-faq__illustration" width={340} url={Sirv.ILLUSTRATIONS.BOOK_READ} />
        </div>
      </div>
    </div>
  );
};

export default Faq;
