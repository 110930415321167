// TODO: Remove after v2 release
export default {
  ICON: {
    AGENT: 'https://maticinsurance-cdn.sirv.com/icons/consumer/agent.png',
  },
  ILLUSTRATIONS: {
    BUNDLE: 'https://maticinsurance-cdn.sirv.com/illustrations/bundle.png',
    BOOK_READ: 'https://maticinsurance.sirv.com/illustrations/book-read.png',
    CALENDAR: 'https://maticinsurance.sirv.com/illustrations/calendar.svg',
    ERROR: 'https://maticinsurance-cdn.sirv.com/illustrations/error.png',
    QUOTE_SKELETON_HORIZONTAL: 'https://maticinsurance-cdn.sirv.com/illustrations/quote-skeleton-horizontal.png',
    QUOTE_SKELETON_VERTICAL: 'https://maticinsurance-cdn.sirv.com/illustrations/quote-skeleton-vertical.png',
  },
  TEAM: 'https://maticinsurance.sirv.com/agents/team.png',
};
