import React from 'react';
import PhoneIcon from '../../../../assets/svg/schedule-call/phone.svg?react';
import MailIcon from '../../../../assets/svg/schedule-call/mail.svg?react';
import SirvImage from '../../../../shared/components/SirvImage/SirvImage';
import Anchor from '../../../../shared/components/Anchor/Anchor';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import type { ScheduleCallTeamProps } from './ScheduleCallTeam.types';
import {
  assistanceCSS,
  assistanceContactsCSS,
  assistanceContentCSS,
  assistanceDescriptionCSS,
  assistanceHeadingCSS,
  assistanceTextCSS,
  contactIconCSS,
  contactsHeadingCSS,
  containerInnerCSS,
  imageCSS,
  linkCSS,
} from './ScheduleCallTeam.style';

const ScheduleCallTeam: React.FC<ScheduleCallTeamProps> = ({ agentEmail, agentPhone, onPhoneNumberClick }) => (
  <div css={containerInnerCSS}>
    <div css={assistanceCSS}>
      <SirvImage css={imageCSS} alt="Matic team" url={SIRV_IMAGES.teamImg} width={96} height={96} />
      <div css={assistanceContentCSS}>
        <div css={assistanceTextCSS}>
          <div css={assistanceHeadingCSS}>The Matic Team</div>
          <p css={assistanceDescriptionCSS}>
            We look forward to connecting with you. In the meantime, if you need immediate assistance please contact us.
          </p>
        </div>

        <div css={assistanceContactsCSS}>
          <div css={contactsHeadingCSS}>Have questions?</div>
          <Anchor css={linkCSS} href={`tel:${agentPhone}`} onClick={onPhoneNumberClick}>
            <PhoneIcon css={contactIconCSS} />
            <span>{agentPhone}</span>
          </Anchor>
          <Anchor css={linkCSS} href={`mailto:${agentEmail}`}>
            <MailIcon css={contactIconCSS} />
            <span>{agentEmail}</span>
          </Anchor>
        </div>
      </div>
    </div>
  </div>
);

export default ScheduleCallTeam;
