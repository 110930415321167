import { skipToken, useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import type { HTTPResponse } from '../../types/api.type';
import type { Session } from '../../types/session.type.ts';
import api from '../api';

export function getSession(gid: string) {
  return api
    .get<HTTPResponse<{ session: Session | null }>>(API_PATH.session.replace(':session_gid', gid));
}

function useSessionQuery(gid?: string | null) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.session, gid],
    queryFn: gid
      ? async () => await getSession(gid).then(res => res.data)
      : skipToken,
  });
}

export default useSessionQuery;
