import type { FC } from 'react';
import { Card } from '../../../shared/components/Card/Card';
import Button from '../../../shared/components/Button/Button';
import { buttonCSS, cardCSS, cardTitleCSS } from './PolicyCard.style';
import type { PolicyCardProps } from './PolicyCard.type';

const PolicyCard: FC<PolicyCardProps> = ({ icon, title, onActionClick, buttonText = 'Get Quotes' }) => {
  return (
    <Card customCSS={cardCSS}>
      <div css={cardTitleCSS}>
        <img src={icon.src} alt={icon.alt} />
        {title}
      </div>

      <Button customCSS={buttonCSS} onClick={onActionClick}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default PolicyCard;
