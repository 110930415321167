import type { FC } from 'react';
import { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Button from '../../../shared/components/Button/Button.tsx';
import ArrowIcon from '../../../assets/svg/arrow.svg?react';
import QuoteCoverage from '../QuoteCoverage/QuoteCoverage.tsx';
import { COVERAGES } from '../../../constants/coverages.ts';
import type { QuoteCoveragesProps } from './QuoteCoverages.type.ts';
import { actionButtonCSS, containerCSS, coverageCSS, policyDetailsButtonCSS } from './QuoteCoverages.style.ts';
import { filterExtraCoverages } from './QuoteCoverages.util.ts';

const QuoteCoverages: FC<QuoteCoveragesProps> = ({ customCSS, coverages, onActionButtonClick }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(s => !s);
  };

  return (
    <div css={[containerCSS(expanded), customCSS]}>
      <Collapse in={expanded}>
        {coverages.filter(filterExtraCoverages).map(c => (
          <QuoteCoverage
            customCSS={coverageCSS}
            key={c.key}
            title={COVERAGES[c.key]?.title}
            description={COVERAGES[c.key]?.description}
            icon={COVERAGES[c.key]?.icon}
            value={c.limit_value}
          />
        ))}
        <Button customCSS={actionButtonCSS} fullWidth onClick={onActionButtonClick}>
          Continue
        </Button>
      </Collapse>

      <button type="button" css={policyDetailsButtonCSS(expanded)} onClick={handleExpand}>
        <div>{expanded ? 'Close policy details' : 'See policy details'}</div>
        <span>
          <ArrowIcon />
        </span>
      </button>
    </div>
  );
};

export default QuoteCoverages;
