import './RecommendedQuoteCard.scss';

import type { FC } from 'react';
import { useState } from 'react';

import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Button from '../../kit_deprecated/Button/Button';
import type { Policy, Quote } from '../../services/rateReviews';
import Benefits from '../Benefits/Benefits';
import { Card, CardContent } from '../Card/Card';
import QuoteDetailsModal from '../QuoteDetailsModal/QuoteDetailsModal';
import type { onQuoteReview, onQuoteSelect } from '../RateReview/RateReview.type';
import { ButtonSize } from '../../kit_deprecated/Button/Button.type';

interface Props {
  quote: Quote;
  policy?: Policy;
  savings: string | null;
  onQuoteSelect: onQuoteSelect;
  onQuoteReview: onQuoteReview;
}

const ORDINAL = 0;

const RecommendedQuoteCard: FC<Props> = ({ quote, policy, savings, onQuoteSelect, onQuoteReview }) => {
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const { data: settingsData } = useSettingsQuery();
  const button = (
    <Button
      className="c-recommended-quote-card__call-btn"
      size={ButtonSize.Large}
      onClick={() => onQuoteSelect(quote, ORDINAL)}
    >
      <>
        Yes, I’m Interested
        <span className="u-hidden--sm"> in Lowering My Monthly Expenses</span>
      </>
    </Button>
  );

  return (
    <>
      <Card className="c-recommended-quote-card">
        <div className="c-recommended-quote-card__header">
          <h4>Recommended Quote</h4>
        </div>
        <CardContent>
          <div className="c-recommended-quote-card__row u-mb-9">
            <div className="c-recommended-quote-card__carrier-logo-wrapper">
              <img
                className="c-recommended-quote-card__carrier-logo"
                src={quote.carrier.logo_url}
                alt={quote.carrier.marketing_name}
              />
            </div>
            <div className="c-recommended-quote-card__saving-msg">
              <div className="c-recommended-quote-card__save">Save</div>
              <h2 className="c-recommended-quote-card__saving">{savings}</h2>
              <div className="c-recommended-quote-card__per-year">Per Year</div>
            </div>
          </div>

          <div className="u-mb-8">
            <Benefits newPremium={quote.premium} oldPremium={policy?.premium} />
          </div>

          <div className="c-recommended-quote-card__call">
            {button}
            <div className="c-recommended-quote-card__expand-details">
              <a
                className="o-link o-link--secondary expand-btn qa-expand-recommended-quote-details"
                onClick={() => {
                  onQuoteReview(quote, ORDINAL);
                  setAreDetailsVisible(true);
                }}
              >
                Show Details
              </a>
            </div>
          </div>
        </CardContent>
      </Card>
      <QuoteDetailsModal
        visible={areDetailsVisible}
        quote={quote}
        phoneNumber={settingsData?.phone_number}
        onClose={() => {
          setAreDetailsVisible(false);
        }}
      >
        {button}
      </QuoteDetailsModal>
    </>
  );
};

export default RecommendedQuoteCard;
