export enum HistoryDataStatuses {
  Ready = 'ready',
  Pending = 'pending',
  Unknown = 'unknown',
}

export interface HistoryResponse<T = PremiumHistoryItem> {
  history: HistoryData<T>;
}

export interface HistoryData<T = PremiumHistoryItem> {
  [key: string]: T;
}

export interface PremiumHistoryItem {
  policy_premium: string | null;
  quote_premium: string | null;
}

export interface ReplacementCostHistoryItem {
  replacement_cost: string | null;
  replacement_cost_min: string | null;
  replacement_cost_max: string | null;
}

export type QuotesOverTimeHistoryItem = string | null;

export interface DataItem { year: string }

export interface TransformedHistoryDataItem {
  year: string;
  values: number[];
}

export type TransformedHistoryData = TransformedHistoryDataItem[];

export interface TransformedReplacementCostDataItem {
  year: string;
  values: [ { min: number; max: number }, number];
}

export type TransformedReplacementCostData = TransformedReplacementCostDataItem[];
