import './QuoteDetailsModal.scss';

import React from 'react';

import FullScreenModal from '../../kit_deprecated/FullScreenModal/FullScreenModal';
import Modal from '../../kit_deprecated/Modal/Modal';
import type { ManualQuote, Quote } from '../../services/rateReviews';
import QuoteInfo from './QuoteInfo';

interface Props {
  quote: Quote | ManualQuote;
  visible: boolean;
  onClose: () => void;
  phoneNumber?: string;
  children?: React.ReactNode;
}

const QuoteDetailsModal: React.FunctionComponent<Props> = ({ children, quote, visible, phoneNumber, onClose }) => {
  const quoteInfo = <QuoteInfo quote={quote} phoneNumber={phoneNumber} />;

  return (
    <>
      <Modal isModalOpen={visible} onClose={onClose} overlayClassName="c-quote-details-modal__modal--desktop">
        {quoteInfo}
        <div data-testid="quote-details-modal" className="c-quote-details-modal__children">
          {children}
        </div>
      </Modal>

      <FullScreenModal className="c-quote-details-modal__modal--mobile" onClose={onClose} visible={visible}>
        {quoteInfo}
        <div className="c-quote-details-modal__children">{children}</div>
      </FullScreenModal>
    </>
  );
};

export default QuoteDetailsModal;
