import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

export const loaderContainerCSS = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${THEME.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
