import { css } from '@emotion/react';

import THEME from '../../../styles/theme';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const headerCSS = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const lineCSS = css`
  display: flex;
  justify-content: space-between;
`;

export const boldTextCSS = css`
  font-weight: bold;
`;

export const dividerLineCSS = css`
  height: 1px;
  width: 100%;
  background-color: ${THEME.color.gray10};
  margin: 12px 0;
`;

export const boldGreenTextCSS = css`
  font-weight: bold;
  color: ${THEME.color.positiveGreen27};
`;

export const italicGrayTextCSS = css`
  font-style: italic;
  color: ${THEME.color.gray80};
`;

export const premiumBlockCSS = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const insuranceTitleCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const paymentLineCSS = css`
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
`;
