import { css } from '@emotion/react';

export const containerCSS = css`
  margin-bottom: 32px;
`;

export const contentCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  text-align: center;
  max-width: 320px;
`;

export const iconCSS = css`
  width: 48px;
  height: 48px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const explanationCSS = css`
  font-size: 14px;
`;

export const carrierNumberCSS = css`
  font-size: 48px;
  font-weight: 900;
  margin-top: 12px;
`;

export const fetchingMessageCSS = css`
  font-size: 32px;
  font-weight: 700;
  margin-top: 12px;
`;
