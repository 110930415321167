import { create } from 'zustand';

interface BackButtonPathState {
  currentPath: string;
  prevPath: string;
  setCurrentPath: (pathname: string) => void;
  updateBeforeNavigate: (pathname: string) => void;
}

export const useBackButtonPathStore = create<BackButtonPathState>()(set => ({
  currentPath: '',
  prevPath: '',
  setCurrentPath: pathname => set(state => state.currentPath !== pathname
    ? {
        currentPath: pathname,
        prevPath: state.currentPath,
      }
    : state),
  updateBeforeNavigate: pathname => set({ currentPath: pathname, prevPath: '' }),
}));
