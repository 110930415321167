import * as React from 'react';

import './SirvImage.scss';

import classNames from 'classnames';
import queryString from 'query-string';

interface SirvImageProps {
  url: string;
  width: number;
  height?: number;
  className?: string;
  alt?: string;
}

interface ImgUrlParams extends SirvImageProps {
  multiplier?: number;
}

function getImgUrlWithParams({ height = 0, width, url, multiplier = 1 }: ImgUrlParams) {
  return url.concat('?', queryString.stringify({ w: width * multiplier, h: height * multiplier }));
}

const SirvImage: React.FunctionComponent<SirvImageProps> = ({ className, alt, ...props }) => {
  const imgSrcX1 = getImgUrlWithParams(props);
  const imgSrcX2 = getImgUrlWithParams({ ...props, multiplier: 2 });

  return (
    <div className={classNames('c-sirv-image', className)}>
      <img
        className="c-sirv-image__img"
        src={imgSrcX1}
        srcSet={`${imgSrcX1} 1x,
      ${imgSrcX2} 2x`}
        alt={alt}
      />
    </div>
  );
};

export default SirvImage;
