import './FAQAccordion.scss';

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

type Ordinal = string | number;

interface Item {
  key: string;
  title: string;
  description: string | React.ReactElement;
}

interface FAQProps {
  items: Item[];
  onClick?: (faq: Item, ordinal: Ordinal) => void;
}

const FAQAccordion: React.FunctionComponent<FAQProps> = ({ items, onClick }) => {
  const onFaqClick = ([ordinal]: Ordinal[]) => items[+ordinal - 1] && onClick && onClick(items[+ordinal - 1], +ordinal);

  return (
    <Accordion allowZeroExpanded onChange={onFaqClick}>
      {items.map(({ key, title, description }) => (
        <AccordionItem key={key} uuid={key}>
          <AccordionItemHeading>
            <AccordionItemButton>
              {title}
              <div className="accordion__arrow" />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>{description}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default FAQAccordion;
