import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';

import THEME from '../../../styles/theme.ts';

export function tooltipButtonCSS(size: number): SerializedStyles {
  return css`
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: ${size - 4}px;
  line-height: ${size}px;
  height: ${size}px;
  width: ${size}px;
  margin-left: 6px;
  padding: 0;
  vertical-align: middle;
  position: relative;
  flex-shrink: 0;
  color: ${THEME.color.gray60};
  background-color: transparent;

  &:hover svg {
    color: ${THEME.color.azure68};
  }

  ${mq[1]} {
    margin-left: 8px;
  }
`;
}

export function infoMarkCSS(size: number): SerializedStyles {
  return css`
    width: ${size}px;
    height: ${size}px;
`;
}
