import { type FC, useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import { isAxiosError } from 'axios';
import useAuthenticate from '../../api/authentification/use-authenticate.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import ROUTES from '../../constants/routes.ts';
import { useAnalyticsSessionGidCheck } from '../../hooks/use-analytics-session-gid-check.ts';
import { useUtmParams } from '../../hooks/use-utm-params.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import type { Account } from '../../types/account.type.ts';
import { getTargetPath } from '../../utils/routes.util.ts';
import useAccountQuery from '../../api/account/use-account-query.ts';

export interface AuthErrorData {
  message: string;
  isAxios: boolean;
  responseStatus?: number | null;
}

const Authenticate: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const accountGid = searchParams.get('gid');
  const personGid = searchParams.get('person_gid');
  const marketingEngagementGid = searchParams.get('engagement_gid');
  const targetPage = searchParams.get('target_page');
  const sessionGidParam = searchParams.get('session_gid');
  const utmParams = useUtmParams();

  const sessionGigLocalStorage = localStorage.getItem(ANALYTICS_SESSION_GID);

  const authSend = useRef(false);
  const [accountResponse, setAccountResponse] = useState<Account | null>(null);
  const [authenticateError, setAuthenticateError] = useState<AuthErrorData>();
  const { data: accountData, isFetched: isAccountFetched } = useAccountQuery(accountGid, false);
  const account = accountData?.account || accountResponse;

  const { mutateAsync: authenticate } = useAuthenticate();

  const { sessionGid } = useAnalyticsSessionGidCheck(
    sessionGidParam || sessionGigLocalStorage,
    null,
    utmParams,
  );

  useEffect(() => {
    const call = async (
      tokenValue: string,
      gidValue: string | null,
      personGidValue: string | null,
      sessionGidValue: string | null,
      marketingEngagementGidValue?: string | null,
    ) => {
      try {
        const response = await authenticate({
          token: tokenValue,
          gid: gidValue,
          person_gid: personGidValue,
          engagement_gid: marketingEngagementGidValue,
          session_gid: sessionGidValue,
        });
        setAccountResponse(response.account);
      }
      catch (error) {
        if (isAxiosError(error)) {
          setAuthenticateError({
            message: error.message,
            isAxios: true,
            responseStatus: error.response?.status || null,
          });
        }
        else {
          setAuthenticateError({
            message: error instanceof Error ? error.message : 'Unknown',
            isAxios: false,
          });
        }
      }
    };

    if (
      token
      && (accountGid || personGid)
      && !authSend.current
      && sessionGid
      && isAccountFetched
      && !accountData?.account) {
      authSend.current = true;
      call(token, accountGid, personGid, sessionGid, marketingEngagementGid);
    }
  }, [
    authenticate,
    accountGid,
    personGid,
    marketingEngagementGid,
    token,
    sessionGid,
    isAccountFetched,
    accountData?.account,
  ]);

  if (!token || !(accountGid || personGid)) {
    return <Navigate to={ROUTES.problem} replace />;
  }

  if (authenticateError) {
    const params = new URLSearchParams({
      gid: accountGid || '',
      person_gid: personGid || '',
      target_page: targetPage || '',
    });

    return authenticateError.isAxios && authenticateError.responseStatus === 401
      ? (
          <Navigate to={`${ROUTES.linkExpired}?${params.toString()}`} replace />
        )
      : <Navigate to={ROUTES.problem} replace />;
  }

  if (!account || !sessionGid) {
    return <FullPageLoader />;
  }

  return account?.verified
    ? <Navigate to={getTargetPath(account.gid, targetPage)} replace />
    : (
        <Navigate
          to={`${ROUTES.verify.replace(':gid', account.gid)
          }?target_page=${targetPage}`}
          replace
        />
      );
};

export default Authenticate;
