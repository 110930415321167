import * as React from 'react';

import './Loader.scss';

import classNames from 'classnames';

interface LoaderDotProps {
  inverted: boolean;
}

type LoaderProps = Partial<LoaderDotProps>;

const LoaderDot: React.FunctionComponent<LoaderDotProps> = ({ inverted }) => (
  <div
    className={classNames('c-loader__dot', {
      'c-loader__dot--inverted': inverted,
    })}
  />
);

const Loader: React.FunctionComponent<LoaderProps> = ({ inverted = false }) => (
  <div className="c-loader" data-testid="loader">
    <LoaderDot inverted={inverted} />
    <LoaderDot inverted={inverted} />
    <LoaderDot inverted={inverted} />
  </div>
);

export default Loader;
