import { css } from '@emotion/react';
import THEME from '../../styles/theme';

export const fakeTooltipCSS = css`
  --side-padding: 41px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 24px var(--side-padding);
  background-color: ${THEME.color.white};
  border: 1px solid ${THEME.color.gray30};
  width: 360px;
  height: 180px;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 2px 4px 0px 0px #0000001F;

  :after,
  :before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      top: 100%;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto;
  }

  :after {
      border-color: ${THEME.color.white} transparent transparent transparent ;
      border-width: 10px;
  }

  :before {
      border-color: ${THEME.color.gray30} transparent transparent transparent ;
      border-width: 11.5px;
  }
`;

export const titleCSS = css`
  margin: 0;
  font-size: 20px;
  line-height: 28px;
`;

export const descriptionCSS = css`
  margin-bottom: 18px;
  width: calc(100% + var(--side-padding));
  position: relative;
  left: calc(-1 * var(--side-padding) / 2);
`;
