export const MANUAL_QUOTES = [
  {
    carrier: {
      key: 'upc',
      logo_url: 'https://maticinsurance-cdn.sirv.com/carriers/upc.png',
      marketing_name: 'UPC',
      marketing_description:
        'UPC was founded in 1999 and has a track record of successful operations in '
        + 'catastrophe exposed areas. UPC has not only survived but thrived through 21 separate '
        + 'hurricanes and tropical storms. UPC Insurance is Fortune® magazine’s 38th fastest-growing '
        + 'company in the world and “A” rated with AM Best.',
    },
  },
  {
    carrier: {
      key: 'foremost',
      logo_url: 'https://maticinsurance-cdn.sirv.com/carriers/foremost.png',
      marketing_name: 'Foremost',
      marketing_description:
        'Owned by Farmers Insurance, Foremost is consistently ranked as one of America’s '
        + 'top insurance carriers. Foremost holds an AM Best “A” rating and scores high marks in customer service.',
    },
  },
];

export const MANUAL_INVESTMENT_QUOTES = [
  {
    carrier: {
      key: 'travelers',
      logo_url: 'https://maticinsurance-cdn.sirv.com/carriers/travelers.png',
      marketing_name: 'Travelers',
      marketing_description:
        'Travelers has been in business for over 160 years and is the top writer of homeowner’s '
        + 'insurance through independent agents in the United States. With annual revenue of $29 billion, '
        + 'Travelers is "A+" rated with both AM Best and the BBB.',
    },
  },
  {
    carrier: {
      key: 'american_modern',
      logo_url: 'https://maticinsurance.sirv.com/carriers/American%20modern.png',
      marketing_name: 'American Modern',
      marketing_description:
        'American Modern is an A+ rated provider of residential and recreational insurance. Headquartered '
        + 'in Cincinnati. American Modern is a widely recognized, national leader in the specialty insurance '
        + 'business. With close to 50 years experience in the manufactured housing insurance sector, the company '
        + 'delivers specialized products and services for residential property and the recreational market.',
    },
  },
];
