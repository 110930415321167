import { forwardRef } from 'react';

import { focusHelperCSS, inputCSS, inputWrapperCSS } from './Input.style';
import type { InputProps } from './Input.type';

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { hasError = false, hasPrefix = false, isPrefilled = false, dense = false, customCSS, customWrapperCSS, ...props },
    ref,
  ) => (
    <div css={[inputWrapperCSS, customWrapperCSS]}>
      <input
        type="text"
        css={[inputCSS(hasError, props.disabled, hasPrefix, isPrefilled, dense), customCSS]}
        {...props}
        ref={ref}
      />
      <div css={focusHelperCSS} className="input-focus-helper" />
    </div>
  ),
);

Input.displayName = 'Input';

export default Input;
