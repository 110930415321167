import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';

export function containerCSS(narrow: boolean): SerializedStyles {
  return css`
    display: flex;
    gap: ${narrow ? 8 : 36}px;
    flex-direction: column;
    align-items: center;
`;
}

export const titleCSS = css`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
`;

export const descriptionCSS = css`
  text-align: center;
  font-weight: 500;
`;

export function imageCSS(maxHeight: number): SerializedStyles {
  return css`
    width: max-content;
    max-width: 100%;
    max-height: ${maxHeight}px;
  `;
}
