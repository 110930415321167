import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import Button from '../../../shared/components/Button/Button.tsx';
import noop from '../../../shared/utils/noop.ts';
import { containerCSS, descriptionCSS, imageCSS, titleCSS } from './SuggestedPolicyMessage.style.ts';
import type { SuggestedPolicyMessageProps } from './SuggestedPolicyMessage.type.ts';

const SuggestedPolicyMessage: FC<SuggestedPolicyMessageProps> = ({
  title,
  message,
  image,
  buttonLabel,
  onRender = noop,
  onActionClick,
}) => {
  const renderRef = useRef(false);
  useEffect(() => {
    if (!renderRef.current) {
      renderRef.current = true;
      onRender();
    }
  }, [onRender]);

  return (
    <div css={containerCSS(!!title)}>
      <img src={image.src} alt={image.alt} css={imageCSS(image.maxHeight)} />
      {title && <div css={titleCSS}>{title}</div>}
      <p css={descriptionCSS}>{message}</p>
      {buttonLabel && (
        <Button variant="secondary" fullWidth onClick={onActionClick}>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default SuggestedPolicyMessage;
