import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';

export const containerCSS = {
  '.MuiPaper-root': {
    backgroundColor: THEME.color.aqua,
    color: THEME.color.black,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700',
    boxShadow: THEME.boxShadow.container,
    padding: '12px 20px',
  },
  '.MuiSnackbarContent-action': {
    marginRight: '0',
  },
};

export const buttonCSS = css`
  background-color: transparent;
  border-color: ${THEME.color.black};
  color: ${THEME.color.black};
  &:active,
  &:focus,
  &:hover {
    background-color: ${THEME.color.white};
    border-color: ${THEME.color.black};
    color: ${THEME.color.black};
  }
`;
