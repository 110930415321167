export enum DpCta {
  Score = 'score',
  Quotes = 'quotes',
  CoverageLevel = 'coverage level',
}

export interface DigitalProfile {
  completed_at: Date | null;
  url: string;
};

export interface DigitalProfileResponse {
  profile: DigitalProfile;
}
