import type { SerializedStyles } from '@emotion/react';
import { css, keyframes } from '@emotion/react';
import THEME from '../../../styles/theme.ts';

function bounce(expanded: boolean) {
  return keyframes`
    from, 0%, 100%, 20%, 50%, 80%, to {
      transform: translateY(0);
    }
    40% {
      transform: translateY(${expanded ? '-' : ''}6px);
    }
    60% {
      transform: translateY(${expanded ? '-' : ''}3px);
    }
  `;
}

export function containerCSS(expanded: boolean): SerializedStyles {
  return css`
    margin-top: ${expanded ? 24 : 16}px;
    transition: margin-top 0.3s ease-in-out;
`;
}

export const coverageCSS = css`
  margin-bottom: 24px;
`;

export const actionButtonCSS = css`
  margin-bottom: 16px;
`;

export function policyDetailsButtonCSS(expanded: boolean): SerializedStyles {
  return css`
    display: flex;
    flex-direction: ${expanded ? 'column-reverse' : 'column'};
    align-items: center;
    gap: ${expanded ? 2 : 4}px;
    width: 100%;
    padding: 0;
    text-align: center;
    background-color: transparent;
    border: unset;
    font-size: 16px;
    cursor: pointer;
    > span {
      display: block;
      transition: color 0.3s ease-in-out;
      animation-duration: 1.5s;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
      > svg {
        width: 16px;
        height: 16px;
        ${expanded && css`
          transform: rotate(180deg);
        `}
      }
    }
    &:focus,
    &:hover {
      > span {
        color: ${THEME.color.azure50};
        animation-name: ${bounce(expanded)};
      }
    }
  `;
}
