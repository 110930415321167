import '../RateReviewVariant.scss';

import type { FC } from 'react';
import { useEffect } from 'react';

import useSettingsQuery from '../../../api/settings/use-settings-query.ts';
import Container from '../../../kit_deprecated/Container/Container';
import type { Policy } from '../../../services/rateReviews';
import getManualQuotes from '../../../utils/manualQuotes';
import Faq from '../../Faq/Faq';
import HorizontalPolicyCard from '../../HorizontalPolicyCard/HorizontalPolicyCard';
import ManualQuoteCard from '../../ManualQuoteCard/ManualQuoteCard';
import QuoteCard from '../../QuoteCard/QuoteCard';
import ScheduleCall from '../../ScheduleCall/ScheduleCall';
import VerticalPolicyCard from '../../VerticalPolicyCard/VerticalPolicyCard';
import type { RateReviewVariation } from '../Variation';
import { ContainerSize } from '../../../kit_deprecated/Container/Container.type';

function showPolicy(policy?: Policy) {
  return policy
    && (policy.premium
    || [policy.dwelling, policy.expiration_date, policy.deductible].filter(value => value != null).length >= 2);
}

const NoSavings: FC<RateReviewVariation> = ({ rateReviewData, quotes, callbacks }) => {
  const { data: settings } = useSettingsQuery();
  const noPriorPolicyPremium = !(rateReviewData.policy && rateReviewData.policy.premium);

  const header = noPriorPolicyPremium ? 'Your quotes are ready!' : 'We ran the numbers. You’re in great shape!';

  const subHeader = noPriorPolicyPremium
    ? (
        <div className="c-rate-review__header--details">
          Matic compared 30+ insurance carriers and here are the 3 best options we chose for you.
          {' '}
          If you’d like to customize any of these coverages, speak to an agent at
          {' '}
          <a className="o-link u-text--no-break" href={`tel:${settings?.phone_number}`}>
            {settings?.phone_number}
          </a>
        </div>
      )
    : (
        <div className="c-rate-review__header--details">
          Keep doing what you’re doing and we’ll reach out again before you’re up for another renewal.
          {' '}
          Feel free to call our agents with any questions about bundling your
          {' '}
          auto insurance in the meantime — you could still uncover potential savings.
        </div>
      );

  useEffect(() => {
    quotes.forEach((quote) => {
      if (quote.online_bind_url)
        callbacks.onOnlineBindOffered(quote);
    });
  }, [callbacks, quotes]);

  return (
    <Container className="c-rate-review" size={ContainerSize.Small}>
      <div className="c-rate-review__container">
        <div className="c-rate-review__header">
          <h4 className="u-mb-1">
            {rateReviewData.person?.first_name}
            ,
          </h4>
          <h1 className="u-mb-2">{header}</h1>
          <Container size={ContainerSize.Small}>
            <div className="c-rate-review__header--details">{subHeader}</div>
          </Container>
        </div>

        <ScheduleCall onScheduleCall={callbacks.onScheduleCall} />

        {showPolicy(rateReviewData.policy) && (
          <>
            <HorizontalPolicyCard
              policy={rateReviewData.policy}
              home={rateReviewData.home}
              className="u-hidden--mobile"
            />

            <VerticalPolicyCard
              policy={rateReviewData.policy}
              home={rateReviewData.home}
              person={rateReviewData.person}
              className="u-hidden--desktop"
            />
          </>
        )}

        <h2 className="c-rate-review__quotes-header u-hidden--mobile">Your Homeowner Insurance Options</h2>

        <h4 className="c-rate-review__quotes-header--mobile u-hidden--desktop">Your Insurance Options</h4>

        {quotes.map((quote, index) => (
          <QuoteCard
            key={quote.gid}
            quote={quote}
            onQuoteSelect={callbacks.onQuoteSelect}
            onQuoteReview={callbacks.onQuoteReview}
            recommended={!index && noPriorPolicyPremium}
            ordinal={index}
          />
        ))}

        {getManualQuotes(rateReviewData.home.residency_type).map((quote, index) => (
          <ManualQuoteCard
            key={quote.carrier.key}
            quote={quote}
            onQuoteReview={callbacks.onQuoteReview}
            onManualQuoteSelect={callbacks.onManualQuoteSelect}
            ordinal={quotes.length + index}
          />
        ))}

        <div className="c-rate-review__faq-wrapper">
          <Faq onFAQClicked={callbacks.onFAQClicked} />
        </div>
      </div>
    </Container>
  );
};

export default NoSavings;
