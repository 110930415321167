/* istanbul ignore file */
import * as React from 'react';

import classNames from 'classnames';
import ReactModal from 'react-modal';

import { SVGUrls } from '../../constants/urls';
import ScrollUtils from '../../utils/scroll';
import { ButtonWithIcon } from '../ElementWithIcon/ElementWithIcon';

import './Modal.scss';
import { ElementWithIconSize } from '../ElementWithIcon/ElementWithIcon.type';

const TRANSITION_DURATION = 200;

enum ModalSize {
  Small = 'SMALL',
  Medium = 'MEDIUM',
}

interface ModalProps {
  title?: string;
  subTitle?: string;
  className?: string;
  overlayClassName?: string;
  parentSelector?: () => HTMLElement;
  isModalOpen: boolean;
  onClose?: () => void;
  size?: ModalSize;
  buttonIconSize?: ElementWithIconSize;
  children?: React.ReactNode;
}

class Modal extends React.Component<ModalProps> {
  public static setAppElement = ReactModal.setAppElement;
  private scroll = new ScrollUtils();

  public componentDidUpdate(prevProps: ModalProps): void {
    if (this.props.isModalOpen !== prevProps.isModalOpen && !this.props.isModalOpen) {
      this.scroll.enableBodyScroll();
    }
  }

  public componentWillUnmount(): void {
    this.scroll.enableBodyScroll();
  }

  public static defaultProps = {
    size: ModalSize.Medium,
    buttonIconSize: ElementWithIconSize.Large,
  };

  private onClose = () => {
    if (this.props.onClose) {
      this.props.onClose?.();
    }
  };

  public render(): React.ReactNode {
    const onAfterOpen = () => this.scroll.disableBodyScroll();

    return (
      <ReactModal
        className={classNames(
          'o-modal__dialog',
          `o-modal__dialog--${this.props.size?.toLowerCase()}`,
          this.props.className,
        )}
        portalClassName="o-modal"
        overlayClassName={classNames('o-modal__overlay', this.props.overlayClassName)}
        isOpen={this.props.isModalOpen}
        closeTimeoutMS={TRANSITION_DURATION}
        onRequestClose={this.onClose}
        onAfterOpen={onAfterOpen}
        parentSelector={this.props.parentSelector}
      >
        {this.props.onClose && (
          <ButtonWithIcon
            iconName={SVGUrls.Close}
            size={this.props.buttonIconSize}
            onClick={this.onClose}
            className="o-modal__close-icon qa-modal-close"
            aria-label="Close"
          />
        )}

        <div className="o-modal__content">
          {(this.props.title || this.props.subTitle) && (
            <div className="o-modal__header">
              <h4 className="o-modal__title u-mb-2">{this.props.title}</h4>
              <div className="o-modal__subtitle">{this.props.subTitle}</div>
            </div>
          )}

          <div className="o-modal__body" onTouchMove={this.scroll.onIosTouchMoveFocusedInputScrollFix}>
            {this.props.children}
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default Modal;
