import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  position: relative;
  justify-content: space-between;
  gap: 24px
`;

export const leftColCSS = css`
  display: flex;
  gap: 40px;
  flex-direction: column;
  position: relative;
  padding: 12px;
  ${mq[1]} {
    padding: 0;
    max-width: 55%;
  }
  ${mq[2]} {
    width: 415px;
  }
`;

export const headerCSS = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const paragraphCSS = css`
  font-size: 20px;
  font-weight: 700;
`;

export const bodyCSS = css`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const rightColCSS = css`
  display: none;
  ${mq[1]} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -40px;
    margin-bottom: -40px;
    width: 50%;
  }
`;

export const imageCSS = css`
  object-fit: contain;
  max-width: 100%;
  max-height: 400px;
`;
