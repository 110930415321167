import type { FC } from 'react';

import TooltipQuestionButton from '../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import { containerCSS, subtitleCSS, titleCSS, titleIconCSS } from './SectionHeading.style';
import type { SectionHeadingProps } from './SectionHeading.type';

const SectionHeading: FC<SectionHeadingProps> = ({
  customCSS,
  title,
  subtitle,
  icon,
  size = 'regular',
  tooltip,
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <h2 css={titleCSS(size, !!icon)}>
        <div css={titleIconCSS}>{icon}</div>
        {title}
      </h2>
      {
        subtitle
        && (
          <p css={subtitleCSS(size)}>
            {subtitle}
            {tooltip && <TooltipQuestionButton size={16} content={tooltip} placement="bottom" />}
          </p>
        )
      }
    </div>
  );
};

export default SectionHeading;
