import { type SerializedStyles, css } from '@emotion/react';

import { activeElementResetCSS } from '../../shared/shared.style.ts';
import THEME from '../../styles/theme.ts';

export function containerCSS(isOpen: boolean): SerializedStyles {
  return css`
  ${activeElementResetCSS};
  width: 22px;
  height: 16px;
  margin-right: 12px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  &:focus,
  &:active {
    span {
      background: ${THEME.color.azure50};
    }
  }

  * {
    margin: 0;
    padding: 0;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: ${THEME.color.black};
    border-radius: 2px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 7px;
  }

  span:nth-child(4) {
    top: 14px;
  }

  ${isOpen
  && css`
    span:nth-child(1) {
      top: 7px;
      width: 0;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 7px;
      width: 0;
      left: 50%;
    }
  `}
`;
}
