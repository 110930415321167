import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';

import theme from '../../../styles/theme.ts';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 -${theme.size.paddingBodyHorizontalMobile}px;
  ${mq[1]} {
    flex-direction: row;
    margin: auto;
  }
`;

export const scoreCardCSS = css`
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  ${mq[1]} {
    width: calc(50% - 10px);
    flex-grow: 0;
  }
`;
