import type { FC } from 'react';
import React from 'react';

interface Props {
  children?: React.ReactNode;
}

const Page: FC<Props> = ({ children }) => {
  return <div className="version-1">{children}</div>;
};

export default Page;
