import { css } from '@emotion/react';

import lightBulbIcon from '../../assets/svg/light-bulb.svg';
import { mq } from '../../styles/media-queries.ts';

import THEME from '../../styles/theme.ts';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 40px ${THEME.size.paddingBodyHorizontalMobile}px 0;
  ${mq[1]} {
    flex-direction: row;
    gap: 40px;
    padding: 69px ${THEME.size.paddingBodyHorizontalMobile}px 0;
  }
`;

export const leftColCSS = css`
  ${mq[1]} {
    max-width: 360px;
  }
`;

export const rightColCSS = css`
  width: calc(100% + ${THEME.size.paddingBodyHorizontalMobile * 2}px);
  padding-top: 50px;
  margin-left: -${THEME.size.paddingBodyHorizontalMobile}px;
  margin-right: -${THEME.size.paddingBodyHorizontalMobile}px;
  ${mq[1]} {
    width: calc(100% + ${THEME.size.paddingBodyHorizontalMobile}px);
    padding-top: 35px;
    margin-left: 0;
    margin-right: -${THEME.size.paddingBodyHorizontalMobile}px;
  }
  ${mq[2]} {
    margin-right: 0;
  }
`;

export const illustrationCSS = css`
  width: 100%;
  content: '';
  height: 340px;
  background-image: url(${lightBulbIcon});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: auto 100%;
  ${mq[1]} {
    background-size: auto 100%;
    height: 100%;
    background-position: bottom left;
  }
`;

export const logoCSS = css`
  display: block;
  height: 24px;
  margin: 0 auto;
`;

export const headingCSS = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  margin-top: 6px;
`;

export const subheadingCSS = css`
  text-align: center;
  margin-top: 6px;
`;

export const formCSS = css`
  margin-top: 20px;
`;
