export const CALENDLY_TIME_SELECTED = 'calendly.date_and_time_selected';
export const CALENDLY_EVENT_SCHEDULED = 'calendly.event_scheduled';
export const CALENDLY_EVENT_TYPE_VIEWED = 'calendly.event_type_viewed';
export const CALENDLY_WIDGET_SCRIPT_URL = 'https://assets.calendly.com/assets/external/widget.js';

export function filterCalendlyEvents(eventHandler: (event: string) => void) {
  return (event: MessageEvent): void => {
    if (!(event.data.event || '').indexOf('calendly')) {
      eventHandler(event.data.event);
    }
  };
}
