import { css } from '@emotion/react';

export const rSymbolCSS = css`
  span {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    top: -16px;
    left: 4px;
  }
`;
