import { css } from '@emotion/react';
import THEME from '../../../styles/theme';

export function containerCSS(isActive: boolean) {
  return css`
    display: flex;
    align-items: center;
    gap: 8px;

    border-radius: 16px;
    border: 1px solid ${isActive ? THEME.color.positiveGreen27 : THEME.color.gray60};
    width: fit-content;

    color: ${isActive ? THEME.color.positiveGreen27 : THEME.color.gray60};
    background: ${isActive ? THEME.color.positiveGreen94 : THEME.color.gray10};

    padding: 8px 12px;
    right: 24px;
    top: -16px;
    position: absolute;
  `;
}

export const presenceCircleCSS = css`
  @keyframes pulse {
    0% {
      r: 4;
      opacity: 1;
    }
    50% {
      r: 6;
      opacity: 0.7;
    }
    100% {
      r: 4;
      opacity: 1;
    }
  }

  .inner-circle {
    animation: pulse 2s infinite ease-in-out;
  }

  @media (prefers-reduced-motion: reduce) {
    .inner-circle {
      r: 4;
      animation: none;
      opacity: 1;
    }
  }
`;
