import { useMutation } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import api from '../api';

function useSessionRefresh() {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useMutation({
    mutationFn: async () =>
      await api
        .post<HTTPResponse<{ user: { email: string } }>>(API_PATH.sessionRefresh, { body: {} })
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        }),
  });
}

export default useSessionRefresh;
