import type { FC } from 'react';

import ConfirmationComponent from '../../components/Confirmation/Confirmation';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Page from '../../components/Page/Page';
import { PAGE_KEYS } from '../../constants/analytics';
import useTitle from '../../hooks/useTitle';

import '../../assets/index.scss';

const PAGE_TITLE = 'Confirmation - Matic Insurance';

const RateReviewConfirmation: FC = () => {
  useTitle(PAGE_TITLE);

  return (
    <Page>
      <Header page={PAGE_KEYS.CONFIRMATION} />
      <ConfirmationComponent />
      <Footer />
    </Page>
  );
};

export default RateReviewConfirmation;
