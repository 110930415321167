import { css } from '@emotion/react';

import { mq } from './media-queries.ts';

export const pageHeadingCSS = css`
  font-size: 40px;
  line-height: 1.25;
  font-weight: 900;
  margin: 0;

  ${mq[1]} {
    font-size: 48px;
  }
`;

export const pageSubheadingCSS = css`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;

  ${mq[1]} {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 32px;
  }
`;

export const pageButtonCSS = css`
  max-width: 440px;
  width: 100%;
  margin: 0 auto;
  display: block;
`;

export const columnsWrapperCSS = css`
  display: flex;
  flex-direction: column;
  ${mq[1]} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 30px;
  }
`;

export const leftColumnCSS = css`
  width: 100%;

  ${mq[1]} {
    width: auto;
    flex-grow: 1;
  }
`;

export const rightColumnCSS = css`
  width: 100%;

  ${mq[1]} {
    width: 360px;
    flex-shrink: 0;
  }
`;

export const customSectionHeadingCSS = css`
  ${mq[1]} {
    margin-top: 0;
  }
`;

export const rightColumnButtonCustomCSS = css`
  margin-top: 28px;

  ${mq[1]} {
    margin-bottom: 0;
  }
`;

export const pageInfoPanelCSS = css`
  margin-top: 32px;
`;
