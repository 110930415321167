import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 656px;
  margin-top: 56px;
  position: relative;
`;

export const animatedDashedLoaderCSS = css`
  position: absolute;
  width: 100%;
  height: 2px;
  margin: auto 0;
  top: -26px;
  bottom: 0;
  z-index: -2;

  ${mq[1]} {
    top: -60px; //half of img height
  }
`;
