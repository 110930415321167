const QUERY_CACHE_KEYS = {
  settings: 'settings',
  account: 'account',
  session: 'session',
  disclosures: 'disclosures',
  score: 'score',
  proposal: 'proposal',
  proposalUserPresence: 'proposal_user_presence',
  personAssets: 'person_assets',
  digitalProfile: 'digital_profile',
  person: 'person',
  relatedPeople: 'related_people',
  quotes: 'quotes',
  suggestedQuote: 'suggested_quote',
  agentContactInfo: 'agent_contact_info',
  soldCustomersCount: 'sold_customers_count',
  personLoans: 'person_loans',
  availableCarriers: 'available_carriers',
  maticContent: 'matic_content',
  savings: 'savings',
  publicRecentPolicy: 'public_recent_policy',
  recentPolicy: 'recent_policy',
  premiumHistory: 'premium_history',
  replacementCostHistory: 'replacement_cost_history',
  quotesOverTimeHistory: 'quotes_over_time_history',
};

export default QUERY_CACHE_KEYS;
