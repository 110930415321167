import type { FC } from 'react';

import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import useAccountQuery from '../../api/account/use-account-query.ts';
import MaticLogoIcon from '../../assets/svg/matic-logo.svg?react';
import ROUTES from '../../constants/routes.ts';
import Container from '../../shared/components/Container/Container.tsx';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { getTargetPath } from '../../utils/routes.util.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import VerifyForm from './VerifyForm/VerifyForm.tsx';

import {
  containerCSS,
  formCSS,
  headingCSS,
  illustrationCSS,
  leftColCSS,
  logoCSS,
  rightColCSS,
  subheadingCSS,
} from './index.style.ts';

const VerifyLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const [searchParams] = useSearchParams();
  const targetPage = searchParams.get('target_page');
  const { data: accountData, isFetched: isAccountFetched } = useAccountQuery(gid);
  const isAccountVerified = accountData?.account.verified;
  useTrackPage(AnalyticPageKey.Verify);

  if (!isAccountFetched) {
    return <FullPageLoader />;
  }

  if (isAccountVerified) {
    const path = targetPage ? getTargetPath(gid, targetPage) : ROUTES.insights.replace(':gid', gid);
    return <Navigate to={path} replace />;
  }

  return (
    <Container customCSS={containerCSS}>
      <div css={leftColCSS}>
        <MaticLogoIcon css={logoCSS} />
        <div css={headingCSS}>Get more insurance insights with Matic</div>
        <div css={subheadingCSS}>
          Matic needs to confirm your basic information to log in. We will only need to do this once.
        </div>
        <VerifyForm customCSS={formCSS} />
      </div>
      <div css={rightColCSS}>
        <div css={illustrationCSS} />
      </div>
    </Container>
  );
};

export default VerifyLayout;
