import { TargetPage } from '../types/target-page.ts';

function isValidTargetPage(targetPage: string): boolean {
  return Object.values(TargetPage).includes(targetPage as TargetPage);
}

export function getTargetPath(gid: string, targetPage?: string | null): string {
  if (!targetPage || !isValidTargetPage(targetPage)) {
    return `/${gid}/${TargetPage.insights}`;
  }

  return `/${gid}/${targetPage.replace('_', '-')}`;
}
