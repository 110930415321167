import './QuoteInfo.scss';

import React from 'react';

import { useParams } from 'react-router-dom';

import { PHONE_CONVERSATION_INITIATED } from '../../constants/analytics';
import Sirv from '../../constants/sirv';
import Coverages from '../../kit_deprecated/Coverages/Coverages.tsx';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage.tsx';
import type { ManualQuote, Quote } from '../../services/rateReviews';
import analytics from '../../utils/analytics.util.ts';
import { phoneFormatter } from '../../utils/formatters';

interface Props {
  quote: Quote | ManualQuote;
  phoneNumber?: string;
}

function trackPhoneConversationInitiated(gid: string): void {
  const props = {
    page: 'quotes',
    location: 'quotes banner',
  };

  analytics.trackV1(PHONE_CONVERSATION_INITIATED, gid, props);
}

const QuoteInfo: React.FunctionComponent<Props> = ({ quote, phoneNumber }) => {
  const gid = (useParams as any)().gid;
  const onPhoneClick = () => trackPhoneConversationInitiated(gid);

  return (
    <div className="c-quote-info">
      <div className="c-quote-info__row">
        <div className="c-quote-info__carrier">
          <img
            src={quote.carrier.logo_url}
            alt={quote.carrier.marketing_name}
            className="c-quote-info__carrier-logo u-mb-3"
          />

          <div className="c-quote-info__carrier-desc">{quote.carrier.marketing_description}</div>
        </div>

        {quote.premium && (
          <div className="c-quote-info__premium">
            <h2 className="c-quote-info__premium-title">
              <span className="c-quote-info__premium--desktop">Annual</span>
              {' '}
              Premium
            </h2>
            <h2 className="c-quote-info__premium-value o-paragraph--bold">{quote.premium}</h2>
          </div>
        )}
      </div>

      <div className="c-quote-info__row">
        {quote.deductible && (
          <div className="c-quote-info__deductible">
            <h2 className="c-quote-info__deductible-title o-paragraph--bold">Deductible</h2>
            <h2 className="c-quote-info__deductible-value o-paragraph--bold">{quote.deductible}</h2>
          </div>
        )}

        <div className="c-quote-info__call c-quote-info__call--desktop">
          <SirvImage url={Sirv.ICON.AGENT} width={38} className="c-quote-info__agent-icon" />

          <div>
            Matic will be happy to make any adjustments you need! Just call Matic at
            {' '}
            <a className="o-link--secondary" href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
              {phoneFormatter(phoneNumber)}
            </a>
          </div>
        </div>
      </div>

      {quote.coverages && (
        <div className="c-quote-info__row">
          <div className="c-quote-info__coverages">
            <h2 className="u-mb-3">Coverages</h2>

            <Coverages coverages={quote.coverages} />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuoteInfo;
