export enum CardPadding {
  Standard = 'standard',
  Small = 'small',
}

export interface CardProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export interface CardHeadingProps extends CardProps {
  heading: string;
}

export interface CardContentProps extends CardProps {
  padding?: CardPadding;
}
