import { css } from '@emotion/react';

export const sectionCSS = css`
  margin-top: 40px;
`;

export const sectionHeadingCSS = css`
  margin-top: 0;
  margin-bottom: 20px;
`;
