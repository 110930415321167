import { useEffect, useRef } from 'react';
import type { AnalyticPageKey } from '../types/analytics.type.ts';
import useSegment from './use-segment.ts';

function useTrackPage(pageKey: AnalyticPageKey, sessionGid?: string | null) {
  const { page } = useSegment();
  const pageViewedTracked = useRef(false);

  useEffect(() => {
    if (pageKey && !pageViewedTracked.current) {
      const properties = sessionGid ? { session_gid: sessionGid } : {};
      page(pageKey, properties);
      pageViewedTracked.current = true;
    }
  }, [pageKey, page, sessionGid]);
}

export default useTrackPage;
