import * as React from 'react';

import classNames from 'classnames';

import Loader from '../Loader/Loader';

import './Button.scss';
import { ButtonSize } from './Button.type';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  secondary?: boolean;
  isLoading?: boolean;
}

const Button: React.FunctionComponent<ButtonProps> = ({
  size = ButtonSize.Standard,
  children,
  secondary = false,
  isLoading = false,
  className,
  disabled,
  ...props
}) => (
  <button
    type="button"
    className={classNames(className, 'o-button', `o-button--${size.toLowerCase()}`, {
      'o-button--secondary': secondary,
      'o-button--loading': isLoading,
    })}
    disabled={isLoading || disabled}
    {...props}
  >
    {isLoading ? <Loader inverted={secondary} /> : children}
  </button>
);

export default Button;
