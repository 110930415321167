export const FAQItems = [
  {
    key: '1',
    title: 'Where is this offer coming from?',
    description: (
      <span>
        Matic has reviewed your information and is presenting you with this offer with the help of 30+ top rated
        insurance carriers.
      </span>
    ),
  },
  {
    key: '2',
    title: 'How does Matic get compensated?',
    description: (
      <span>
        We get paid a commission directly from the insurance carriers when you switch policies. You’ll only switch if
        you’re getting value so we do our best.
      </span>
    ),
  },
  {
    key: '3',
    title: 'Who is Matic?',
    description: (
      <span>
        Matic is a digital insurance agency with a marketplace of more than 30 A-rated carriers. We work with homeowners
        like yourself to provide you exclusive access to RateReview™, our free insurance monitoring service. You
        previously signed up for RateReview™ service to help you lower your monthly expenses.
      </span>
    ),
  },
  {
    key: '4',
    title: 'How do I switch carriers? Is it hard?',
    description: (
      <span>
        It’s easy! Once your Matic agent finalizes your policy, we have a customer service team who takes care of
        everything else. All you have to do is electronically sign your insurance application and you’re done.
      </span>
    ),
  },
  {
    key: '5',
    title: 'What does Matic charge?',
    description: <span>Matic is 100% free.</span>,
  },
  {
    key: '6',
    title: 'Can I bundle a car?',
    description: <span>Yes, you can bundle your auto and home insurance and save up to 15%.</span>,
  },
];
