import './Benefits.scss';

import type { FC } from 'react';

import { SVGUrls } from '../../constants/urls';
import Icon from '../../kit_deprecated/Icon/Icon';

interface Props {
  oldPremium?: string | null;
  newPremium?: string | null;
}

const Benefits: FC<Props> = ({ newPremium, oldPremium }) => (
  <>
    <h4 className="u-mb-2">Benefits</h4>
    <ul className="c-benefits">
      <li className="c-benefits__item u-mb-4">
        <Icon iconName={SVGUrls.Save} className="c-benefits__icon" />
        <span className="c-benefits__description">
          Your homeowner policy could be {newPremium}/yr instead of your current policy’s {oldPremium}/yr
        </span>
      </li>
      <li className="c-benefits__item u-mb-4">
        <Icon iconName={SVGUrls.Save} className="c-benefits__icon" />
        <span className="c-benefits__description">It costs nothing to switch</span>
      </li>
      <li className="c-benefits__item u-mb-4">
        <Icon iconName={SVGUrls.Save} className="c-benefits__icon" />
        <span className="c-benefits__description">It’s a fast and painless process</span>
      </li>
    </ul>
  </>
);

export default Benefits;
