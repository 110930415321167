import type { FC } from 'react';

import { containerCSS } from './ProposalCard.style';
import type { ProposalCardProps } from './ProposalCard.type';

const ProposalCard: FC<ProposalCardProps> = ({
  customCSS,
  children,
  backgroundColor = 'transparent',
  isTextCard = false,
}) => {
  return <div css={[containerCSS(backgroundColor, isTextCard), customCSS]}>{children}</div>;
};

export default ProposalCard;
