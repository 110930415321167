import * as React from 'react';

import './Logo.scss';

import { URLS } from '../../constants/urls';
import SirvImage from '../SirvImage/SirvImage';

interface LogoProps {
  partnerLogo?: string;
  partnerName?: string;
  height?: number;
  width?: number;
}

const Logo: React.FunctionComponent<LogoProps> = ({ partnerLogo, partnerName, width = 372, height = 55 }) => (
  <div className="c-logo">
    {partnerLogo
      ? (
          <SirvImage className="c-logo__partner" url={partnerLogo} width={width} height={height} alt={partnerName} />
        )
      : (
          <SirvImage url={URLS.MATIC_LOGO} className="c-logo__matic" width={234} height={height} alt="Matic logo" />
        )}
  </div>
);

export default Logo;
