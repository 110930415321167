import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';

export const labelCSS = css`
  display: block;
  color: ${THEME.color.black};
  vertical-align: middle;
`;

export const tooltipButtonCSS = css`
  bottom: 3px;
`;
