import { css } from '@emotion/react';

export const factorsContainerCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  margin-top: 12px;
`;

export const titleCSS = css`
  font-weight: 700;
`;

export const factorCSS = css`
  width: 100%;
`;

export const actionButtonCSS = css`
  display: block;
  font-weight: 700;
  margin-top: 20px;
`;
