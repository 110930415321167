import { type FC, useRef } from 'react';

import { Controller } from 'react-hook-form';

import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story.ts';
import Input from '../../../shared/components/Input/Input.tsx';
import noop from '../../../shared/utils/noop.ts';
import { isValueSpecificType } from '../../../utils/answers.util.ts';
import isSensitiveQuestion from '../../../utils/is-sensitive-question.util.ts';
import {
  getHiddenQuestionValidationRules,
  getValidationRules,
  hardcodedValidations,
} from '../../../utils/question-validations.util.ts';
import type { QuestionInputProps } from './QuestionIput.type';

const QuestionInput: FC<QuestionInputProps> = ({
  name,
  placeholder,
  hasError = false,
  isDisabled,
  inputId,
  type = 'text',
  validations,
  trackingForbidden = false,
  dense,
  onValidatePreviousInputs = noop,
}) => {
  const isAsteriskCleared = useRef(false);
  const notClearedSensitiveQuestion = isSensitiveQuestion(name) && !isAsteriskCleared.current;

  const getValue = (value: string): string => {
    if (notClearedSensitiveQuestion) {
      isAsteriskCleared.current = true;
      return value.includes('*') ? '' : value;
    }

    return value;
  };

  const hasValidValue = (value: unknown): boolean => {
    return typeof value === 'string' && value.trim().length > 0;
  };

  return (
    <Controller
      rules={
        notClearedSensitiveQuestion
          ? getHiddenQuestionValidationRules(validations)
          : getValidationRules(validations, { isNotEmptyString: hardcodedValidations.isNotEmptyString })
      }
      name={name}
      render={({ field }) => {
        const inputValue = isValueSpecificType<string | string[] | number>(field.value, ['string', 'array', 'number'])
          ? field.value
          : '';

        return (
          <Input
            {...field}
            placeholder={placeholder}
            value={inputValue}
            className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
            hasError={hasError}
            isPrefilled={false}
            id={inputId}
            autoComplete="off"
            name={name}
            type={type}
            disabled={isDisabled}
            dense={dense}
            ref={r => field.ref(r)}
            onBlur={() => {
              field.onBlur();
              if (hasValidValue(field.value)) {
                onValidatePreviousInputs();
              }
            }}
            onChange={(e) => {
              field.onChange({ target: { value: getValue(e.target.value) } });
            }}
          />
        );
      }}
    />
  );
};

export default QuestionInput;
