import { type FC, Fragment } from 'react';

import usePersonLoansQuery from '../../../api/proposal/use-person-loans-query';
import useSavingsQueries from '../../../api/proposal/use-savings-query';
import CarIcon from '../../../assets/svg/car.svg?react';
import HomeIcon from '../../../assets/svg/home.svg?react';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { moneyFormatter } from '../../../utils/formatters';
import {
  boldGreenTextCSS,
  boldTextCSS,
  containerCSS,
  dividerLineCSS,
  headerCSS,
  insuranceTitleCSS,
  italicGrayTextCSS,
  lineCSS,
  paymentLineCSS,
  premiumBlockCSS,
} from './ProposalSummary.style';
import type { ProposalSummaryProps } from './ProposalSummary.type';
import { getLoanPaymentOption } from './ProposalSummary.util';

const ProposalSummary: FC<ProposalSummaryProps> = ({ homeQuotes, autoQuotes, allQuotes, personGid }) => {
  const totalPremium = [...(homeQuotes || []), ...(autoQuotes || [])].reduce((acc, quote) => acc + quote.premium, 0);
  const { data: loans } = usePersonLoansQuery({ personGid });
  const todayPayment = autoQuotes?.reduce(
    (acc, quote) => acc + Number(quote.payment_options?.[0]?.downpayment || 0),
    0,
  );

  const { totalSavings } = useSavingsQueries({ personGid, quotesGids: allQuotes?.map(quote => quote.gid) || [] });

  return (
    <ProposalCard isTextCard>
      <div css={containerCSS}>
        <p css={headerCSS}>Offer Summary</p>
        {homeQuotes?.map(quote => (
          <div css={lineCSS} key={quote.gid}>
            <div css={insuranceTitleCSS}>
              <HomeIcon />
              <p>Home insurance(yearly)</p>
            </div>
            <div css={premiumBlockCSS}>
              <p>{moneyFormatter(quote.premium, false, 2)}</p>
              <p css={italicGrayTextCSS}>{getLoanPaymentOption({ loans, quote })}</p>
            </div>
          </div>
        ))}
        {autoQuotes?.map(quote => (
          <Fragment key={quote.gid}>
            <div css={lineCSS}>
              <div css={insuranceTitleCSS}>
                <CarIcon />
                <p>Auto insurance({quote.payment_options?.[0].term_months === 12 ? 'Yearly' : '6 months'})</p>
              </div>
              <div css={premiumBlockCSS}>
                <p>{moneyFormatter(quote.premium, false, 2)}</p>
                <p css={italicGrayTextCSS}>{getLoanPaymentOption({ loans, quote })}</p>
              </div>
            </div>
            {quote.payment_options?.[0]?.downpayment
              ? (
                  <div css={paymentLineCSS}>
                    <p>Initial payment required by carrier</p>
                    <p>{moneyFormatter(quote.payment_options[0].downpayment, false, 2)}</p>
                  </div>
                )
              : null}

            {quote.payment_options?.[0]?.subsequent_payment
              ? (
                  <div css={paymentLineCSS}>
                    <p>Subsequent monthly payments</p>
                    <p>{moneyFormatter(quote.payment_options[0].subsequent_payment, false, 2)}</p>
                  </div>
                )
              : null}
          </Fragment>
        ))}

        <div css={dividerLineCSS} />
        <div css={lineCSS}>
          <div>
            <p css={boldTextCSS}>Total</p>
            <p>
              This total cost includes all your selected insurance products. Note that the period of policy might be
              differ.
            </p>
          </div>

          <p css={boldTextCSS}>{moneyFormatter(totalPremium, false, 2)}</p>
        </div>

        <div>
          <p css={boldTextCSS}>No hidden fees</p>
          <p>
            Matic is a free insurance marketplace. There are no extra fees to work with us; only pay the price of the
            premium.
          </p>
        </div>
        <div css={dividerLineCSS} />
        {totalSavings > 0
          ? (
              <div css={lineCSS}>
                <p css={boldGreenTextCSS}>Your total savings</p>
                <p css={boldGreenTextCSS}>{moneyFormatter(totalSavings, false, 2)}</p>
              </div>
            )
          : null}
        {todayPayment
          ? (
              <div css={lineCSS}>
                <p css={boldTextCSS}>Today payment</p>
                <p css={boldTextCSS}>{moneyFormatter(todayPayment, false, 2)}</p>
              </div>
            )
          : null}
      </div>
    </ProposalCard>
  );
};

export default ProposalSummary;
