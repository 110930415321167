import type { FC } from 'react';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Page from '../../components/Page/Page';
import RateReviewComponent from '../../components/RateReview/RateReview';
import useTitle from '../../hooks/useTitle';

import '../../assets/index.scss';

const PAGE_TITLE = 'Your RateReview - Matic Insurance';

const RateReview: FC = () => {
  useTitle(PAGE_TITLE);

  return (
    <Page>
      <Header page="quotes" />
      <RateReviewComponent />
      <Footer />
    </Page>
  );
};

export default RateReview;
