import { useLayoutEffect, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export default function useSize<T extends HTMLElement>(target: React.RefObject<T | null>) {
  const [size, setSize] = useState<DOMRect | undefined>(undefined);

  useLayoutEffect(() => {
    if (target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, entry => setSize(entry.contentRect));

  return size;
}
