import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';

export const carrierLogoCSS = css`
  max-width: 170px;
  max-height: 70px;
  margin-bottom: 12px;
  img {
    max-height: 70px;
  }
`;

export const premiumContainerCSS = css`
  margin-bottom: 24px;
`;

export const newPremiumCSS = css`
  font-size: 20px;
  line-height: 28px;
  > span {
    font-weight: 700;
    color: ${THEME.color.positiveGreen27};
  }
`;

export const renewedPremiumCSS = css`
  font-size: 14px;
  line-height: 20px;
`;

export const savingsContainerCSS = css`
  display: flex;
  justify-content: space-between; 
  gap: 16px;
  font-size: 16px;
  padding: 16px 0;
  border-top: 1px solid ${THEME.color.gray10};
`;

export const savingsValueCSS = css`
  font-weight: 700;
  color: ${THEME.color.positiveGreen27};
`;

export const valuesContainerCSS = css`
  display: flex;
  align-items: stretch;
  border-top: 1px solid ${THEME.color.gray10};
  border-bottom: 1px solid ${THEME.color.gray10};
  margin-bottom: 24px;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const valueColCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 24px 12px 8px;
  width: 33.33%;
  font-size: 14px;
  position: relative;
  min-width: 95px;
  box-sizing: border-box;
  &:not(:last-child) {
    border-right: 1px solid ${THEME.color.gray10};
  }
`;

export const valueTooltipCSS = css`
  position: absolute;
  right: 8px;
  top: 16px;
`;

export const valueNumberCSS = css`
  font-weight: 700;
`;

export function tagCSS(color: string): SerializedStyles {
  return css`
    display: inline-block;
    margin-top: 8px;
    padding: 4px 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    background-color: ${color};
    color: ${THEME.color.white};
    border-radius: 40px;
  `;
}

export const autoExplainerCSS = css`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: ${THEME.color.azure50};
  &:focus,
  &:hover {
    color: ${THEME.color.azure68};
  }
`;
