import {
  CONVERSATION_SCHEDULING_INITIATED,
  FAQ_CLICKED,
  FLOW_STARTED,
  ONLINE_BIND_OFFERED,
  PAGES,
  QUOTE_REVIEWED,
  QUOTE_SELECTED,
  SHOW_MORE_QUOTES_CLICKED,
} from '../../constants/analytics';
import type { ManualQuote, Quote, RateReview as RateReviewEntity } from '../../services/rateReviews';
import analytics from '../../utils/analytics.util.ts';
import { isMobile } from '../../utils/layout';
import quoteType from '../../utils/quoteType';

function quoteProps(quote: Quote | ManualQuote) {
  return {
    premium: quote.premium,
    carrier_key: quote.carrier.key,
    quote_gid: quote.gid,
    quote_type: quoteType(quote),
  };
}

const layout = () => (isMobile() ? 'mobile' : 'desktop');

export function trackPageView(gid: string): void {
  const props = { session_gid: gid };
  analytics.page(PAGES.QUOTES, props);
}

export function trackOnlineBindOffered(gid: string, quote: Quote, data?: RateReviewEntity): void {
  const props = {
    ...quoteProps(quote),
    page_variation: data?.variation,
  };
  analytics.trackV1(ONLINE_BIND_OFFERED, gid, props);
}

export function trackQuoteReviewed(
  gid: string,
  quote: Quote | ManualQuote,
  ordinal: number,
  data?: RateReviewEntity,
): void {
  const props = {
    ...quoteProps(quote),
    ordinal,
    layout: layout(),
    page_variation: data?.variation,
  };

  analytics.trackV1(QUOTE_REVIEWED, gid, props);
}

export function trackQuoteSelected(
  gid: string,
  quote: Quote | ManualQuote,
  ordinal: number,
  data?: RateReviewEntity,
): void {
  const props = {
    ...quoteProps(quote),
    ordinal,
    layout: layout(),
    page_variation: data?.variation,
  };

  analytics.trackV1(QUOTE_SELECTED, gid, props);
}

export function trackShowMoreQuotesClicked(gid: string, data?: RateReviewEntity): void {
  const props = {
    page_variation: data?.variation,
    layout: layout(),
  };

  analytics.trackV1(SHOW_MORE_QUOTES_CLICKED, gid, props);
}

export function trackFlowStarted(gid: string, data: RateReviewEntity): void {
  const props = {
    page_variation: data.variation,
    shown_savings: data.savings,
    best_quote_gid: data.quotes[0]?.gid,
  };
  analytics.trackV1(FLOW_STARTED, gid, props);
}

export function identifyPerson(data: RateReviewEntity): void {
  const traits = {
    email: analytics.generateTrackEmail(data.person.gid),
  };
  analytics.identify(data.person.gid, traits);
}

export function trackFullStory(data: RateReviewEntity): void {
  const traits = {
    email: analytics.generateTrackEmail(data.person.gid),
  };

  analytics.setFullStoryUserVars(traits);
}

export function trackConversationSchedulingInitiated(gid: string): void {
  const props = {
    page: 'quotes',
    layout: layout(),
  };
  analytics.trackV1(CONVERSATION_SCHEDULING_INITIATED, gid, props);
}

export function trackFAQClicked(gid: string, question: string, data?: RateReviewEntity): void {
  const props = {
    page_variation: data?.variation,
    layout: layout(),
    question_text: question,
  };
  analytics.trackV1(FAQ_CLICKED, gid, props);
}
