import type { FC } from 'react';

import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import SIRV_IMAGES from '../../../constants/sirv-images';
import THEME from '../../../styles/theme';
import {
  bodyCSS,
  containerCSS,
  headerCSS,
  imageCSS,
  leftColCSS,
  paragraphCSS,
  rightColCSS,
} from './ProposalFooter.style';
import type { ProposalFooterProps } from './ProposalFooter.type';

const ProposalFooter: FC<ProposalFooterProps> = () => {
  return (
    <ProposalCard customCSS={containerCSS} backgroundColor={THEME.color.violet95}>
      <div css={leftColCSS}>
        <p css={headerCSS}>Why customers choose Matic</p>
        <div css={bodyCSS}>
          <div>
            <p css={paragraphCSS}>Ongoing support</p>
            <p>
              In addition to your carrier, Matic is an extra insurance advocate there to help you navigate issues with
              your policy.
            </p>
          </div>
          <div>
            <p css={paragraphCSS}>Transparency</p>
            <p>
              Our advisors are not paid based on your premium, so our motive is to find the best price and policy
              options for you.
            </p>
          </div>
          <div>
            <p css={paragraphCSS}>Peace of mind</p>
            <p>
              If your premium increases in the future, you can rely on Matic’s network of 40+ A-rated carriers to help
              find a better option.
            </p>
          </div>
        </div>
      </div>
      <div css={rightColCSS}>
        <img src={SIRV_IMAGES.aboutMatic} alt="Friendly advisor" css={imageCSS} />
      </div>
    </ProposalCard>
  );
};

export default ProposalFooter;
