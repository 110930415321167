import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';

import THEME from '../../../styles/theme.ts';

export const containerCSS
  = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    ${mq[1]} {
      max-width: ${THEME.size.containerMaxWidth}px;
      box-sizing: border-box;
      padding: 40px 15px;
      gap: 40px;
    }
  `;

export const agentContactCSS = css`
  display: flex;
  gap: 24px;
  flex-direction: column;
  ${mq[1]} {
    flex-direction: row;
  }
`;

export const agentContactItemCSS = css`
  font-weight: 700;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
`;

export const agentInfoCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;
  ${mq[1]} {
    text-align: center;
    align-items: center;
  }
`;

export const imageCSS = css`
  max-width: 464px;
  max-height: 212px;
`;
