import { useEffect } from 'react';
import useCreateFullStorySession from '../api/full-story-sessions/use-create-full-story-session';
import { reportErrorMessage } from './error.util.ts';

function useTrackFullStorySession({
  personGid,
  engagementGid,
  proposalGid,
}: {
  personGid?: string;
  engagementGid?: string;
  proposalGid?: string;
}) {
  const { mutateAsync: createFullStorySession } = useCreateFullStorySession();
  const fullStory = (window as any).FS;

  useEffect(() => {
    const captureSession = (): Promise<string | null> => {
      try {
        if (fullStory) {
          return fullStory.getCurrentSessionURL();
        }
      }
      catch {
        reportErrorMessage('Failed to get Full Story Session URL');
      }
      return Promise.resolve(null);
    };

    const trackSession = async () => {
      if (fullStory && personGid && engagementGid && proposalGid) {
        const sessionUrl = await captureSession();
        if (sessionUrl) {
          await createFullStorySession({ personGid, engagementGid, sessionUrl, proposalGid });
        }
      }
    };

    trackSession();
  }, [fullStory, personGid, engagementGid, proposalGid, createFullStorySession]);
}

export default useTrackFullStorySession;
