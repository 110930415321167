import * as React from 'react';

import classNames from 'classnames';

import './ElementWithIcon.scss';

import type { SVGUrls } from '../../constants/urls';
import type { IconProps } from '../Icon/Icon';
import Icon from '../Icon/Icon';
import { ElementWithIconSize } from './ElementWithIcon.type';

interface ElementWithIconProps {
  size?: ElementWithIconSize;
  social?: boolean;
  iconName: SVGUrls | string;
  iconText?: string;
}

export const AnchorWithIcon: React.FunctionComponent<
  ElementWithIconProps & React.AnchorHTMLAttributes<HTMLAnchorElement> & IconProps
> = ({ size = ElementWithIconSize.Standard, className, iconName, iconText, social, ...props }) => (
  <a
    className={classNames(className, 'o-element-with-icon', `o-element-with-icon--${size.toLowerCase()}`, {
      'o-element-with-icon--social': social,
    })}
    {...props}
  >
    <Icon iconName={iconName} className="o-element-with-icon__icon" aria-hidden="true" />
    {iconText && <span className="u-visually-hidden">{iconText}</span>}
  </a>
);

export const ButtonWithIcon: React.FunctionComponent<
  ElementWithIconProps & React.ButtonHTMLAttributes<HTMLButtonElement> & IconProps
> = ({ size = ElementWithIconSize.Standard, className, type = 'button', iconName, social, ...props }) => (
  <button
    type={type}
    className={classNames(className, 'o-element-with-icon', `o-element-with-icon--${size.toLowerCase()}`, {
      'o-element-with-icon--social': social,
    })}
    {...props}
  >
    <Icon iconName={iconName} className="o-element-with-icon__icon" />
  </button>
);
