import { css } from '@emotion/react';
import THEME from './theme';

export const contentPageContainerCSS = css`
  max-width: ${THEME.size.containerSmMaxWidth}px;

  h2 {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 14px 0;
  }

  h3, h4 {
    font-size: 14px;
    line-height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  section:not(:last-of-type){
    margin-bottom: 40px;
  }

  section > p:not(:last-of-type) {
    margin-bottom: 14px
  }

  ul {
    padding-left: 25px;
    font-size: 14px;
  }

  li span {
    font-weight: 700;
  }

  a: {
    color: ${THEME.color.azure62};
  }
`;
