import { FactorLevel } from '../../types/score.type.ts';

export function getFactorLevelName(factorLevel: FactorLevel): string {
  switch (factorLevel) {
    case FactorLevel.Low:
      return 'Low';
    case FactorLevel.Moderate:
      return 'Medium';
    case FactorLevel.High:
      return 'High';
    case FactorLevel.Unknown:
    default:
      return 'Unknown';
  }
}
