import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import useSegment from '../../hooks/use-segment.ts';
import type { AnalyticEvents } from '../../types/analytics.type.ts';
import type { TrackOnInitProps } from './TrackOnInit.type';

const TrackOnInit: FC<TrackOnInitProps<AnalyticEvents>> = ({ eventName, properties }) => {
  const { track } = useSegment();
  const renderRef = useRef(false);

  useEffect(() => {
    if (!renderRef.current) {
      renderRef.current = true;
      track(eventName, properties);
    }
  }, [track, eventName, properties]);

  return null;
};

export default TrackOnInit;
