import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';

export const proposalsSelectCSS = css`
  max-width: 460px;
  margin: 0 12px;
  ${mq[1]} {
    margin: 0;
  }
`;
