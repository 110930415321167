const SIRV_IMAGES = {
  twitter: 'https://maticinsurance-cdn.sirv.com/icons/svg/twitter.svg',
  linkedin: 'https://maticinsurance-cdn.sirv.com/icons/svg/linkedin.svg',
  facebook: 'https://maticinsurance-cdn.sirv.com/icons/svg/facebook.svg',
  instagram: 'https://maticinsurance-cdn.sirv.com/icons/svg/instagram.svg',
  phone: 'https://maticinsurance-cdn.sirv.com/icons/svg/phone.svg',
  documentAnimLoader: 'https://maticinsurance.sirv.com/customer-portal/document-anim-loader.gif',
  chartPreview: 'https://maticinsurance.sirv.com/customer-portal/chart-preview.png',
  agent: 'https://maticinsurance.sirv.com/customer-portal/proposal/agent.png',
  insurance: 'https://maticinsurance.sirv.com/customer-portal/proposal/insurance.png',
  aboutMatic: 'https://maticinsurance.sirv.com/customer-portal/proposal/about-matic.png',
  car: 'https://maticinsurance.sirv.com/customer-portal/proposal/car.png',
  maticHeart: 'https://maticinsurance.sirv.com/customer-portal/matic-heart.svg',
  teamImg: 'https://maticinsurance.sirv.com/agents/team.png',
  manOnSpeedometer: 'https://maticinsurance.sirv.com/customer-portal/man-on-speedometer.png',
  radialChart: 'https://maticinsurance.sirv.com/customer-portal/radial-chart.png',
  dullСat: 'https://maticinsurance.sirv.com/illustrations/dull_cat.svg',
};

export default SIRV_IMAGES;
