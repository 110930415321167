import type { Account } from '../../../types/account.type.ts';
import type { ProfileFormState } from './ProfileForm.type.ts';

export function profileDataHasChanges(
  profileData: ProfileFormState,
  initialProfileData: Account | Record<string, unknown> = {},
): boolean {
  // @ts-expect-error types are not matching
  return Object.keys(profileData).some(key => profileData[key] !== initialProfileData[key]);
}
