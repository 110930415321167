import type { FC } from 'react';

import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import type { SpecificQuestionProps } from '../Question.type';
import QuestionInput from '../QuestionInput/QuestionInput';
import { EMAIL_PATTERN_VALIDATION } from './EmailQuestions.util';

const EmailQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  icon,
  isDisabled,
  placeholder = 'xxxxxx@xxxxx.com',
  validations,
  gridWidth,
  dense,
  trackingForbidden,
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    icon={icon}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      type: 'email',
      name: questionKey,
      isDisabled,
      inputId: questionKey,
      placeholder,
      validations: [EMAIL_PATTERN_VALIDATION, ...(validations || [])],
      trackingForbidden,
      dense,
    }}
    inputComponent={QuestionInput}
  />
);

export default EmailQuestion;
