import { reportErrorMessage } from './error.util.ts';

const getFirstProperty = (obj: Record<string, any>): unknown => Object.keys(obj)[0];

export function scrollToError(errors: Record<string, any>): void {
  const firstErrorKey = getFirstProperty(errors) as string;
  let inputKey;
  if (Array.isArray(errors[firstErrorKey])) {
    const index: number = errors[firstErrorKey].findIndex((el: Record<string, string>) => !!el);
    inputKey = `${firstErrorKey}[${index}].${getFirstProperty(errors[firstErrorKey][index]) as string}`;
  }
  else {
    inputKey = firstErrorKey;
  }
  let inputToScroll = document.getElementById(inputKey);
  if (!inputToScroll) {
    /** If it is radio button, each input has id as "key" + "index" */
    inputToScroll = document.getElementById(`${inputKey}[0]`);
  }

  if (inputToScroll) {
    inputToScroll.focus({ preventScroll: true });
    inputToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  else {
    reportErrorMessage(`Failed to scroll to error input ${inputKey}`);
  }
}
