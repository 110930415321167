/* istanbul ignore file */
import * as React from 'react';

import * as ReactDOM from 'react-dom';

import './FullScreenModal.scss';

import classNames from 'classnames';

import { SVGUrls } from '../../constants/urls';
import ScrollUtils from '../../utils/scroll';
import { ButtonWithIcon } from '../ElementWithIcon/ElementWithIcon';
import Header from '../Header/Header';

interface FullScreenModalProps {
  visible?: boolean;
  logo?: string;
  title?: string;
  className?: string;
  onClose: () => void;
  parentSelector?: () => HTMLElement;
  children?: React.ReactNode;
}

class FullScreenModal extends React.Component<FullScreenModalProps> {
  private fullScreenModal: any;
  private scroll = new ScrollUtils();

  public static defaultProps = {
    visible: false,
    parentSelector: (): HTMLElement => document.body,
  };

  public componentDidUpdate(prevProps: FullScreenModalProps): void {
    if (this.props.visible !== prevProps.visible) {
      if (this.props.visible) {
        if (!this.isHiddenOnScreen()) {
          this.scroll.disableBodyScroll();
        }

        window.addEventListener('resize', this.handleWindowResize);
      }
      else {
        this.scroll.enableBodyScroll();
        window.removeEventListener('resize', this.handleWindowResize);
      }
    }
  }

  public componentWillUnmount(): void {
    this.scroll.enableBodyScroll();
    window.removeEventListener('resize', this.handleWindowResize);
  }

  private isHiddenOnScreen = () => !this.fullScreenModal.clientHeight && !this.fullScreenModal.clientWidth;

  private handleWindowResize = () => {
    if (this.isHiddenOnScreen()) {
      this.scroll.enableBodyScroll();
    }
    else {
      this.scroll.disableBodyScroll();
    }
  };

  private setRef = (ref: any) => {
    if (ref) {
      this.fullScreenModal = ref;
    }
  };

  public render(): React.ReactNode {
    const { visible, logo, onClose, className, title, children } = this.props;

    return ReactDOM.createPortal(
      <div
        ref={this.setRef}
        className={classNames('c-full-screen-modal', className, { 'c-full-screen-modal--visible': visible })}
        onTouchMove={this.scroll.onIosTouchMoveFocusedInputScrollFix}
      >
        <Header partnerLogo={logo}>
          <div className="c-full-screen-modal__close-icon">
            <ButtonWithIcon onClick={onClose} iconName={SVGUrls.Close} aria-label="Close" />
          </div>
        </Header>

        {title && <h3 className="c-full-screen-modal__title">{title}</h3>}
        <div className="c-full-screen-modal__body">{children}</div>
      </div>,
      this.props.parentSelector!(),
    );
  }
}

export default FullScreenModal;
