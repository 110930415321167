import { css } from '@emotion/react';

import { mq } from '../../styles/media-queries';

import THEME from '../../styles/theme.ts';

export function containerCSS(isOpenedFromAmp: boolean) {
  return css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 0;
    padding-top: 24px;
    ${isOpenedFromAmp ? 'padding-top: 40px;' : ''}
    ${mq[1]} {
      max-width: ${THEME.size.containerMaxWidth}px;
      box-sizing: border-box;
      padding: 40px 15px;
      gap: 40px;
    }
  `;
}

export const expiredBannerCSS = css`
  position: fixed;
  top: 0;
  background-color: ${THEME.color.warningYellow94};
  width: 100%;
  right: 0;
  left: 0;
  z-index: 10;
  padding: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const expiredTextCSS = css`
  max-width: calc(100% - 64px);
`;

export const quoteDisclaimerTextCSS = css`
  color: ${THEME.color.gray80};
  font-style: italic;
  padding: 12px;
  ${mq[1]} {
    padding: 0;
  }
`;

export const logoBoxCSS = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 12px;
`;
