import { skipToken, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import type { HTTPResponse } from '../../types/api.type';
import type { Home, Vehicle } from '../../types/asset.type';
import api from '../api';

function usePersonAssetsQuery({ personGid, assetGids }: { personGid?: string; assetGids?: string[] | null }) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.personAssets, personGid, assetGids],
    queryFn: personGid
      ? async () =>
        await api.get<HTTPResponse<{ homes: (Home | null)[]; vehicles: (Vehicle | null)[] }>>(
          `${API_PATH.personAssets.replace(':gid', personGid)}?${queryString.stringify(
            { assets_gids: assetGids },
            { arrayFormat: 'bracket' },
          )}`,
        )
      : skipToken,
    select: ({ data }) => data,
  });
}

export default usePersonAssetsQuery;
