import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const headingCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 40px;
  gap: 16px;
  margin-top: 100px;

  img {
    width: 56px;
    height: 56px;
  }

  ${mq[1]} {
    flex-direction: row;
    font-size: 56px;
    line-height: 80px;
    margin-top: 56px;

    img {
      width: auto;
      height: auto;
    }
  }
`;

export const descriptionCSS = css`
  text-align: center;
  max-width: 704px;
  font-size: 20px;
  line-height: 28px;

  span {
    font-weight: 700;
  }
`;

export const customButtonCSS = css`
  max-width: calc(100% - 32px);
  margin-top: auto;
  position: absolute;
  bottom: 16px;

  ${mq[1]} {
    margin-top: 72px;
    max-width: 600px;
    position: relative;
  }
`;
