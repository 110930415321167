import { css } from '@emotion/react';

export const imageCSS = css`
  margin-top: 32px;
  max-width: 440px;
`;

export const descriptionCSS = css`
  display: block;
  max-width: 450px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
`;

export const buttonCSS = css`
  margin-top: 20px;
`;
