import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';

export const containerCSS = css`
  font-size: 16px;
  position: relative;
  padding-left: 36px;
`;

export const iconCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
`;

export const headerCSS = css`
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const valueCSS = css`
  color: ${THEME.color.positiveGreen27};
`;

export const descriptionCSS = css`
  margin-top: 6px;
  color: ${THEME.color.gray60};
`;
