import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries.ts';

export const formContainerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${mq[1]} {
    margin-right: -16px;
  }
`;

export const actionButtonsCSS = css`
  display: flex;
  gap: 20px;
`;
