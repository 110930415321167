import type { FC } from 'react';

import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { pageHeadingCSS, pageSubheadingCSS } from '../../styles/layout.style.ts';
import AutoInsuranceInsightCard from './AutoInsuranceInsightCard/AutoInsuranceInsightCard.tsx';
import CoverageInsightCard from './CoverageInsightCard/CoverageInsightCard.tsx';
import HomeQuoteInsightCard from './HomeQuoteInsightCard/HomeQuoteInsightCard.tsx';
import RateReviewInsightCard from './RateReviewInsightCard/RateReviewInsightCard.tsx';
import ScoreCards from './ScoreCards/ScoreCards.tsx';
import FooterCopy from './FooterCopy/FooterCopy.tsx';

const InsightsLayout: FC = () => {
  useTrackPage(AnalyticPageKey.Insights);

  return (
    <PageContainer pageKey={AnalyticPageKey.Insights} footerCopy={<FooterCopy />}>
      <h1 css={pageHeadingCSS}>Protection Snapshot</h1>
      <p css={pageSubheadingCSS}>
        Your protection snapshot provides an easy-to-understand view of how carriers determine how risky it is
        to insure your home. And shows how protected you are based on your level of coverage and steps you’ve
        taken to protect your home from potential damage.
      </p>

      <ScoreCards />

      <RateReviewInsightCard />

      <SectionHeading title="Does your home coverage meet your needs?" />
      <CoverageInsightCard />

      <SectionHeading title="Auto quote options" />
      <AutoInsuranceInsightCard />

      <SectionHeading title="Home quote options" />
      <HomeQuoteInsightCard />
    </PageContainer>
  );
};

export default InsightsLayout;
