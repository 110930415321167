import { css } from '@emotion/react';

import theme from '../../../styles/theme';

export const cardCSS = css`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.gray10};
  border-radius: ${theme.size.borderRadius}px;
  box-shadow: ${theme.boxShadow.container};
`;
