import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const policiesWrapperCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;

  ${mq[1]} {
    margin-top: 60px;
  }
`;
