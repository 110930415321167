import type { FC } from 'react';
import { useEffect } from 'react';

import Sirv from '../../../constants/sirv';
import Container from '../../../kit_deprecated/Container/Container';
import SirvImage from '../../../kit_deprecated/SirvImage/SirvImage';
import Faq from '../../Faq/Faq';
import LoaderQuoteCard from '../../LoaderQuoteCard/LoaderQuoteCard';
import RecommendedQuoteCard from '../../RecommendedQuoteCard/RecommendedQuoteCard';
import ScheduleCall from '../../ScheduleCall/ScheduleCall';
import VerticalPolicyCard from '../../VerticalPolicyCard/VerticalPolicyCard';
import type { RateReviewVariation } from '../Variation';
import { ContainerSize } from '../../../kit_deprecated/Container/Container.type';

const Savings: FC<RateReviewVariation> = ({ rateReviewData, quotes, callbacks }) => {
  const [recommendedQuote, ...otherQuotes] = quotes;

  useEffect(() => {
    quotes.forEach((quote) => {
      if (quote.online_bind_url)
        callbacks.onOnlineBindOffered(quote);
    });
  }, [callbacks, quotes]);

  return (
    <Container className="c-rate-review" size={ContainerSize.Small}>
      <div className="c-rate-review__container">
        <div className="c-rate-review__header">
          <h4 className="u-mb-1">
            {rateReviewData.person?.first_name}
            ,
          </h4>
          <h1 className="u-mb-2">
            Save
            {' '}
            {rateReviewData.savings}
            {' '}
            When You Switch to
            {' '}
            {recommendedQuote.carrier.marketing_name}
          </h1>
        </div>
        <RecommendedQuoteCard
          quote={recommendedQuote}
          policy={rateReviewData.policy}
          savings={rateReviewData.savings}
          onQuoteSelect={callbacks.onQuoteSelect}
          onQuoteReview={callbacks.onQuoteReview}
        />

        {otherQuotes.length > 0
          ? (
              <div className="c-rate-review__loader-quotes">
                <LoaderQuoteCard
                  quotes={otherQuotes}
                  onQuoteReview={callbacks.onQuoteReview}
                  onQuoteSelect={callbacks.onQuoteSelect}
                  onQuotesLoad={callbacks.onQuotesLoad}
                />
              </div>
            )
          : null}

        <aside className="c-rate-review__current-policy">
          <VerticalPolicyCard
            policy={rateReviewData.policy}
            person={rateReviewData.person}
            home={rateReviewData.home}
            className="u-mb-4"
          />

          <div className="c-rate-review__bundle-info">
            <h2>Bundle Home and Auto to Save up to 15%</h2>
            <SirvImage url={Sirv.ILLUSTRATIONS.BUNDLE} width={370} />
          </div>
        </aside>

        <ScheduleCall onScheduleCall={callbacks.onScheduleCall} savings />

        <div className="c-rate-review__faq-wrapper">
          <Faq onFAQClicked={callbacks.onFAQClicked} />
        </div>
      </div>
    </Container>
  );
};

export default Savings;
