import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../styles/media-queries.ts';
import type { SectionHeadingSize } from './SectionHeading.type.ts';

export const containerCSS = css`
  margin-top: 34px;
  margin-bottom: 16px;
  ${mq[1]} {
    margin-top: 60px;
    margin-bottom: 20px;
  }
`;

export const titleWithIconCSS = css`
  position: relative;
  padding-left: 32px;
  ${mq[1]} {
    padding-left: 40px;
  }
`;

export function titleCSS(size: SectionHeadingSize, hasIcon?: boolean): SerializedStyles {
  return css`
    font-size: ${size === 'large' ? 32 : 24}px;
    line-height: ${size === 'large' ? 40 : 32}px;
    margin: 0;
  
    ${hasIcon && titleWithIconCSS};
`;
}

export const titleIconCSS = css`
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
  ${mq[1]} {
    width: 32px;
    height: 32px;
  }
`;

export function subtitleCSS(size: SectionHeadingSize): SerializedStyles {
  return css`
    margin-top: 4px;
    max-width: 750px;
    text-wrap: pretty; 

    ${mq[1]} {
      font-size: ${size === 'large' ? 20 : 16}px;
      line-height: ${size === 'large' ? 28 : 20}px;
    }
`;
}
