const sensitiveQuestionsKeys = [
  /person_phone/,
  /vehicle_\d_vin/,
  /driver_\d_license_number/,
  /auto_policy_notification_phone_number/,
  /progressive_snapshot_program_phone/,
];

function isSensitiveQuestion(questionKey: string): boolean {
  return sensitiveQuestionsKeys.some(key => key.test(questionKey));
}

export default isSensitiveQuestion;
