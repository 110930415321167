import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useScrollToBlockOnInit() {
  const location = useLocation();
  const isScrolled = useRef(false);

  const elementId = location.state?.scrollToId as string;

  useEffect(() => {
    const element = document.getElementById(elementId);

    if (element && !isScrolled.current) {
      isScrolled.current = true;
      window.history.replaceState({}, '');

      setTimeout(() => {
        window?.scrollTo({ behavior: 'smooth', top: element.offsetTop - 100 });
      }, 300);
    }
  }, [elementId]);
}

export default useScrollToBlockOnInit;
