import type { PolicyType } from './quote.type.ts';

export enum AnalyticPageKey {
  Insights = 'insights',
  Policies = 'policies',
  RateReview = 'rate_review',
  ReplacementCost = 'replacement_cost',
  CarrierOptions = 'carrier_options',
  SnapshotDetails = 'snapshot_details',
  DigitalProfileIntro = 'digital_profile_intro',
  Account = 'account',
  SignIn = 'sign_in',
  LinkExpired = 'link_expired',
  CheckEmail = 'check_email',
  Verify = 'verify',
  Error = 'error',
  ScheduleCall = 'schedule_call',
  CreditScoreDetails = 'credit_score_details',
  Disclaimers = 'disclaimers',
}

export enum AnalyticLocation {
  Header = 'header',
  Footer = 'footer',
  MaticTeamBlock = 'matic_team_block',
  ScoreCards = 'score_cards', // TODO: Remove if not needed
  RiskScoreCard = 'risk_score_card',
  ProtectionScoreCard = 'protection_score_card',
  RateReviewInsightCard = 'rate_review_insight_card',
  CoverageInsightCard = 'coverage_insight_card',
  HomeQuoteInsightCard = 'home_quote_insight_card',
  FactorsList = 'factors_list',
  SuggestedPolicy = 'suggested_policy',
  CarrierOptionsList = 'carrier_options_list',
  Chart = 'chart',
}

export enum AnalyticEventStatus {
  Success = 'success',
  Error = 'error',
}

export interface AnalyticEventProperties {
  session_gid?: string;
  account_gid?: string | null;
}

interface PhoneConversationInitiatedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  location: AnalyticLocation;
}

interface ConversationSchedulingInitiatedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  location: AnalyticLocation;
}

interface LiveHelpClickedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
}

interface QuoteSelectedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  location: AnalyticLocation;
  quote_gid: string;
  carrier_key: string;
  ordinal: number;
  policy_type: PolicyType;
  premium_value: number;
}

export enum AnalyticSuggestedPolicyOfferResult {
  Quote = 'quote',
  Error = 'error',
  NoQuotes = 'no_quotes',
}

interface SuggestedPolicyViewedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  offer_result: AnalyticSuggestedPolicyOfferResult;
  quotes?: string[];
  savings?: number | null;
}

interface AccountVerifiedProps extends AnalyticEventProperties {
  status: AnalyticEventStatus;
  available_attempts_count: number;
  fail_reason?: string;
}

interface UnlockPortalDataRequestedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  location: AnalyticLocation;
}

interface BlogPostOpenedProps extends AnalyticEventProperties {
  page: AnalyticPageKey;
  title: string;
}

interface AccountProfileUpdatedProps extends AnalyticEventProperties {
  status: AnalyticEventStatus;
}

interface InsightsNavigationClickedProps extends AnalyticEventProperties {
  target_page: AnalyticPageKey;
}

export enum AnalyticAddNewPolicyVariant {
  Home = 'home',
  Auto = 'auto',
  Life = 'life',
  Other = 'other',
}

interface AddNewPolicyClickedProps extends AnalyticEventProperties {
  policy_variant: AnalyticAddNewPolicyVariant;
}

interface AuthenticatedLinkSentProps extends Omit<AnalyticEventProperties, 'account_gid'> {
  page: AnalyticPageKey;
}

export interface AnalyticEventsMap {
  ['Phone Conversation Initiated']: PhoneConversationInitiatedProps;
  ['Conversation Scheduling Initiated']: ConversationSchedulingInitiatedProps;
  ['Conversation Scheduling Finished']: AnalyticEventProperties;
  ['Schedule A Call Time Selected']: AnalyticEventProperties;
  ['Live Help Clicked']: LiveHelpClickedProps;
  ['Quote Selected']: QuoteSelectedProps;
  ['Suggested Policy Viewed']: SuggestedPolicyViewedProps;
  ['Digital Profile Navigated']: AnalyticEventProperties;
  ['Account Verified']: AccountVerifiedProps;
  ['Unlock Portal Data Requested']: UnlockPortalDataRequestedProps;
  ['Blog Post Opened']: BlogPostOpenedProps;
  ['Account Profile Updated']: AccountProfileUpdatedProps;
  ['Insights Navigation Clicked']: InsightsNavigationClickedProps;
  ['Add New Policy Clicked']: AddNewPolicyClickedProps;
  ['Authenticate Link Sent']: AuthenticatedLinkSentProps;
  ['Session Expiration Warning Viewed']: AnalyticEventProperties;
}

export type AnalyticEvents = keyof AnalyticEventsMap;

export type TrackSegmentFunction = <T extends AnalyticEvents>(eventName: T, properties?: AnalyticEventsMap[T]) => void;

interface PageSegmentProperties extends Record<string, unknown> {
  session_gid?: string;
}

export type PageSegmentFunction = (pageKey: AnalyticPageKey, properties?: PageSegmentProperties) => void;
