import './LoaderQuoteCard.scss';

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';

import type { Quote } from '../../services/rateReviews';
import QuoteCard from '../QuoteCard/QuoteCard';
import type { onQuoteReview, onQuoteSelect, onQuotesLoad } from '../RateReview/RateReview.type';
import QuoteSkeleton from './QuoteSkeleton';

interface Props {
  quotes: Quote[];
  onQuoteSelect: onQuoteSelect;
  onQuoteReview: onQuoteReview;
  onQuotesLoad: onQuotesLoad;
}

const LoaderQuoteCard: FC<Props> = ({ quotes, onQuoteSelect, onQuoteReview, onQuotesLoad }) => {
  const [hasVisibleQuotes, setHasVisibleQuotes] = useState(false);

  const onLoadQuotes = () => {
    setHasVisibleQuotes(true);
  };

  useEffect(() => {
    if (hasVisibleQuotes) {
      onQuotesLoad();
    }
  }, [hasVisibleQuotes, onQuotesLoad]);

  return (
    <>
      {hasVisibleQuotes && (
        <>
          {quotes.map((quote, index) => (
            <div className="c-loader-quote-card__card" key={quote.carrier.marketing_name}>
              <QuoteCard
                quote={quote}
                onQuoteSelect={onQuoteSelect}
                onQuoteReview={onQuoteReview}
                ordinal={index + 1}
              />
            </div>
          ))}
        </>
      )}

      <div
        className={classNames('c-loader-quote-card__skeleton-card', {
          'c-loader-quote-card__skeleton-card--animate': hasVisibleQuotes,
        })}
      >
        <QuoteSkeleton onLoadQuotes={onLoadQuotes} />
      </div>
    </>
  );
};

export default LoaderQuoteCard;
