import { skipToken, useQuery } from '@tanstack/react-query';
import queryString from 'query-string';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import type { HTTPResponse } from '../../types/api.type';
import type { Policy } from '../../types/policy.type';
import api from '../api';

function usePublicRecentPolicyQuery({
  personGid,
  assetGid,
  policyTypes,
}: {
  personGid?: string;
  assetGid?: string;
  policyTypes: string[];
}) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicRecentPolicy, personGid],
    queryFn:
      personGid && assetGid
        ? async () =>
          await api.get<HTTPResponse<{ person_policy: Policy }>>(
            `${API_PATH.publicRecentPolicy.replace(':person_gid', personGid)}?
            ${queryString.stringify(
                {
                  asset_gid: assetGid,
                  policy_types: policyTypes,
                },
                { arrayFormat: 'bracket' },
              )}`,
          )
        : skipToken,
    select: ({ data }) => data.person_policy,
  });
}

export default usePublicRecentPolicyQuery;
