import type { RegisterOptions, ValidationValue } from 'react-hook-form';

import type { AnswerValue } from './answer.type';

export enum ValidationType {
  Required = 'required',
  Pattern = 'pattern',
  Min = 'min',
  Max = 'max',
  MinLength = 'min_length',
  MaxLength = 'max_length',
  MinDaysFromNow = 'min_days_from_now',
  MaxDaysFromNow = 'max_days_from_now',
  MinDaysBeforeNow = 'min_days_before_now',
  MaxDaysBeforeNow = 'max_days_before_now',
  MinYears = 'min_years',
  MaxYears = 'max_years',
}

export interface Validation {
  key: ValidationType;
  value: ValidationValue;
  message: string;
}

export type ValidateFn = (
  answerValue: AnswerValue,
  validationValue?: ValidationValue,
  message?: string
) => string | boolean;

export enum HardcodedValidations {
  IsValidDate = 'isValidDate',
  isValidAddress = 'isValidAddress',
  IsNotEmptyString = 'isNotEmptyString',
}

export enum CustomValidations {
  MinDaysFromNow = 'minDaysFromNow',
  MaxDaysFromNow = 'maxDaysFromNow',
  MinDaysBeforeNow = 'minDaysBeforeNow',
  MaxDaysBeforeNow = 'maxDaysBeforeNow',
  MinYears = 'minYears',
  MaxYears = 'maxYears',
}

export type ValidationRules = Omit<
  RegisterOptions<Record<string, AnswerValue>, string>,
  'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
>;
