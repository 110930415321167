import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Navigate, useLocation, useParams } from 'react-router-dom';

import { ERROR } from '../../constants/routes';
import useRateReviews from '../../hooks/useRateReviews';
import type { ManualQuote, Quote } from '../../services/rateReviews';
import rateReviews from '../../services/rateReviews';
import type { UTMParams } from '../../utils/queryString';
import { addUtmParams, getUtmParams } from '../../utils/queryString';
import Variation from '../RateReviewVariant/Variation';
import {
  identifyPerson,
  trackConversationSchedulingInitiated,
  trackFAQClicked,
  trackFlowStarted,
  trackFullStory,
  trackOnlineBindOffered,
  trackPageView,
  trackQuoteReviewed,
  trackQuoteSelected,
  trackShowMoreQuotesClicked,
} from './tracking';

function selectQuote(gid: string, quote_gid: string, utmParams: UTMParams) {
  return rateReviews.selectQuote(gid, quote_gid, utmParams);
}

function selectManualQuote(gid: string, carrier_key: string, utmParams: UTMParams) {
  return rateReviews.selectManualQuote(gid, carrier_key, utmParams);
}

function scheduleCall(gid: string, utmParams: UTMParams) {
  return rateReviews.scheduleCall(gid, utmParams);
}

const RateReview: FC = () => {
  const gid = (useParams as any)().gid;
  const location = useLocation();
  const utmParams = getUtmParams(location.search);
  const { data, isError } = useRateReviews(gid);
  const [accepted, accept] = useState(false);

  const callbacks = {
    onQuoteSelect(quote: Quote, ordinal: number): void {
      selectQuote(gid, quote.gid, utmParams).then(() => {
        trackQuoteSelected(gid, quote, ordinal, data);
        if (quote.online_bind_url)
          window.location.replace(addUtmParams(quote.online_bind_url, location.search));
        else accept(true);
      });
    },
    onManualQuoteSelect(quote: ManualQuote, ordinal: number): void {
      selectManualQuote(gid, quote.carrier.key, utmParams).then(() => {
        trackQuoteSelected(gid, quote, ordinal, data);
        accept(true);
      });
    },
    onScheduleCall(): void {
      scheduleCall(gid, utmParams).then(() => {
        trackConversationSchedulingInitiated(gid);
        accept(true);
      });
    },
    onQuoteReview(quote: Quote | ManualQuote, ordinal: number): void {
      trackQuoteReviewed(gid, quote, ordinal, data);
    },
    onQuotesLoad(): void {
      trackShowMoreQuotesClicked(gid, data);
    },
    onOnlineBindOffered(quote: Quote): void {
      trackOnlineBindOffered(gid, quote, data);
    },
    onFAQClicked(question: string): void {
      trackFAQClicked(gid, question, data);
    },
  };

  useEffect(() => {
    if (data) {
      identifyPerson(data);
      trackFullStory(data);
      trackPageView(gid);
      trackFlowStarted(gid, data);
    }
  }, [data, gid]);

  if (isError) {
    return <Navigate to={{ pathname: ERROR }} />;
  }

  if (!data) {
    return null;
  }

  if (accepted) {
    return (
      <Navigate
        to={{
          pathname: `/rate_reviews/${gid}/confirmation`,
          search: location.search,
        }}
      />
    );
  }

  const { variation: RateReviewComponent, maxQuotesCount } = Variation(data.variation);

  const quotes = data.quotes.slice(0, maxQuotesCount);

  return <RateReviewComponent callbacks={callbacks} rateReviewData={data} quotes={quotes} />;
};

export default RateReview;
