import type { FC } from 'react';
import { Global } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import AuthGuard from './components/AuthGuard/AuthGuard.tsx';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SessionRoot from './components/SessionRoot/SessionRoot.tsx';
import ROUTES, { ERROR, RATE_REVIEW, RATE_REVIEW_CONFIRMATION } from './constants/routes';
import Authenticate from './pages/authenticate';
import RateReview from './layouts_deprecated/RateReview/RateReview.tsx';
import RateReviewConfirmation from './layouts_deprecated/RateReviewConfirmation/RateReviewConfirmation.tsx';
import AccountLayout from './pages/account';
import CheckEmailLayout from './pages/check-email';
import InsightsLayout from './pages/insights';
import LinkExpiredLayout from './pages/link-expired';
import ProposalLayout from './pages/proposal/index.tsx';
import RateReviewLayout from './pages/rate-review/index.tsx';
import ReplacementCostLayout from './pages/replacement-cost/index.tsx';
import SignInLayout from './pages/sign-in';
import SnapshotDetailsLayout from './pages/snapshot-details';
import VerifyLayout from './pages/verify';

import normalizeCSS, { globalCSS } from './styles/global.style.ts';
import CarrierOptionsLayout from './pages/carrier-options/index.tsx';
import DpIntroLayout from './pages/dp-intro/index.tsx';
import ScheduleCallLayout from './pages/schedule-call/index.tsx';
import { ActionCableContext, setupCableConsumer } from './utils/actioncable.ts';
import PoliciesLayout from './pages/policies/index.tsx';
import useSettingsQuery from './api/settings/use-settings-query.ts';
import useAnalytics from './hooks/use-analytics.ts';
import AuthRoot from './components/AuthRoot/AuthRoot.tsx';
import CreditScoreDetailsLayout from './pages/credit-score-details/CreditScoreDetailsLayout.tsx';
import DisclaimersLayout from './pages/disclaimers/Disclaimers.tsx';
import FullPageLoader from './shared/components/FullPageLoader/FullPageLoader.tsx';
import ErrorLayout from './pages/error';
import ErrorLayoutDeprecated from './layouts_deprecated/Error/ErrorLayoutDeprecated.tsx';
import ErrorFallbackV1 from './pages/error/fallbacks/ErrorFallbackV1/ErrorFallbackV1.tsx';
import ErrorFallbackGlobal from './pages/error/fallbacks/ErrorFallbackGlobal/ErrorFallbackGlobal.tsx';
import ErrorFallbackSession from './pages/error/fallbacks/ErrorFallbackSession/ErrorFallbackSession.tsx';

const App: FC = () => {
  const { data: settingsData } = useSettingsQuery(false);
  const { reset } = useQueryErrorResetBoundary();
  useAnalytics({ segment: settingsData?.segment, sentry: settingsData?.sentry });

  if (!settingsData) {
    return <FullPageLoader />;
  }

  return (
    <>
      <Global styles={normalizeCSS} />
      <Sentry.ErrorBoundary
        onReset={reset}
        fallback={({ resetError }) => <ErrorFallbackGlobal resetError={resetError} />}
      >
        <ActionCableContext.Provider value={setupCableConsumer()}>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route
                path={ROUTES.authenticate}
                element={(
                  <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                    <Authenticate />
                  </Sentry.ErrorBoundary>
                )}
              />

              <Route
                path={ROUTES.sessionBase}
                element={(
                  <Sentry.ErrorBoundary
                    fallback={({ error }) => <ErrorFallbackSession error={error} />}
                  >
                    <AuthGuard>
                      <SessionRoot />
                    </AuthGuard>
                  </Sentry.ErrorBoundary>
                )}
              >
                <Route index element={<InsightsLayout />} />

                <Route path={ROUTES.verify} element={<VerifyLayout />} />
                <Route path={ROUTES.insights} element={<InsightsLayout />} />
                <Route path={ROUTES.rateReview} element={<RateReviewLayout />} />
                <Route path={ROUTES.replacementCost} element={<ReplacementCostLayout />} />
                <Route path={ROUTES.carrierOptions} element={<CarrierOptionsLayout />} />
                <Route path={ROUTES.digitalProfileIntro} element={<DpIntroLayout />} />
                <Route path={ROUTES.policies} element={<PoliciesLayout />} />
                <Route path={ROUTES.snapshotDetails} element={<SnapshotDetailsLayout />} />
                <Route path={ROUTES.scheduleCall} element={<ScheduleCallLayout />} />
                <Route path={ROUTES.account} element={<AccountLayout />} />
                <Route path={ROUTES.creditScoreDetails} element={<CreditScoreDetailsLayout />} />
                <Route path={ROUTES.disclaimers} element={<DisclaimersLayout />} />
              </Route>

              <Route path={ROUTES.auth} element={<AuthRoot />}>
                <Route index element={<SignInLayout />} />

                <Route
                  path={ROUTES.linkExpired}
                  element={(
                    <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                      <LinkExpiredLayout />
                    </Sentry.ErrorBoundary>
                  )}
                />
                <Route
                  path={ROUTES.checkEmail}
                  element={(
                    <Sentry.ErrorBoundary fallback={<Navigate to={ROUTES.problem} />}>
                      <CheckEmailLayout />
                    </Sentry.ErrorBoundary>
                  )}
                />
                <Route
                  path={ROUTES.signIn}
                  element={<SignInLayout />}
                />
              </Route>

              <Route
                path={ROUTES.problem}
                element={(
                  <>
                    <ErrorLayout />
                    <Global styles={globalCSS} />
                  </>
                )}
              />
              <Route
                path={ROUTES.invalid}
                element={(
                  <>
                    <ErrorLayout hideActionButton />
                    <Global styles={globalCSS} />
                  </>
                )}
              />

              <Route
                path={ROUTES.proposal}
                element={(
                  <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'proposal');
                    }}
                    fallback={(
                      <>
                        <ErrorLayout hideActionButton />
                        <Global styles={globalCSS} />
                      </>
                    )}
                  >
                    <ProposalLayout />
                    <Global styles={globalCSS} />
                  </Sentry.ErrorBoundary>
                )}
              />

              {/** v1 routes. TODO: Remove after v2 release */}
              <Route
                path={RATE_REVIEW_CONFIRMATION}
                element={(
                  <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'rate_review_v1');
                    }}
                    fallback={<ErrorFallbackV1 />}
                  >
                    <RateReviewConfirmation />
                  </Sentry.ErrorBoundary>
                )}
              />
              <Route
                path={RATE_REVIEW}
                element={(
                  <Sentry.ErrorBoundary
                    beforeCapture={(scope) => {
                      scope.setTag('location', 'rate_review_v1');
                    }}
                    fallback={<ErrorFallbackV1 />}
                  >
                    <RateReview />
                  </Sentry.ErrorBoundary>
                )}
              />
              <Route
                path={ERROR}
                element={(
                  <ErrorLayoutDeprecated />
                )}
              />
            </Routes>
          </BrowserRouter>
        </ActionCableContext.Provider>
      </Sentry.ErrorBoundary>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
