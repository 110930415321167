import type { FC } from 'react';
import { isAxiosError } from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import ROUTES from '../../../../constants/routes.ts';
import { ERRORS_AUTHORIZATION } from '../../../../utils/error.util.ts';
import isGid from '../../../../utils/is-gid.util.ts';
import type { ErrorFallbackSessionProps } from './ErrorFallbackSession.type.ts';

const ErrorFallbackSession: FC<ErrorFallbackSessionProps> = ({ error }) => {
  const { gid } = useParams();

  const getProblemRoute = (): string => {
    return isGid(gid) ? `${ROUTES.problem}?gid=${gid}` : ROUTES.problem;
  };

  if (isAxiosError(error) && ERRORS_AUTHORIZATION.includes(error.response?.status || 0)) {
    return <Navigate to={ROUTES.signIn} />;
  }

  return <Navigate to={getProblemRoute()} />;
};

export default ErrorFallbackSession;
