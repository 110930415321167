import type { FC } from 'react';

import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import { ANALYTICS_SESSION_GID, LOGGED_OUT_SESSION_GID } from '../../constants/browser-storage.ts';
import useSegment from '../../hooks/use-segment.ts';
import useMutateTrackSession from '../../api/session/use-mutate-track-session.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import SignInForm from './SignInForm/SignInForm.tsx';

import { signInFormCSS } from './index.style.ts';

const SignInLayout: FC = () => {
  const loggedOutSessionGid = sessionStorage.getItem(LOGGED_OUT_SESSION_GID);
  const localStorageSessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

  const { mutateAsync: createTrackSession } = useMutateTrackSession();

  const { page } = useSegment();

  /**
   * If we have session gid after log out we track page event with this gid to avoid creating new
   * session that is potentially will end after user close a tab with sign in page after log out
   */
  useTrackPage(AnalyticPageKey.SignIn, loggedOutSessionGid || localStorageSessionGid);

  const onBeforeSubmit = async (): Promise<void> => {
    if (loggedOutSessionGid) {
      sessionStorage.removeItem(LOGGED_OUT_SESSION_GID);

      const data = await createTrackSession({});
      localStorage.setItem(ANALYTICS_SESSION_GID, data.session.gid);

      /**
       * Track page event one more time with a new session gid to have full user flow for new session
       */
      page(AnalyticPageKey.SignIn, { session_gid: data.session.gid });
    }
  };

  return (
    <PageInfoContainer
      heading="Sign into Matic"
      subHeading="To login to Matic please enter your email below to get your access link."
    >
      <SignInForm customCSS={signInFormCSS} onBeforeSubmit={onBeforeSubmit} />
    </PageInfoContainer>
  );
};

export default SignInLayout;
