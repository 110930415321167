import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { mq, mqCalendly } from '../../../styles/media-queries';
import THEME from '../../../styles/theme';

export const titleCSS = css`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  text-align: center;
  
  span {
    display: none;
  }

  ${mq[1]} {
    font-size: 48px;
    line-height: 60px;

    span {
      display: inline;
    }
  }
`;

export const subtitleCSS = css`
  display: none;
  text-align: center;

  ${mq[1]} {
    display: block;
    margin-bottom: 0;
  }
`;

export const loaderCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  background: ${THEME.color.white};
  padding: 34px 0 10px;
`;

const CALENDLY_WIDGET_HEIGHT = {
  sm: [1015, 1015, 1025, 560],
  md: [1085, 1085, 1120, 570],
  lg: [700],
};

export function calendlyWidgetCSS(step: number): SerializedStyles {
  return css`
    background-color: ${THEME.color.white};
    border: 1px solid ${THEME.color.gray30};
    border-radius: 8px;
    height: ${CALENDLY_WIDGET_HEIGHT.sm[step]}px;
    box-shadow: ${THEME.boxShadow.container};
    margin-bottom: 20px;
    margin-top: 24px;

    iframe {
      border-radius: 8px;
    }

    ${mqCalendly[0]} {
      border: unset;
      box-shadow: unset;
      height: ${CALENDLY_WIDGET_HEIGHT.md[step]}px;
      margin-bottom: 0;
      margin-top: 0;
    }

    ${mqCalendly[1]} {
      height: ${CALENDLY_WIDGET_HEIGHT.lg[0]}px;
      min-width: 320px;
    }
  `;
}
