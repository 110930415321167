import './QuoteSkeleton.scss';

import type { FC } from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';

import Sirv from '../../constants/sirv';
import Button from '../../kit_deprecated/Button/Button';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage';
import { Card, CardContent } from '../Card/Card';
import { ButtonSize } from '../../kit_deprecated/Button/Button.type';

interface Props {
  onLoadQuotes: any;
}

const QuoteSkeleton: FC<Props> = ({ onLoadQuotes }) => {
  const [animationInProgress, setAnimationInProgress] = useState(false);

  useEffect(() => {
    if (animationInProgress) {
      onLoadQuotes();
    }
  });

  return (
    <Card className="c-quote-skeleton">
      <CardContent>
        <SirvImage
          className={classNames('c-quote-skeleton__illustration u-hidden--mobile', {
            'c-quote-skeleton__illustration--animate': animationInProgress,
          })}
          url={Sirv.ILLUSTRATIONS.QUOTE_SKELETON_HORIZONTAL}
          width={1000}
        />

        {!animationInProgress && (
          <Button
            className="c-quote-skeleton__button qa-skeleton-button"
            size={ButtonSize.Large}
            onClick={() => setAnimationInProgress(true)}
            secondary
          >
            See Other Quotes Matic Found
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default QuoteSkeleton;
