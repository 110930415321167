import { css } from '@emotion/react';

import THEME from '../../styles/theme';

export const containerCSS = css`
  background-color: ${THEME.color.violet95};
  border-radius: 4px;
  display: flex;
  gap: 16px;
  padding: 16px;
`;

export const iconCSS = css`
  min-height: 24px;
  min-width: 24px;
`;
