import './Error.scss';

import type { FC } from 'react';
import Sirv from '../../constants/sirv';
import SirvImage from '../../kit_deprecated/SirvImage/SirvImage';

const DEFAULT_CONTACT_PHONE = '(833) 382-1298';

function contactPhoneBlock(phone: string) {
  return (
    <>
      {' '}
      <a className="o-link u-text--no-break" href={`tel:${phone}`}>
        {phone}
      </a>
      {' '}
    </>
  );
}

const Error: FC = () => {
  return (
    <section className="ie-error-container">
      <div className="c-error ie-error-component">
        <h2>Oops! Something Went Wrong</h2>
        <SirvImage className="c-error__illustration" url={Sirv.ILLUSTRATIONS.ERROR} width={536} />
        <div>
          <b>It’s Confusing, We Know</b>
        </div>
        <div className="c-error__description">
          Please, call us
          {' '}
          {contactPhoneBlock(DEFAULT_CONTACT_PHONE)}
          {' '}
          to ask for help, or try again later.
        </div>
      </div>
    </section>
  );
};

export default Error;
