import { type FC, useId } from 'react';

import MuiTooltip from '@mui/material/Tooltip';

import { TooltipTheme, muiTooltipCSS, tooltipArrowCSS, tooltipContentCSS } from '../../../styles/common.style.ts';
import { tooltipCSS } from './Tooltip.style';
import type { TooltipProps } from './Tooltip.type';

const Tooltip: FC<TooltipProps> = ({
  id,
  children,
  content,
  customCSS,
  theme = TooltipTheme.Dark,
  position = 'top',
}) => {
  const generatedId = useId();
  const key = id ?? generatedId;

  const tooltipProps = {
    id: `tooltip-${key}`,
    arrow: true,
    componentsProps: {
      tooltip: { sx: { ...muiTooltipCSS(theme), ...customCSS?.tooltipCSS } },
      arrow: { sx: { ...tooltipArrowCSS(theme) } },
    },
    title: <div css={tooltipContentCSS(theme)}>{content}</div>,
    enterTouchDelay: 0,
    leaveTouchDelay: 100000,
    placement: position,
    PopperProps: {
      style: { zIndex: 2 },
    },
  };

  return (
    <MuiTooltip {...tooltipProps}>
      <button type="button" css={[tooltipCSS, customCSS?.buttonCSS]}>
        {children}
      </button>
    </MuiTooltip>
  );
};

export default Tooltip;
